import React from "react";
import "../../../styles/QuestsTitleSeparator.css";
import dailySpoilsTitleSeparatorLeft from "../../../assets/images/dailySpoilsTitleSeparatorLeft.png";
import dailySpoilsTitleSeparatorCenter from "../../../assets/images/dailySpoilsTitleSeparatorCenter.png";
import dailySpoilsTitleSeparatorRight from "../../../assets/images/dailySpoilsTitleSeparatorRight.png";

const QuestsTitleSeparator = ({ width }) => {
  return (
    <div
      className="quests-title-separator"
      style={width ? { width } : undefined}
    >
      <img
        src={dailySpoilsTitleSeparatorLeft}
        alt=""
        className="separator-left"
      />
      <img
        src={dailySpoilsTitleSeparatorCenter}
        alt=""
        className="separator-center"
      />
      <img
        src={dailySpoilsTitleSeparatorRight}
        alt=""
        className="separator-right"
      />
    </div>
  );
};

export default QuestsTitleSeparator;
