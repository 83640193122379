import React from "react";
import attack from "../../../assets/images/attack.png";
import defense from "../../../assets/images/defense.png";
import charge from "../../../assets/images/charge.png";
import smallContainer from "../../../assets/images/smallContainer.png";
import "../../../styles/StatDisplay.css";

function StatDisplay({
  attack: attackValue,
  defense: defenseValue,
  charge: chargeValue,
  handleDisplayClick,
}) {
  const stats = [
    {
      name: "Attack",
      description: `Attack measures the likelihood of critical combos`,
      value: attackValue,
      icon: attack,
    },
    {
      name: "Defense",
      description: `Defense measures the likelihood of blocking deadblocks`,
      value: defenseValue,
      icon: defense,
    },
    {
      name: "Charge",
      description: `Charge measures the rate of 'Ultimate' regeneration`,
      value: chargeValue,
      icon: charge,
    },
  ];

  return (
    <div className="stat-display">
      {stats.map((stat) => (
        <div
          key={stat.name}
          className="stat-item"
          style={{ backgroundImage: `url(${smallContainer})` }}
          onClick={() =>
            handleDisplayClick(stat.name, stat.description, stat.icon)
          }
        >
          <img src={stat.icon} alt={stat.name} className="stat-icon" />
          <span className="stat-value">{stat.value}</span>
        </div>
      ))}
    </div>
  );
}

export default StatDisplay;
