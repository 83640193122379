// PurchaseArmoryItemBottomSheet.js
import React from "react";
import BottomSheet from "../../BottomSheet";
import ArmoryItem from "./ArmoryItem";
import "../../../styles/PurchaseArmoryItemBottomSheet.css";
import armoryUnlockButton from "../../../assets/images/armoryUnlockButton.png";
import armoryUnlockButtonPressed from "../../../assets/images/armoryUnlockButtonPressed.png";
import armoryUpgradeButton from "../../../assets/images/armoryUpgradeButton.png";
import armoryUpgradeButtonPressed from "../../../assets/images/armoryUpgradeButtonPressed.png";
import armoryInsufficientFundsButton from "../../../assets/images/armoryInsufficientFundsButton.png";
import armoryMissingRequirementsButton from "../../../assets/images/armoryMissingRequirementsButton.png";
import armoryBottomSheetSectionHeaderBanner from "../../../assets/images/armoryBottomSheetSectionHeaderBanner.png";
import armoryBottomSheetDescriptionBox from "../../../assets/images/armoryBottomSheetDescriptionBox.png";
import armoryBottomSheetLevelArrow from "../../../assets/images/armoryBottomSheetLevelArrow.png";

const PurchaseArmoryItemBottomSheet = ({
  isOpen,
  item,
  onCancel,
  onConfirm,
  isButtonPressed = false,
}) => {
  if (!item) return null;

  const {
    title,
    description,
    currentLevel,
    bountyBoostUpgrade,
    price,
    canAfford,
    dependenciesMet,
    dependencies = [],
    isMaxLevel,
    id,
    isUnlocked,
  } = item;

  console.log("currentlevel: ", currentLevel);
  const nextLevel = currentLevel + 1;
  const canPurchase = canAfford && dependenciesMet;

  const getButtonImage = () => {
    if (!dependenciesMet) return armoryMissingRequirementsButton;
    if (!canAfford) return armoryInsufficientFundsButton;
    if (isUnlocked) {
      return isButtonPressed ? armoryUpgradeButtonPressed : armoryUpgradeButton;
    }
    return isButtonPressed ? armoryUnlockButtonPressed : armoryUnlockButton;
  };

  const getBottomSectionContent = () => {
    if (!dependenciesMet) {
      return {
        bannerText: "REQUIREMENTS",
        content: (
          <div className="requirements-list">
            {dependencies.map((dep, index) => (
              <div key={index} className="requirement-item">
                {`${dep.name} Lv. ${dep.level}`}
              </div>
            ))}
          </div>
        ),
      };
    }

    if (!isUnlocked) {
      return {
        bannerText: "UNLOCK ITEM",
        content: <div className="level-display">Lv. 1</div>,
      };
    }

    return {
      bannerText: "UPGRADE ITEM",
      content: (
        <div className="level-upgrade-display">
          <div className="level-text">Lv. {currentLevel}</div>
          <div className="level-arrow-container">
            <img
              src={armoryBottomSheetLevelArrow}
              alt="arrow"
              className="level-arrow"
            />
          </div>
          <div className="level-text">Lv. {nextLevel}</div>
        </div>
      ),
    };
  };

  const bottomSection = getBottomSectionContent();
  const buttonImage = getButtonImage();

  return (
    <BottomSheet
      isOpen={isOpen}
      onCancel={onCancel}
      onConfirm={onConfirm}
      canTapOutToCancel={true}
      isConfirmEnabled={canPurchase}
      hideButtons={true}
      height={600}
    >
      <div className="purchase-armory-item-container">
        <div className="preview-item-container">
          <ArmoryItem
            id={id}
            title={title}
            isLocked={!dependenciesMet}
            hideLevel={true}
            currentLevel={currentLevel}
            bountyBoostUpgrade={bountyBoostUpgrade}
            price={price}
            canAfford={canAfford}
            dependenciesMet={dependenciesMet}
            isMaxLevel={isMaxLevel}
            onSelect={() => {}}
            width={334}
          />
        </div>

        <div className="description-box-container" style={{ width: 334 }}>
          <img
            src={armoryBottomSheetDescriptionBox}
            alt="Description background"
            className="description-box-image"
          />
          <div className="description-text">{description}</div>
        </div>

        <div className="bottom-section">
          <div className="banner-container">
            <img
              src={armoryBottomSheetSectionHeaderBanner}
              alt="Section banner"
              className="banner-image"
            />
            <div className="banner-text">{bottomSection.bannerText}</div>
          </div>

          <div className="content-container">{bottomSection.content}</div>

          <div className="button-container">
            <img
              src={buttonImage}
              alt={isUnlocked ? "Upgrade" : "Unlock"}
              className={`action-button ${canPurchase ? "clickable" : ""}`}
              onClick={canPurchase ? onConfirm : undefined}
            />
          </div>
        </div>
      </div>
    </BottomSheet>
  );
};

export default PurchaseArmoryItemBottomSheet;
