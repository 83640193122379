import React from "react";
import "../styles/LoadingView.css";
import loadingBG from "../assets/images/loadingBG.png";
import bkLogo from "../assets/images/bkLogo.png";
import loadingSpinner from "../assets/images/loadingSpinner.svg"; // Assuming we use SVG for the spinner

const LoadingView = ({ loadingProgression }) => {
  const progressPercentage = Math.round(loadingProgression * 100);

  return (
    <div
      className="loading-view"
      style={{ backgroundImage: `url(${loadingBG})` }}
    >
      <div className="content">
        <img src={bkLogo} alt="Brutal Knights Logo" className="logo" />
        <img src={loadingSpinner} alt="Loading" className="spinner" />
        <p className="loading-text">LOADING</p>

        <p className="loading-progress">{progressPercentage}%</p>
        <p className="initial-load-text">{"(Initial load may take longer)"}</p>
      </div>
    </div>
  );
};

export default LoadingView;
