import React from "react";
import "../styles/TextInput.css";
import textInputLeft from "../assets/images/textInputLeft.png";
import textInputCenterSliver from "../assets/images/textInputCenterSliver.png";
import textInputRight from "../assets/images/textInputRight.png";

function TextInput({ value, onChange, placeholder, style }) {
  return (
    <div className="text-input-container" style={{ style }}>
      <input
        type="text"
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        className="text-input"
        style={style}
      />
      <div className="text-input-background" style={style}>
        <img src={textInputLeft} alt="" className="text-input-bg-left" />
        <img
          src={textInputCenterSliver}
          alt=""
          className="text-input-bg-center"
        />
        <img src={textInputRight} alt="" className="text-input-bg-right" />
      </div>
    </div>
  );
}

export default TextInput;
