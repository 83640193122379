import React, { useState } from "react";
import CallToActionHeader from "../../CallToActionHeader";
import SectionHeader from "../../SectionHeader";
import ItemButton from "../../ItemButton";
import EnterEthAddressBottomSheet from "./EnterEthAddressBottomSheet";
import airdropCTAHeaderText from "../../../assets/images/airdropCTAHeaderText.png";
import "../../../styles/AirdropScreen.css";

function AirdropScreen({ userInfo, ethereumAddress, onEthAddressSubmit }) {
  const [isEthAddressBottomSheetOpen, setIsEthAddressBottomSheetOpen] =
    useState(false);

  const handleConnectWallet = () => {
    setIsEthAddressBottomSheetOpen(true);
  };

  const handleEthAddressCancel = () => {
    setIsEthAddressBottomSheetOpen(false);
  };

  const handleEthAddressConfirm = (address) => {
    onEthAddressSubmit(address);
    setIsEthAddressBottomSheetOpen(false);
  };

  return (
    <div className="airdrop-screen">
      <CallToActionHeader CTAHeaderTextIcon={airdropCTAHeaderText} />
      <SectionHeader sectionName="COMPLETE AIRDROP TASKS..." />
      <div className="section-container">
        <ItemButton
          key={"Connect Your Wallet"}
          title={"Connect Your Wallet"}
          onSelected={handleConnectWallet}
          isCompleted={!!ethereumAddress}
        />
      </div>
      <EnterEthAddressBottomSheet
        onCancel={handleEthAddressCancel}
        onConfirm={handleEthAddressConfirm}
        ethereumAddress={ethereumAddress}
        isOpen={isEthAddressBottomSheetOpen}
      />
    </div>
  );
}

export default AirdropScreen;
