export const formatNumberWithCommas = (input) => {
  // Convert input to a number if it's a string
  const num = typeof input === "string" ? parseFloat(input) : input;

  // Check if the input is a valid number
  if (isNaN(num)) {
    throw new Error("Invalid input: must be a number or a numeric string");
  }

  // Convert to string and add commas
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const detectEnvironment = () => {
  const currentUrl = window.location.href;
  console.log("CurrentURL: ", currentUrl);
  return currentUrl.includes("devbrutalblocks") ? "dev" : "prod";
};
