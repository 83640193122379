import React from "react";
import "../styles/InfoCard.css";
import infoCardLeft from "../assets/images/infoCardLeft.png";
import infoCardCenterSliver from "../assets/images/infoCardCenterSliver.png";
import infoCardRight from "../assets/images/infoCardRight.png";

function InfoCard({ text, style, textStyle }) {
  return (
    <div className="info-card" style={style}>
      <div className="info-card-background">
        <img src={infoCardLeft} alt="" className="info-card-bg-left" />
        <img
          src={infoCardCenterSliver}
          alt=""
          className="info-card-bg-center"
        />
        <img src={infoCardRight} alt="" className="info-card-bg-right" />
      </div>
      <div className="info-card-content">
        <div className="info-row-text-container">
          <div className="info-row-text" style={textStyle}>
            {text}
          </div>
        </div>
      </div>
    </div>
  );
}

export default InfoCard;
