import React from "react";
import backgroundSlice from "../assets/images/backgroundSlice.png";
import "../styles/BackgroundContainer.css";

const BackgroundContainer = () => {
  return (
    <div
      className="background-container"
      style={{
        backgroundImage: `url(${backgroundSlice})`,
        width: "100vw",
        height: "100vh",
      }}
    />
  );
};

export default BackgroundContainer;
