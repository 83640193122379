import React, { useState } from "react";
import CharacterDisplay from "./CharacterDisplay";
import StatDisplay from "./StatDisplay";
import AbilityDisplay from "./AbilityDisplay";
import BattleButton from "./BattleButton";
import PopUpWithIcon from "./PopUpWithIcon";
import {
  playSoundSelectionPrimary,
  playSoundDeselect,
} from "../../../utils/AudioManager";
import "../../../styles/BattleScreen.css";

function BattleScreen({
  userInfo,
  onBattlePressed,
  onNextCharacterPressed,
  onPrevCharacterPressed,
  currentCharacterIndex,
  characterArray,
}) {
  const currentCharacter = characterArray[currentCharacterIndex];

  const [popupInfo, setPopupInfo] = useState({
    isOpen: false,
    title: "",
    content: "",
    icon: null,
  });

  const openInfoPopup = (title, content, icon) => {
    playSoundSelectionPrimary && playSoundSelectionPrimary();
    setPopupInfo({ isOpen: true, title, content, icon });
  };

  const closeInfoPopup = () => {
    playSoundDeselect && playSoundDeselect();
    setPopupInfo({ isOpen: false, title: "", content: "", icon: null });
  };

  return (
    <div className="battle-screen">
      <div className="battle-screen-content">
        <CharacterDisplay
          character={currentCharacter}
          onNextCharacterPressed={onNextCharacterPressed}
          onPrevCharacterPressed={onPrevCharacterPressed}
        />
        <StatDisplay
          attack={currentCharacter.attack}
          defense={currentCharacter.defense}
          charge={currentCharacter.charge}
          handleDisplayClick={openInfoPopup}
        />
        <AbilityDisplay
          ultimateAbility={currentCharacter.ultimate}
          ultimateTitle={currentCharacter.ultimateTitle}
          passiveAbility={currentCharacter.passive}
          passiveTitle={currentCharacter.passiveTitle}
          handleDisplayClick={openInfoPopup}
        />
      </div>
      <BattleButton onClick={onBattlePressed} />
      <PopUpWithIcon
        isOpen={popupInfo.isOpen}
        onClose={closeInfoPopup}
        title={popupInfo.title}
        content={popupInfo.content}
        icon={popupInfo.icon}
      />
    </div>
  );
}

export default BattleScreen;
