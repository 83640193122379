function importAll(r) {
  return r.keys().map(r);
}

const images = importAll(
  require.context("../assets/images", false, /\.(png|jpe?g|svg)$/)
);

export const preloadImages = () => {
  return Promise.all(
    images.map((src) => {
      return new Promise((resolve, reject) => {
        const img = new Image();
        img.src = src;
        img.onload = resolve;
        img.onerror = reject;
      });
    })
  );
};
