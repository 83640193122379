import React, { useRef, useEffect, useState } from "react";

const AutoSizingText = ({
  text,
  maxFontSize,
  maxLines,
  textAlign = "center",
  justifyContent = "flex-start",
  className = "",
}) => {
  const containerRef = useRef(null);
  const textRef = useRef(null);
  const [fontSize, setFontSize] = useState(maxFontSize);

  useEffect(() => {
    const resizeText = () => {
      if (!containerRef.current || !textRef.current) return;

      const containerWidth = containerRef.current.offsetWidth;
      const containerHeight = containerRef.current.offsetHeight;

      let currentFontSize = maxFontSize;
      const minFontSize = Math.max(1, maxFontSize * 0.75);

      const tryFit = (fontSize) => {
        textRef.current.style.fontSize = `${fontSize}px`;

        const lines = Math.ceil(
          textRef.current.scrollHeight / (fontSize * 1.2)
        );

        return (
          textRef.current.scrollHeight <= containerHeight &&
          textRef.current.scrollWidth <= containerWidth &&
          (!maxLines || lines <= maxLines)
        );
      };

      while (currentFontSize > minFontSize && !tryFit(currentFontSize)) {
        currentFontSize -= 0.5;
      }

      while (currentFontSize > 1 && !tryFit(currentFontSize)) {
        currentFontSize -= 0.5;
      }

      setFontSize(currentFontSize);
    };

    resizeText();
    window.addEventListener("resize", resizeText);

    return () => {
      window.removeEventListener("resize", resizeText);
    };
  }, [text, maxFontSize, maxLines]);

  return (
    <div
      ref={containerRef}
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent,
      }}
    >
      <div
        ref={textRef}
        className={className}
        style={{
          fontSize: `${fontSize}px`,
          lineHeight: 1.2,
          textAlign,
          maxWidth: "100%",
          wordWrap: "break-word",
        }}
      >
        {text}
      </div>
    </div>
  );
};

export default AutoSizingText;
