import { detectEnvironment } from "./Utils";

const FRIEND_INVITE_BASE_URL_BEGINNING = "https://t.me/";
const FRIEND_INVITE_BASE_URL_END = "?start=invite_";

export const generateFriendInviteLink = (userId) => {
  let botName = "BrutalKnightsBot";
  const environment = detectEnvironment();
  if (environment === "dev") {
    botName = "BKDevBot";
  }
  if (!userId) {
    console.error("Cannot generate invite link: userId is undefined");
    return null;
  }
  return `${
    FRIEND_INVITE_BASE_URL_BEGINNING + botName + FRIEND_INVITE_BASE_URL_END
  }${userId}`;
};
