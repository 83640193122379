// Import all armory images
import aegisOfTheTyrantImg from "../assets/armoryIcons/aegisOfTheTyrant.png";
import armorOfTheUndyingImg from "../assets/armoryIcons/armorOfTheUndying.png";
import arrowstormImg from "../assets/armoryIcons/arrowstorm.png";
import barbedWireImg from "../assets/armoryIcons/barbedWire.png";
import berserkerBrewImg from "../assets/armoryIcons/berserkerBrew.png";
import berserkersTwinAxesImg from "../assets/armoryIcons/berserkersTwinAxes.png";
import blazingPitchImg from "../assets/armoryIcons/blazingPitch.png";
import bloodShieldImg from "../assets/armoryIcons/bloodShield.png";
import bloodVialImg from "../assets/armoryIcons/bloodVial.png";
import bloodfrenzyImg from "../assets/armoryIcons/bloodfrenzy.png";
import bloodhoundImg from "../assets/armoryIcons/bloodhound.png";
import bloodiedCleaverImg from "../assets/armoryIcons/bloodiedCleaver.png";
import bloodmistDodgeImg from "../assets/armoryIcons/bloodmistDodge.png";
import bloodrushImg from "../assets/armoryIcons/bloodrush.png";
import boneDustImg from "../assets/armoryIcons/boneDust.png";
import bonecrusherImg from "../assets/armoryIcons/bonecrusher.png";
import boneforgedShieldImg from "../assets/armoryIcons/boneforgedShield.png";
import bootsOfTheConquerorImg from "../assets/armoryIcons/bootsOfTheConqueror.png";
import bowOfTheApocalypseImg from "../assets/armoryIcons/bowOfTheApocalypse.png";
import breathOfPlagueImg from "../assets/armoryIcons/breathOfPlague.png";
import brutalizeImg from "../assets/armoryIcons/brutalize.png";
import butchersToolkitImg from "../assets/armoryIcons/butchersToolkit.png";
import cannibalsJerkyImg from "../assets/armoryIcons/cannibalsJerky.png";
import carrionCrowCloakImg from "../assets/armoryIcons/carrionCrowCloak.png";
import chainOfTormentImg from "../assets/armoryIcons/chainOfTorment.png";
import cloakOfFlayedShadowsImg from "../assets/armoryIcons/cloakOfFlayedShadows.png";
import cosmicHorrorGlimpseImg from "../assets/armoryIcons/cosmicHorrorGlimpse.png";
import crownOfSubjugationImg from "../assets/armoryIcons/crownOfSubjugation.png";
import cruelCaltropsImg from "../assets/armoryIcons/cruelCaltrops.png";
import crushingBlowImg from "../assets/armoryIcons/crushingBlow.png";
import deadeyeImg from "../assets/armoryIcons/deadeye.png";
import deathFromAboveImg from "../assets/armoryIcons/deathFromAbove.png";
import disembowelImg from "../assets/armoryIcons/disembowel.png";
import draughtOfLivingDeathImg from "../assets/armoryIcons/draughtOfLivingDeath.png";
import essenceOfAgonyImg from "../assets/armoryIcons/essenceOfAgony.png";
import eviscerateImg from "../assets/armoryIcons/eviscerate.png";
import executionersHoodImg from "../assets/armoryIcons/executionersHood.png";
import fateTwistingVialImg from "../assets/armoryIcons/fateTwistingVial.png";
import fistsOfTheColossusImg from "../assets/armoryIcons/fistsOfTheColossus.png";
import flayedSkinVestImg from "../assets/armoryIcons/flayedSkinVest.png";
import fleshKnittingSalveImg from "../assets/armoryIcons/fleshKnittingSalve.png";
import fleshrenderImg from "../assets/armoryIcons/fleshrender.png";
import fleshweaveArmorImg from "../assets/armoryIcons/fleshweaveArmor.png";
import gauntletsOfFleshRendingImg from "../assets/armoryIcons/gauntletsOfFleshRending.png";
import greekFireFlaskImg from "../assets/armoryIcons/greekFireFlask.png";
import harbingerOfDoomImg from "../assets/armoryIcons/harbingerOfDoom.png";
import heartpiercerLongbowImg from "../assets/armoryIcons/heartpiercerLongbow.png";
import hellfireGrenadeImg from "../assets/armoryIcons/hellfireGrenade.png";
import hellforgedAxeImg from "../assets/armoryIcons/hellforgedAxe.png";
import hellgateImg from "../assets/armoryIcons/hellgate.png";
import helmOfMaddeningVisionsImg from "../assets/armoryIcons/helmOfMaddeningVisions.png";
import ichorOfTheGodsImg from "../assets/armoryIcons/ichorOfTheGods.png";
import juggernautsBulwarkImg from "../assets/armoryIcons/juggernautsBulwark.png";
import knuckleDustersImg from "../assets/armoryIcons/knuckleDusters.png";
import liquidCourageImg from "../assets/armoryIcons/liquidCourage.png";
import meatstormImg from "../assets/armoryIcons/meatstorm.png";
import miasmaBombImg from "../assets/armoryIcons/miasmaBomb.png";
import mindshatterImg from "../assets/armoryIcons/mindshatter.png";
import morningstarOfAgonyImg from "../assets/armoryIcons/morningstarOfAgony.png";
import oblivionEssenceImg from "../assets/armoryIcons/oblivionEssence.png";
import painkillerHerbsImg from "../assets/armoryIcons/painkillerHerbs.png";
import paradoxScrollImg from "../assets/armoryIcons/paradoxScroll.png";
import paranoiaPowderImg from "../assets/armoryIcons/paranoiaPowder.png";
import pendantOfSufferingImg from "../assets/armoryIcons/pendantOfSuffering.png";
import phantomSkinImg from "../assets/armoryIcons/phantomSkin.png";
import philosophersBaneImg from "../assets/armoryIcons/philosophersBane.png";
import phoenixBloodImg from "../assets/armoryIcons/phoenixBlood.png";
import rageEssenceImg from "../assets/armoryIcons/rageEssence.png";
import ribcageCuirassImg from "../assets/armoryIcons/ribcageCuirass.png";
import riposteOfAgonyImg from "../assets/armoryIcons/riposteOfAgony.png";
import scrollOfBloodMagicImg from "../assets/armoryIcons/scrollOfBloodMagic.png";
import shadowmeldImg from "../assets/armoryIcons/shadowmeld.png";
import shadowstalkImg from "../assets/armoryIcons/shadowstalk.png";
import sharpenedFemurImg from "../assets/armoryIcons/sharpenedFemur.png";
import shroudOfTheDamnedImg from "../assets/armoryIcons/shroudOfTheDamned.png";
import sinewBowImg from "../assets/armoryIcons/sinewBow.png";
import skullsplitterImg from "../assets/armoryIcons/skullsplitter.png";
import soulreaverImg from "../assets/armoryIcons/soulreaver.png";
import soulstoneImg from "../assets/armoryIcons/soulstone.png";
import spikedBootsImg from "../assets/armoryIcons/spikedBoots.png";
import temporalSlaughterImg from "../assets/armoryIcons/temporalSlaughter.png";
import towerOfPainImg from "../assets/armoryIcons/towerOfPain.png";
import twinbladeTornadoImg from "../assets/armoryIcons/twinbladeTornado.png";
import vengeanceStrikeImg from "../assets/armoryIcons/vengeanceStrike.png";
import venomSacImg from "../assets/armoryIcons/venomSac.png";
import voidBeaconImg from "../assets/armoryIcons/voidBeacon.png";
import wallcrawlerImg from "../assets/armoryIcons/wallcrawler.png";
import warcryOfTheDamnedImg from "../assets/armoryIcons/warcryOfTheDamned.png";
import widowMakerCrossbowImg from "../assets/armoryIcons/widowMakerCrossbow.png";
import worldbreakerMaulImg from "../assets/armoryIcons/worldbreakerMaul.png";

// Create image mapping
const imageMapping = {
  aegis_of_the_tyrant: aegisOfTheTyrantImg,
  armor_of_the_undying: armorOfTheUndyingImg,
  arrowstorm: arrowstormImg,
  barbed_wire: barbedWireImg,
  berserker_brew: berserkerBrewImg,
  berserkers_twin_axes: berserkersTwinAxesImg,
  blazing_pitch: blazingPitchImg,
  blood_shield: bloodShieldImg,
  blood_vial: bloodVialImg,
  bloodfrenzy: bloodfrenzyImg,
  bloodhound: bloodhoundImg,
  bloodied_cleaver: bloodiedCleaverImg,
  bloodmist_dodge: bloodmistDodgeImg,
  bloodrush: bloodrushImg,
  bone_dust: boneDustImg,
  bonecrusher: bonecrusherImg,
  boneforged_shield: boneforgedShieldImg,
  boots_of_the_conqueror: bootsOfTheConquerorImg,
  bow_of_the_apocalypse: bowOfTheApocalypseImg,
  breath_of_plague: breathOfPlagueImg,
  brutalize: brutalizeImg,
  butchers_toolkit: butchersToolkitImg,
  cannibals_jerky: cannibalsJerkyImg,
  carrion_crow_cloak: carrionCrowCloakImg,
  chain_of_torment: chainOfTormentImg,
  cloak_of_flayed_shadows: cloakOfFlayedShadowsImg,
  cosmic_horror_glimpse: cosmicHorrorGlimpseImg,
  crown_of_subjugation: crownOfSubjugationImg,
  cruel_caltrops: cruelCaltropsImg,
  crushing_blow: crushingBlowImg,
  deadeye: deadeyeImg,
  death_from_above: deathFromAboveImg,
  disembowel: disembowelImg,
  draught_of_living_death: draughtOfLivingDeathImg,
  essence_of_agony: essenceOfAgonyImg,
  eviscerate: eviscerateImg,
  executioners_hood: executionersHoodImg,
  fate_twisting_vial: fateTwistingVialImg,
  fists_of_the_colossus: fistsOfTheColossusImg,
  flayed_skin_vest: flayedSkinVestImg,
  flesh_knitting_salve: fleshKnittingSalveImg,
  fleshrender: fleshrenderImg,
  fleshweave_armor: fleshweaveArmorImg,
  gauntlets_of_flesh_rending: gauntletsOfFleshRendingImg,
  greek_fire_flask: greekFireFlaskImg,
  harbinger_of_doom: harbingerOfDoomImg,
  heartpiercer_longbow: heartpiercerLongbowImg,
  hellfire_grenade: hellfireGrenadeImg,
  hellforged_axe: hellforgedAxeImg,
  hellgate: hellgateImg,
  helm_of_maddening_visions: helmOfMaddeningVisionsImg,
  ichor_of_the_gods: ichorOfTheGodsImg,
  juggernauts_bulwark: juggernautsBulwarkImg,
  knuckle_dusters: knuckleDustersImg,
  liquid_courage: liquidCourageImg,
  meatstorm: meatstormImg,
  miasma_bomb: miasmaBombImg,
  mindshatter: mindshatterImg,
  morningstar_of_agony: morningstarOfAgonyImg,
  oblivion_essence: oblivionEssenceImg,
  painkiller_herbs: painkillerHerbsImg,
  paradox_scroll: paradoxScrollImg,
  paranoia_powder: paranoiaPowderImg,
  pendant_of_suffering: pendantOfSufferingImg,
  phantom_skin: phantomSkinImg,
  philosophers_bane: philosophersBaneImg,
  phoenix_blood: phoenixBloodImg,
  rage_essence: rageEssenceImg,
  ribcage_cuirass: ribcageCuirassImg,
  riposte_of_agony: riposteOfAgonyImg,
  scroll_of_blood_magic: scrollOfBloodMagicImg,
  shadowmeld: shadowmeldImg,
  shadowstalk: shadowstalkImg,
  sharpened_femur: sharpenedFemurImg,
  shroud_of_the_damned: shroudOfTheDamnedImg,
  sinew_bow: sinewBowImg,
  skullsplitter: skullsplitterImg,
  soulreaver: soulreaverImg,
  soulstone: soulstoneImg,
  spiked_boots: spikedBootsImg,
  temporal_slaughter: temporalSlaughterImg,
  tower_of_pain: towerOfPainImg,
  twinblade_tornado: twinbladeTornadoImg,
  vengeance_strike: vengeanceStrikeImg,
  venom_sac: venomSacImg,
  void_beacon: voidBeaconImg,
  wallcrawler: wallcrawlerImg,
  warcry_of_the_damned: warcryOfTheDamnedImg,
  widow_maker_crossbow: widowMakerCrossbowImg,
  worldbreaker_maul: worldbreakerMaulImg,
};

/**
 * Get the image for a given armory item ID
 * @param {string} itemId - The snake_case ID of the item
 * @returns {string} The image URL for the item
 */
export const getImageForItem = (itemId) => {
  if (!imageMapping[itemId]) {
    console.warn(`No image found for item ID: ${itemId}`);
    return null;
  }
  return imageMapping[itemId];
};

export default getImageForItem;
