import React from "react";
import "../../../styles/ArmoryItem.css";
import getImageForItem from "../../../utils/ArmoryImages";
import lootIcon from "../../../assets/images/loot.png";
import armoryItemCard from "../../../assets/images/armoryItemCard.png";
import lockedArmoryItemOverlay from "../../../assets/images/lockedArmoryItemOverlay.png";

const formatDecimal = (value) => {
  const str = value.toFixed(2);
  return str.replace(/\.?0+$/, "");
};

const formatNumber = (num) => {
  const trillion = 1000000000000;
  const billion = 1000000000;
  const million = 1000000;
  const thousand = 1000;

  if (num >= trillion) {
    const value = num / trillion;
    return `${formatDecimal(value)}T`;
  }
  if (num >= billion) {
    const value = num / billion;
    return `${formatDecimal(value)}B`;
  }
  if (num >= million) {
    const value = num / million;
    return `${formatDecimal(value)}M`;
  }
  if (num >= thousand) {
    const value = num / thousand;
    return `${formatDecimal(value)}K`;
  }
  return num.toString();
};

const threeLineTitles = [
  "Helm of Maddening Visions",
  "Cloak of Flayed Shadows",
  "Cosmic Horror Glimpse",
];

const longWordTitles = ["Worldbreaker Maul"];

const getTitleSizeClass = (title) => {
  if (threeLineTitles.includes(title)) return "small-text";
  if (longWordTitles.includes(title)) return "medium-text";
  return "regular-text";
};

const getFooterLevelClass = (hideLevel, isMaxLevel) => {
  if (hideLevel) return "level-hidden";
  if (isMaxLevel) return "price-hidden";
  return "level-showing";
};

const ArmoryItem = ({
  id,
  title,
  description,
  isLocked,
  currentLevel,
  bountyBoostUpgrade,
  price,
  canAfford,
  dependenciesMet,
  isMaxLevel,
  onSelect,
  width,
  hideLevel,
}) => {
  const itemImage = getImageForItem(id);
  const nextLevelDisplay = isMaxLevel ? "MAX" : `${formatNumber(price)}`;
  const titleSizeClass = getTitleSizeClass(title);
  const footerLevelClass = getFooterLevelClass(hideLevel, isMaxLevel);

  const style = {
    ...(isMaxLevel && { cursor: "default" }),
    ...(width && { "--armory-item-width": `${width}px` }),
  };

  return (
    <div className="armory-item" onClick={onSelect} style={style}>
      <img src={armoryItemCard} alt="" className="armory-item-background" />
      <div className="armory-item-content">
        <div className="armory-item-header">
          <div className="item-icon-container">
            <img src={itemImage} alt={title} className="item-icon" />
          </div>
          <div className="armory-item-header-copy">
            <div className="item-title-container">
              <div className={`item-title ${titleSizeClass}`}>{title}</div>
            </div>
            {!isMaxLevel && (
              <div className="bounty-boost-container">
                <div className="bounty-boost-title">Bounty Boost:</div>
                <div className="bounty-boost-value">
                  {`+${bountyBoostUpgrade.toFixed(2)}x`}
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="armory-item-footer">
          <div className={`footer-content ${footerLevelClass}`}>
            {!hideLevel && <span>Lv. {currentLevel}</span>}
            {!isMaxLevel && (
              <div className="price">
                <img
                  src={lootIcon}
                  alt="loot"
                  className="loot-icon-armory-item"
                />
                <span>{nextLevelDisplay}</span>
              </div>
            )}
          </div>
        </div>
      </div>
      {isLocked && (
        <img
          src={lockedArmoryItemOverlay}
          alt=""
          className="armory-item-locked-overlay"
        />
      )}
    </div>
  );
};

export default ArmoryItem;
