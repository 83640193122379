import React from "react";
import "../../../styles/DailySpoilsDayItem.css";

// Active state
import dailySpoilsDayItemActiveLeft from "../../../assets/images/dailySpoilsDayItemActiveLeft.png";
import dailySpoilsDayItemActiveCenter from "../../../assets/images/dailySpoilsDayItemActiveCenter.png";
import dailySpoilsDayItemActiveRight from "../../../assets/images/dailySpoilsDayItemActiveRight.png";

// Completed state
import dailySpoilsDayItemCompletedLeft from "../../../assets/images/dailySpoilsDayItemCompletedLeft.png";
import dailySpoilsDayItemCompletedCenter from "../../../assets/images/dailySpoilsDayItemCompletedCenter.png";
import dailySpoilsDayItemCompletedRight from "../../../assets/images/dailySpoilsDayItemCompletedRight.png";

// Locked state
import dailySpoilsDayItemLockedLeft from "../../../assets/images/dailySpoilsDayItemLockedLeft.png";
import dailySpoilsDayItemLockedCenter from "../../../assets/images/dailySpoilsDayItemLockedCenter.png";
import dailySpoilsDayItemLockedRight from "../../../assets/images/dailySpoilsDayItemLockedRight.png";

import lootIcon from "../../../assets/images/loot.png";

const DailySpoilsDayItem = ({
  day,
  reward,
  isCompleted = false,
  isActive = false,
  isLocked = false,
  isFullWidth = false,
  onClick,
}) => {
  const getStateImages = () => {
    if (isLocked) {
      return {
        left: dailySpoilsDayItemLockedLeft,
        center: dailySpoilsDayItemLockedCenter,
        right: dailySpoilsDayItemLockedRight,
      };
    }
    if (isCompleted) {
      return {
        left: dailySpoilsDayItemCompletedLeft,
        center: dailySpoilsDayItemCompletedCenter,
        right: dailySpoilsDayItemCompletedRight,
      };
    }
    if (isActive) {
      return {
        left: dailySpoilsDayItemActiveLeft,
        center: dailySpoilsDayItemActiveCenter,
        right: dailySpoilsDayItemActiveRight,
      };
    }
    return {
      left: dailySpoilsDayItemLockedLeft,
      center: dailySpoilsDayItemLockedCenter,
      right: dailySpoilsDayItemLockedRight,
    };
  };

  const images = getStateImages();

  const formatReward = (amount) => {
    if (amount >= 1000000) return `1,000,000`;
    if (amount >= 1000) return `${amount / 1000}K`;
    return amount.toString();
  };

  return (
    <div
      className={`daily-spoils-day-item ${isActive ? "active" : ""} ${
        isLocked ? "locked" : ""
      } ${isFullWidth ? "full-width" : ""}`}
      style={{ opacity: isCompleted ? 0.75 : 1 }}
    >
      <img src={images.left} alt="" className="item-left" />
      <img src={images.center} alt="" className="item-center" />
      <img src={images.right} alt="" className="item-right" />

      <div className="item-content">
        <div className="day-text" style={{ opacity: isLocked ? 0.4 : 1 }}>
          DAY {day}
          {day === 10 ? "+" : ""}
        </div>
        <img
          src={lootIcon}
          alt=""
          className="loot-icon-daily-spoils"
          style={{ opacity: isLocked ? 0.6 : 1 }}
        />
        <div className="reward-text" style={{ opacity: isLocked ? 0.4 : 1 }}>
          {formatReward(reward)}
        </div>
      </div>
    </div>
  );
};

export default DailySpoilsDayItem;
