// const env = process.env.REACT_APP_ENV || "prod";
const env = process.env.REACT_APP_ENV || "dev";

const config = {
  dev: {
    apiUrl: "https://devapi.bishopinteractive.xyz/v1.0.0/brutalknights/",
  },
  test: {
    apiUrl: "https://testapi.bishopinteractive.xyz/v1.0.0/brutalknights/",
  },
  prod: {
    apiUrl: "https://api.bishopinteractive.xyz/v1.0.0/brutalknights/",
  },
};

export default config[env];
