import React from "react";
import "../styles/CallToActionHeader.css";
import CTAHeaderPlatform from "../assets/images/CTAHeaderPlatform.png";

function CallToActionHeader({ CTAHeaderTextIcon }) {
  return (
    <div className="call-to-action-header">
      <img
        src={CTAHeaderPlatform}
        alt="CTA Platform"
        className="cta-platform"
      />
      <img
        src={CTAHeaderTextIcon}
        alt="CTA Header"
        className="cta-header-text"
      />
    </div>
  );
}

export default CallToActionHeader;
