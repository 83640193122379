import React, { useState, useMemo } from "react";
import BottomSheet from "../../BottomSheet";
import DailySpoilsDayItem from "./DailySpoilsDayItem";
import QuestsTitleSeparator from "./QuestsTitleSeparator";
import "../../../styles/DailySpoilsBottomSheet.css";

import dailySpoilsTitle from "../../../assets/images/dailySpoilsTitle.png";
import dailySpoilsClaimButton from "../../../assets/images/dailySpoilsClaimButton.png";
import dailySpoilsClaimButtonPressed from "../../../assets/images/dailySpoilsClaimButtonPressed.png";
import dailySpoilsClaimButtonDisabled from "../../../assets/images/dailySpoilsClaimButtonDisabled.png";

const DailySpoilsBottomSheet = ({
  isOpen,
  onCancel,
  userInfo,
  onClaimDailySpoils,
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const rewards = [
    1000, 2500, 5000, 10000, 25000, 50000, 100000, 250000, 500000, 1000000,
  ];

  const { currentDay, hasClaimedToday } = useMemo(() => {
    const now = new Date();
    if (!userInfo?.brutalBlocksDSLastClaimDate) {
      return { currentDay: 1, hasClaimedToday: false };
    }

    const lastClaimDate = new Date(userInfo.brutalBlocksDSLastClaimDate);
    const lastClaimUTCDate = lastClaimDate.toISOString().split("T")[0];
    const todayUTCDate = now.toISOString().split("T")[0];
    const yesterdayUTCDate = new Date(now.getTime() - 86400000)
      .toISOString()
      .split("T")[0];

    const claimedToday = lastClaimUTCDate === todayUTCDate;

    let day = 1;
    if (lastClaimUTCDate === yesterdayUTCDate) {
      day = Math.min((userInfo.brutalBlocksDSClaimStreak || 0) + 1, 10);
    } else if (!claimedToday) {
      day = 1;
    } else {
      day = Math.min(userInfo.brutalBlocksDSClaimStreak || 0, 10);
    }

    return { currentDay: day, hasClaimedToday: claimedToday };
  }, [userInfo]);

  const getClaimButtonImage = () => {
    if (hasClaimedToday) return dailySpoilsClaimButtonDisabled;
    if (isLoading) return dailySpoilsClaimButtonPressed;
    return dailySpoilsClaimButton;
  };

  const handleClaim = async () => {
    setIsLoading(true);
    try {
      await onClaimDailySpoils();
    } catch (error) {
      console.error("Error claiming daily spoils:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const claimButtonImage = getClaimButtonImage();

  return (
    <BottomSheet
      isOpen={isOpen}
      onCancel={onCancel}
      canTapOutToCancel={true}
      hideButtons={true}
      height={600}
    >
      <div className="daily-spoils-container">
        <img
          src={dailySpoilsTitle}
          alt="Daily Spoils"
          className="title-image"
        />
        <QuestsTitleSeparator width="90%" />

        <div className="rewards-grid">
          {rewards.map((reward, index) => (
            <DailySpoilsDayItem
              key={index}
              day={index + 1}
              reward={reward}
              isCompleted={
                index + 1 < currentDay ||
                (index + 1 === currentDay && hasClaimedToday)
              }
              isActive={index + 1 === currentDay && !hasClaimedToday}
              isLocked={index + 1 > currentDay}
              isFullWidth={index === 9}
              onClick={
                index + 1 === currentDay && !hasClaimedToday
                  ? handleClaim
                  : undefined
              }
            />
          ))}
        </div>

        <div className="claim-button-container">
          <img
            src={claimButtonImage}
            alt={hasClaimedToday ? "Claim Again Tomorrow" : "Claim"}
            className={`claim-button ${
              !hasClaimedToday && !isLoading ? "clickable" : ""
            }`}
            onClick={!hasClaimedToday && !isLoading ? handleClaim : undefined}
          />
        </div>
      </div>
    </BottomSheet>
  );
};

export default DailySpoilsBottomSheet;
