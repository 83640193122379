import React, { useState } from "react";
import battleButton from "../../../assets/images/battleButton.png";
import battleButtonPressed from "../../../assets/images/battleButtonPressed.png";
import battleButtonLine from "../../../assets/images/battleButtonLine.png";
import "../../../styles/BattleButton.css";

function BattleButton({ onClick }) {
  const [isPressed, setIsPressed] = useState(false);

  const handlePress = () => {
    onClick();
  };
  const handleMouseDown = () => {
    setIsPressed(true);
  };
  const handleMouseUp = () => {
    setIsPressed(false);
  };

  return (
    <div className="battle-button-container">
      <img
        src={battleButtonLine}
        alt="Battle Button Line"
        className="battle-button-line"
      />
      <button
        className="battle-button"
        onClick={handlePress}
        onTouchStart={handleMouseDown}
        onTouchEnd={handleMouseUp}
        onMouseDown={handleMouseDown}
        onMouseUp={handleMouseUp}
        onMouseLeave={handleMouseUp}
      >
        <img
          src={isPressed ? battleButtonPressed : battleButton}
          alt="Battle"
          className="battle-button-image"
        />
      </button>
    </div>
  );
}

export default BattleButton;
