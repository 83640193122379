let logs = [];
const maxLogs = 100; // Adjust this value to set the maximum number of logs to keep

export const logToGlobalLogger = (newText, stringParam) => {
  const now = new Date();
  const timestamp = now.toLocaleTimeString([], {
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
  });
  let message = "";
  if (stringParam != null) {
    message = newText + stringParam;
  } else {
    message = newText;
  }
  logs.unshift({ timestamp, message });

  // Keep only the last 'maxLogs' entries
  if (logs.length > maxLogs) {
    logs = logs.slice(0, maxLogs);
  }
};

export const getGlobalLogs = () => {
  return [...logs];
};

export const clearGlobalLogs = () => {
  logs = [];
};
