import React, {
  useEffect,
  useState,
  useRef,
  useCallback,
  useMemo,
  useImperativeHandle,
  forwardRef,
} from "react";
import { Unity, useUnityContext } from "react-unity-webgl";
import LoadingView from "./LoadingView";
import { logToGlobalLogger } from "../utils/GlobalLogger";

const UnityGame = forwardRef(
  (
    {
      elo,
      character,
      userId,
      onUnityQuit,
      roomId,
      opponentUsername,
      onFriendMatchConcluded,
    },
    ref
  ) => {
    const containerRef = useRef(null);
    const [errors, setErrors] = useState([]);

    let devicePixelRatio = useMemo(() => window.devicePixelRatio || 1, []);
    logToGlobalLogger("devicePixelratio: " + devicePixelRatio);

    const {
      unityProvider,
      sendMessage,
      addEventListener,
      removeEventListener,
      loadingProgression,
      unload,
      isLoaded,
    } = useUnityContext({
      loaderUrl: "unity/build/demoBuild.loader.js",
      dataUrl: "unity/build/demoBuild.data",
      frameworkUrl: "unity/build/demoBuild.framework.js",
      codeUrl: "unity/build/demoBuild.wasm",
      webglContextAttributes: {
        antialias: false,
        powerPreference: 2,
        desynchronized: true,
      },
      matchWebGLToCanvasSize: true,
    });

    const [isLoading, setIsLoading] = useState(true);

    const addError = useCallback((message) => {
      setErrors((prevErrors) => [...prevErrors, message]);
    }, []);

    useImperativeHandle(ref, () => ({
      unloadUnity: async () => {
        if (isLoaded) {
          try {
            setIsLoading(true);
            await unload();
            console.log("Unity instance unloaded successfully");
          } catch (err) {
            console.error("Error unloading Unity instance:", err);
            addError(`Error unloading Unity instance: ${err.message}`);
          }
        }
      },
    }));

    const handleFriendMatchConcluded = useCallback(() => {
      console.log("Friend match concluded event received in UnityGame");
      onFriendMatchConcluded && onFriendMatchConcluded();
    }, [onFriendMatchConcluded]);

    useEffect(() => {
      if (isLoaded) {
        const gameParameters = {
          elo,
          character,
          userId,
        };

        if (roomId && opponentUsername) {
          gameParameters.roomId = roomId;
          gameParameters.opponentUsername = opponentUsername;
        }

        console.log("Updating game parameters:", gameParameters);
        logToGlobalLogger(
          "UnityGame.js: Updating game parameters: " +
            JSON.stringify(gameParameters)
        );

        sendMessage(
          "GameManager",
          "SetGameParameters",
          JSON.stringify(gameParameters)
        );
      }
    }, [
      isLoaded,
      elo,
      character,
      userId,
      roomId,
      opponentUsername,
      sendMessage,
    ]);

    useEffect(() => {
      const handleUnityLoaded = () => {
        console.log("Unity game loaded");
        setIsLoading(false);
        const gameParameters = {
          elo,
          character,
          userId,
        };

        if (roomId && opponentUsername) {
          gameParameters.roomId = roomId;
          gameParameters.opponentUsername = opponentUsername;
        }

        sendMessage(
          "GameManager",
          "SetGameParameters",
          JSON.stringify(gameParameters)
        );
      };

      const handleUnityError = (message) => {
        console.error("Unity error:", message);
        addError(`Unity Error: ${message}`);
      };

      const handleUnityQuit = () => {
        console.log("Unity application quit");
        onUnityQuit && onUnityQuit();
      };

      addEventListener("unityLoaded", handleUnityLoaded);
      addEventListener("error", handleUnityError);
      addEventListener("unityQuit", handleUnityQuit);
      addEventListener("friendMatchConcluded", handleFriendMatchConcluded);

      return () => {
        removeEventListener("unityLoaded", handleUnityLoaded);
        removeEventListener("error", handleUnityError);
        removeEventListener("unityQuit", handleUnityQuit);
        removeEventListener("friendMatchConcluded", handleFriendMatchConcluded);
      };
    }, [
      elo,
      character,
      userId,
      sendMessage,
      addEventListener,
      removeEventListener,
      addError,
      onUnityQuit,
      roomId,
      opponentUsername,
      handleFriendMatchConcluded,
    ]);

    const clearErrors = () => {
      setErrors([]);
    };

    return (
      <div
        ref={containerRef}
        style={{ width: "100%", height: "100%", position: "relative" }}
      >
        {errors.length > 0 && (
          <div
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              padding: "10px",
              backgroundColor: "rgba(255, 0, 0, 0.8)",
              color: "white",
              zIndex: 1000,
            }}
          >
            <button
              onClick={clearErrors}
              style={{
                position: "absolute",
                top: "5px",
                right: "5px",
                background: "none",
                border: "none",
                color: "white",
                fontSize: "16px",
                cursor: "pointer",
              }}
            >
              ✕
            </button>
            <ul style={{ listStyleType: "none", padding: 0 }}>
              {errors.map((error, index) => (
                <li key={index}>{error}</li>
              ))}
            </ul>
          </div>
        )}
        {isLoading && <LoadingView loadingProgression={loadingProgression} />}
        <Unity
          unityProvider={unityProvider}
          style={{ width: "100%", height: "100%" }}
          devicePixelRatio={devicePixelRatio}
        />
      </div>
    );
  }
);

export default UnityGame;
