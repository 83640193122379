import React, { useState, useEffect } from "react";
import BackgroundContainer from "../components/BackgroundContainer";
import loadingSpinner from "../assets/images/loadingSpinner.svg";
import "../styles/LoadingComponentFade.css";

const LoadingComponentFade = ({ shouldFadeOut = true }) => {
  const [opacity, setOpacity] = useState(1);
  const [display, setDisplay] = useState("flex");

  useEffect(() => {
    if (shouldFadeOut) {
      const timer = setTimeout(() => {
        setOpacity(0);
      }, 666);

      return () => clearTimeout(timer);
    }
  }, [shouldFadeOut]);

  const handleTransitionEnd = () => {
    if (opacity === 0) {
      setDisplay("none");
    }
  };

  return (
    <div
      className="loading-container"
      style={{
        opacity: shouldFadeOut ? opacity : 1,
        transition: "opacity 0.5s ease-out",
        display: display,
      }}
      onTransitionEnd={handleTransitionEnd}
    >
      <BackgroundContainer />
      <div className="loading-content-container">
        <div className="initial-loading-text">LOADING</div>
        <img src={loadingSpinner} alt="Loading" className="spinner" />
      </div>
    </div>
  );
};

export default LoadingComponentFade;
