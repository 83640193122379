import React from "react";
import ultimate from "../../../assets/images/ultimate.png";
import passive from "../../../assets/images/passive.png";
import largeContainer from "../../../assets/images/largeContainer.png";
import "../../../styles/AbilityDisplay.css";

function AbilityDisplay({
  ultimateAbility,
  ultimateTitle,
  passiveAbility,
  passiveTitle,
  handleDisplayClick,
}) {
  const abilities = [
    {
      name: ultimateTitle,
      type: "ULT",
      icon: ultimate,
      description: ultimateAbility,
    },
    {
      name: passiveTitle,
      type: "PSV",
      icon: passive,
      description: passiveAbility,
    },
  ];

  return (
    <div className="ability-display">
      {abilities.map((ability) => (
        <div
          key={ability.type}
          className="ability-item"
          style={{ backgroundImage: `url(${largeContainer})` }}
          onClick={() =>
            handleDisplayClick(ability.name, ability.description, ability.icon)
          }
        >
          <img src={ability.icon} alt={ability.type} className="ability-icon" />
          <span className="ability-name">{ability.name}</span>
        </div>
      ))}
    </div>
  );
}

export default AbilityDisplay;
