import React, { useState, useMemo, useCallback } from "react";
import CallToActionHeader from "../../CallToActionHeader";
import SectionSelector from "./SectionSelector";
import ArmoryGrid from "./ArmoryGrid";
import PurchaseArmoryItemBottomSheet from "./PurchaseArmoryItemBottomSheet";
import armoryCTAHeaderText from "../../../assets/images/armoryCTAHeaderText.png";
import ArmoryItems from "../../../utils/AmoryItems";
import { getItemById } from "../../../utils/ArmoryUtils";
import "../../../styles/ArmoryScreen.css";

const SECTIONS = ["Gear", "Skills", "Consumables"];

const ArmoryScreen = ({ userInfo, onItemUpgrade }) => {
  const [selectedSection, setSelectedSection] = useState(SECTIONS[0]);
  const [selectedItem, setSelectedItem] = useState(null);

  const itemsByCategory = useMemo(() => {
    return ArmoryItems.reduce((acc, item) => {
      if (!acc[item.category]) {
        acc[item.category] = [];
      }
      acc[item.category].push(item);
      return acc;
    }, {});
  }, []);

  const getCurrentLevel = useCallback(
    (itemId) => {
      return userInfo?.brutalBlocksArmoryItems?.[itemId]?.level || 0;
    },
    [userInfo?.brutalBlocksArmoryItems]
  );

  const areDependenciesMet = useCallback(
    (item) => {
      if (!item.dependencies || item.dependencies.length === 0) return true;

      return item.dependencies.every((dep) => {
        const currentLevel = getCurrentLevel(dep.itemId);
        return currentLevel >= dep.level;
      });
    },
    [getCurrentLevel]
  );

  const getNextLevelInfo = useCallback(
    (item) => {
      const currentLevel = getCurrentLevel(item.itemId);
      const nextUpgrade = item.upgrades[currentLevel];

      if (!nextUpgrade) return null;

      return nextUpgrade;
    },
    [getCurrentLevel]
  );

  const displayItems = useMemo(() => {
    const categoryItems = itemsByCategory[selectedSection] || [];
    const userLoot = userInfo?.brutalBlocksLoot || 0;

    return categoryItems.map((item) => {
      const currentLevel = getCurrentLevel(item.itemId);
      const nextLevel = getNextLevelInfo(item);
      const dependenciesMet = areDependenciesMet(item);
      const canAfford = userLoot >= (nextLevel?.cost || 0);
      const isMaxLevel = currentLevel >= item.upgrades.length;

      const resolvedDependencies =
        item.dependencies?.map((dep) => ({
          ...dep,
          name: getItemById(dep.itemId)?.name || "Unknown Item",
        })) || [];

      return {
        id: item.itemId,
        title: item.name,
        description: item.description,
        isLocked: currentLevel === 0 || !dependenciesMet,
        hideLevel: currentLevel === 0,
        currentLevel,
        bountyBoostUpgrade: nextLevel?.bountyBoostUpgrade || 0,
        price: nextLevel?.cost || 0,
        canAfford,
        dependenciesMet,
        isMaxLevel,
        dependencies: resolvedDependencies,
        category: item.category,
        subcategory: item.subcategory,
        isUnlocked: currentLevel,
      };
    });
  }, [
    selectedSection,
    itemsByCategory,
    userInfo?.brutalBlocksLoot,
    getNextLevelInfo,
    getCurrentLevel,
    areDependenciesMet,
  ]);

  const handleItemSelect = useCallback(
    (itemId) => {
      const itemLevel = getCurrentLevel(itemId);
      if (itemLevel === 20) return;
      const item = displayItems.find((i) => i.id === itemId);
      if (item) {
        setSelectedItem(item);
      }
    },
    [displayItems, getCurrentLevel]
  );

  const handleConfirmPurchase = useCallback(() => {
    if (selectedItem) {
      onItemUpgrade?.(selectedItem.id);
      setSelectedItem(null);
    }
  }, [selectedItem, onItemUpgrade]);

  return (
    <div className="armory-screen">
      <CallToActionHeader CTAHeaderTextIcon={armoryCTAHeaderText} />
      <div className="armory-content">
        <SectionSelector
          sections={SECTIONS}
          selectedSection={selectedSection}
          onSelectSection={setSelectedSection}
        />
        <ArmoryGrid items={displayItems} onItemSelect={handleItemSelect} />
      </div>
      <PurchaseArmoryItemBottomSheet
        isOpen={!!selectedItem}
        item={selectedItem}
        onCancel={() => setSelectedItem(null)}
        onConfirm={handleConfirmPurchase}
      />
    </div>
  );
};

export default ArmoryScreen;
