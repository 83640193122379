import React from "react";
import "../styles/SectionHeader.css";
import sectionHeaderBg from "../assets/images/sectionHeader.png";

function SectionHeader({ sectionName }) {
  return (
    <div className="section-header-container">
      <div className="section-header-background">
        <img src={sectionHeaderBg} alt="Section header background" />
      </div>
      <div className="section-header-text">{sectionName}</div>
    </div>
  );
}

export default SectionHeader;
