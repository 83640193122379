// Import all sound files
function importAll(r) {
  let sounds = {};
  r.keys().forEach((key) => {
    sounds[key.replace(/^\.\//, "").replace(/\.[^/.]+$/, "")] = r(key);
  });
  return sounds;
}

const soundFiles = importAll(
  require.context("../assets/sounds", false, /\.(mp3)$/)
);

// Create an AudioContext
const AudioContext = window.AudioContext || window.webkitAudioContext;
const audioContext = new AudioContext();

// Global volume control
let globalVolume = 1;
let isMuted = false;

// Create a global gain node for volume control
const gainNode = audioContext.createGain();
gainNode.gain.value = globalVolume;
gainNode.connect(audioContext.destination);

// Object to store AudioBuffers
const audioBuffers = {};

// Object to store active sound sources
const activeSources = {};

// Function to load a sound and decode it
const loadSound = (soundName, soundPath) => {
  return fetch(soundPath)
    .then((response) => response.arrayBuffer())
    .then((arrayBuffer) => audioContext.decodeAudioData(arrayBuffer))
    .then((audioBuffer) => {
      audioBuffers[soundName] = audioBuffer;
    })
    .catch((error) => {
      console.error(`Error loading sound ${soundName}:`, error);
    });
};

// Preload all sounds
const preloadPromises = Object.entries(soundFiles).map(
  ([soundName, soundPath]) => {
    return loadSound(soundName, soundPath);
  }
);

// Wait for all sounds to be preloaded
Promise.all(preloadPromises)
  .then(() => {
    console.log("All sounds preloaded");
  })
  .catch((error) => {
    console.error("Error preloading sounds:", error);
  });

// Unlock audio context on user interaction (required for some mobile browsers)
const unlockAudioContext = () => {
  if (audioContext.state !== "suspended") return;
  audioContext.resume().then(() => {
    console.log("AudioContext resumed");
  });
  window.removeEventListener("touchend", unlockAudioContext);
  window.removeEventListener("click", unlockAudioContext);
};

window.addEventListener("touchend", unlockAudioContext, false);
window.addEventListener("click", unlockAudioContext, false);

// Function to set global volume
export const setVolume = (volume) => {
  globalVolume = Math.max(0, Math.min(1, volume));
  gainNode.gain.value = isMuted ? 0 : globalVolume;
};

// Function to toggle mute
export const toggleMute = () => {
  isMuted = !isMuted;
  gainNode.gain.value = isMuted ? 0 : globalVolume;
};

// Function to play a sound using Web Audio API
export const playSound = (soundName) => {
  if (audioBuffers[soundName]) {
    const source = audioContext.createBufferSource();
    source.buffer = audioBuffers[soundName];
    source.connect(gainNode);
    source.start(0);

    // Store the source
    if (!activeSources[soundName]) {
      activeSources[soundName] = [];
    }
    activeSources[soundName].push(source);

    // Remove the source when it ends
    source.onended = () => {
      const index = activeSources[soundName].indexOf(source);
      if (index !== -1) {
        activeSources[soundName].splice(index, 1);
      }
    };
  } else {
    console.error(`Sound not found: ${soundName}`);
  }
};

// Function to stop a sound
export const stopSound = (soundName) => {
  if (activeSources[soundName]) {
    activeSources[soundName].forEach((source) => {
      source.stop();
    });
    activeSources[soundName] = [];
  }
};

// Explicitly create and export play functions for each sound
export const playSoundFunctions = {};

Object.keys(soundFiles).forEach((soundName) => {
  const functionName = `playSound${
    soundName.charAt(0).toUpperCase() + soundName.slice(1)
  }`;
  playSoundFunctions[functionName] = () => playSound(soundName);
});

// Create stop functions for each sound
export const stopSoundFunctions = {};

Object.keys(soundFiles).forEach((soundName) => {
  const functionName = `stopSound${
    soundName.charAt(0).toUpperCase() + soundName.slice(1)
  }`;
  stopSoundFunctions[functionName] = () => stopSound(soundName);
});

// Export all play and stop functions
export const {
  playSoundSelectionPrimary,
  playSoundNavItemSwitch,
  playSoundDeselect,
  playSoundBattle,
  playSoundBOTBK,
} = playSoundFunctions;

export const {
  stopSoundSelectionPrimary,
  stopSoundNavItemSwitch,
  stopSoundDeselect,
  stopSoundBattle,
  stopSoundBOTBK,
} = stopSoundFunctions;

// Log available sound functions
console.log("Available play sound functions:", Object.keys(playSoundFunctions));
console.log("Available stop sound functions:", Object.keys(stopSoundFunctions));
