import React from "react";
import ArmoryItem from "./ArmoryItem";
import "../../../styles/ArmoryGrid.css";

const ArmoryGrid = ({ items, onItemSelect }) => {
  return (
    <div className="armory-grid">
      <div className="armory-grid-inner">
        {items.map((item) => (
          <ArmoryItem
            key={item.id}
            {...item}
            onSelect={() => onItemSelect(item.id)}
          />
        ))}
      </div>
    </div>
  );
};

export default ArmoryGrid;
