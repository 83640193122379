import React, { useState, useEffect, useRef } from "react";
import AutoSizingText from "../../AutoSizingText";

import "../../../styles/PopUpWithIcon.css";

const PopUpWithIcon = ({ isOpen, onClose, title, content, icon }) => {
  const [isVisible, setIsVisible] = useState(isOpen);
  const [animationClass, setAnimationClass] = useState("");
  const contentRef = useRef(null);

  useEffect(() => {
    if (isOpen) {
      setIsVisible(true);
      requestAnimationFrame(() => {
        requestAnimationFrame(() => {
          setAnimationClass("fade-in");
        });
      });
    } else {
      setAnimationClass("fade-out");
      const timer = setTimeout(() => setIsVisible(false), 300);
      return () => clearTimeout(timer);
    }
  }, [isOpen]);

  useEffect(() => {
    if (contentRef.current) {
      contentRef.current.style.height = `${contentRef.current.scrollHeight}px`;
    }
  }, [isVisible, content]);

  if (!isVisible) return null;

  return (
    <div className={`popup-overlay ${animationClass}`} onClick={onClose}>
      <div
        className="popup-content"
        onClick={(e) => e.stopPropagation()}
        ref={contentRef}
      >
        {icon && <img src={icon} alt={title} className="popup-icon" />}
        <div className="popup-title-container">
          <AutoSizingText
            text={title.toUpperCase()}
            maxFontSize={20}
            maxLines={2}
            textAlign="left"
          />
        </div>
        <div className="popup-body-container">
          <p className="popup-body">{content}</p>
        </div>
      </div>
    </div>
  );
};

export default PopUpWithIcon;
