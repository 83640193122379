const ArmoryItems = [
  {
    name: "Bloodied Cleaver",
    itemId: "bloodied_cleaver",
    category: "Gear",
    subcategory: "Basic",
    description:
      "A crude, heavy blade caked with dried blood. Intimidating but unwieldy.",
    dependencies: [],
    upgrades: [
      {
        level: 1,
        cost: 67000,
        bountyBoostUpgrade: 0.03,
      },
      {
        level: 2,
        cost: 111000,
        bountyBoostUpgrade: 0.03,
      },
      {
        level: 3,
        cost: 178000,
        bountyBoostUpgrade: 0.04,
      },
      {
        level: 4,
        cost: 307000,
        bountyBoostUpgrade: 0.05,
      },
      {
        level: 5,
        cost: 544000,
        bountyBoostUpgrade: 0.06,
      },
      {
        level: 6,
        cost: 887000,
        bountyBoostUpgrade: 0.07,
      },
      {
        level: 7,
        cost: 1520000,
        bountyBoostUpgrade: 0.08,
      },
      {
        level: 8,
        cost: 2660000,
        bountyBoostUpgrade: 0.1,
      },
      {
        level: 9,
        cost: 4080000,
        bountyBoostUpgrade: 0.12,
      },
      {
        level: 10,
        cost: 6250000,
        bountyBoostUpgrade: 0.14,
      },
      {
        level: 11,
        cost: 10500000,
        bountyBoostUpgrade: 0.17,
      },
      {
        level: 12,
        cost: 18600000,
        bountyBoostUpgrade: 0.2,
      },
      {
        level: 13,
        cost: 29400000,
        bountyBoostUpgrade: 0.23,
      },
      {
        level: 14,
        cost: 50100000,
        bountyBoostUpgrade: 0.28,
      },
      {
        level: 15,
        cost: 78900000,
        bountyBoostUpgrade: 0.34,
      },
      {
        level: 16,
        cost: 122000000,
        bountyBoostUpgrade: 0.41,
      },
      {
        level: 17,
        cost: 185000000,
        bountyBoostUpgrade: 0.5,
      },
      {
        level: 18,
        cost: 309000000,
        bountyBoostUpgrade: 0.5,
      },
      {
        level: 19,
        cost: 477000000,
        bountyBoostUpgrade: 0.5,
      },
      {
        level: 20,
        cost: 820000000,
        bountyBoostUpgrade: 0.49,
      },
    ],
  },
  {
    name: "Boneforged Shield",
    itemId: "boneforged_shield",
    category: "Gear",
    subcategory: "Basic",
    description:
      "A shield made from the fused bones of fallen warriors. Surprisingly sturdy.",
    dependencies: [],
    upgrades: [
      {
        level: 1,
        cost: 59000,
        bountyBoostUpgrade: 0.04,
      },
      {
        level: 2,
        cost: 94000,
        bountyBoostUpgrade: 0.04,
      },
      {
        level: 3,
        cost: 156000,
        bountyBoostUpgrade: 0.05,
      },
      {
        level: 4,
        cost: 247000,
        bountyBoostUpgrade: 0.06,
      },
      {
        level: 5,
        cost: 360000,
        bountyBoostUpgrade: 0.07,
      },
      {
        level: 6,
        cost: 575000,
        bountyBoostUpgrade: 0.08,
      },
      {
        level: 7,
        cost: 891000,
        bountyBoostUpgrade: 0.09,
      },
      {
        level: 8,
        cost: 1550000,
        bountyBoostUpgrade: 0.1,
      },
      {
        level: 9,
        cost: 2280000,
        bountyBoostUpgrade: 0.12,
      },
      {
        level: 10,
        cost: 3780000,
        bountyBoostUpgrade: 0.14,
      },
      {
        level: 11,
        cost: 5760000,
        bountyBoostUpgrade: 0.16,
      },
      {
        level: 12,
        cost: 9520000,
        bountyBoostUpgrade: 0.18,
      },
      {
        level: 13,
        cost: 16100000,
        bountyBoostUpgrade: 0.21,
      },
      {
        level: 14,
        cost: 25800000,
        bountyBoostUpgrade: 0.24,
      },
      {
        level: 15,
        cost: 42400000,
        bountyBoostUpgrade: 0.27,
      },
      {
        level: 16,
        cost: 70000000,
        bountyBoostUpgrade: 0.31,
      },
      {
        level: 17,
        cost: 117000000,
        bountyBoostUpgrade: 0.31,
      },
      {
        level: 18,
        cost: 200000000,
        bountyBoostUpgrade: 0.31,
      },
      {
        level: 19,
        cost: 329000000,
        bountyBoostUpgrade: 0.31,
      },
      {
        level: 20,
        cost: 498000000,
        bountyBoostUpgrade: 0.3,
      },
    ],
  },
  {
    name: "Spiked Boots",
    itemId: "spiked_boots",
    category: "Gear",
    subcategory: "Basic",
    description:
      "Heavy boots with metal spikes. Deadly kicks, poor for stealth.",
    dependencies: [],
    upgrades: [
      {
        level: 1,
        cost: 57000,
        bountyBoostUpgrade: 0.03,
      },
      {
        level: 2,
        cost: 81000,
        bountyBoostUpgrade: 0.03,
      },
      {
        level: 3,
        cost: 134000,
        bountyBoostUpgrade: 0.04,
      },
      {
        level: 4,
        cost: 193000,
        bountyBoostUpgrade: 0.05,
      },
      {
        level: 5,
        cost: 290000,
        bountyBoostUpgrade: 0.05,
      },
      {
        level: 6,
        cost: 465000,
        bountyBoostUpgrade: 0.06,
      },
      {
        level: 7,
        cost: 737000,
        bountyBoostUpgrade: 0.07,
      },
      {
        level: 8,
        cost: 1090000,
        bountyBoostUpgrade: 0.09,
      },
      {
        level: 9,
        cost: 1720000,
        bountyBoostUpgrade: 0.1,
      },
      {
        level: 10,
        cost: 2750000,
        bountyBoostUpgrade: 0.12,
      },
      {
        level: 11,
        cost: 4340000,
        bountyBoostUpgrade: 0.13,
      },
      {
        level: 12,
        cost: 6620000,
        bountyBoostUpgrade: 0.16,
      },
      {
        level: 13,
        cost: 10500000,
        bountyBoostUpgrade: 0.18,
      },
      {
        level: 14,
        cost: 16900000,
        bountyBoostUpgrade: 0.21,
      },
      {
        level: 15,
        cost: 28200000,
        bountyBoostUpgrade: 0.25,
      },
      {
        level: 16,
        cost: 43100000,
        bountyBoostUpgrade: 0.29,
      },
      {
        level: 17,
        cost: 64300000,
        bountyBoostUpgrade: 0.34,
      },
      {
        level: 18,
        cost: 103000000,
        bountyBoostUpgrade: 0.34,
      },
      {
        level: 19,
        cost: 167000000,
        bountyBoostUpgrade: 0.34,
      },
      {
        level: 20,
        cost: 250000000,
        bountyBoostUpgrade: 0.34,
      },
    ],
  },
  {
    name: "Executioner's Hood",
    itemId: "executioners_hood",
    category: "Gear",
    subcategory: "Basic",
    description:
      "A thick leather hood that obscures the face. Instills fear in enemies.",
    dependencies: [],
    upgrades: [
      {
        level: 1,
        cost: 65000,
        bountyBoostUpgrade: 0.01,
      },
      {
        level: 2,
        cost: 100000,
        bountyBoostUpgrade: 0.02,
      },
      {
        level: 3,
        cost: 168000,
        bountyBoostUpgrade: 0.02,
      },
      {
        level: 4,
        cost: 261000,
        bountyBoostUpgrade: 0.02,
      },
      {
        level: 5,
        cost: 472000,
        bountyBoostUpgrade: 0.03,
      },
      {
        level: 6,
        cost: 710000,
        bountyBoostUpgrade: 0.03,
      },
      {
        level: 7,
        cost: 1180000,
        bountyBoostUpgrade: 0.04,
      },
      {
        level: 8,
        cost: 2000000,
        bountyBoostUpgrade: 0.04,
      },
      {
        level: 9,
        cost: 3720000,
        bountyBoostUpgrade: 0.05,
      },
      {
        level: 10,
        cost: 6350000,
        bountyBoostUpgrade: 0.06,
      },
      {
        level: 11,
        cost: 10900000,
        bountyBoostUpgrade: 0.08,
      },
      {
        level: 12,
        cost: 18400000,
        bountyBoostUpgrade: 0.09,
      },
      {
        level: 13,
        cost: 32000000,
        bountyBoostUpgrade: 0.11,
      },
      {
        level: 14,
        cost: 52300000,
        bountyBoostUpgrade: 0.13,
      },
      {
        level: 15,
        cost: 94500000,
        bountyBoostUpgrade: 0.15,
      },
      {
        level: 16,
        cost: 150000000,
        bountyBoostUpgrade: 0.18,
      },
      {
        level: 17,
        cost: 247000000,
        bountyBoostUpgrade: 0.18,
      },
      {
        level: 18,
        cost: 408000000,
        bountyBoostUpgrade: 0.18,
      },
      {
        level: 19,
        cost: 654000000,
        bountyBoostUpgrade: 0.17,
      },
      {
        level: 20,
        cost: 1110000000,
        bountyBoostUpgrade: 0.17,
      },
    ],
  },
  {
    name: "Flayed Skin Vest",
    itemId: "flayed_skin_vest",
    category: "Gear",
    subcategory: "Basic",
    description:
      "A gruesome vest made from human skin. Offers minimal protection.",
    dependencies: [],
    upgrades: [
      {
        level: 1,
        cost: 87000,
        bountyBoostUpgrade: 0.02,
      },
      {
        level: 2,
        cost: 140000,
        bountyBoostUpgrade: 0.02,
      },
      {
        level: 3,
        cost: 260000,
        bountyBoostUpgrade: 0.03,
      },
      {
        level: 4,
        cost: 390000,
        bountyBoostUpgrade: 0.03,
      },
      {
        level: 5,
        cost: 616000,
        bountyBoostUpgrade: 0.04,
      },
      {
        level: 6,
        cost: 991000,
        bountyBoostUpgrade: 0.04,
      },
      {
        level: 7,
        cost: 1480000,
        bountyBoostUpgrade: 0.05,
      },
      {
        level: 8,
        cost: 2300000,
        bountyBoostUpgrade: 0.06,
      },
      {
        level: 9,
        cost: 3670000,
        bountyBoostUpgrade: 0.07,
      },
      {
        level: 10,
        cost: 6270000,
        bountyBoostUpgrade: 0.08,
      },
      {
        level: 11,
        cost: 10700000,
        bountyBoostUpgrade: 0.1,
      },
      {
        level: 12,
        cost: 17500000,
        bountyBoostUpgrade: 0.12,
      },
      {
        level: 13,
        cost: 28400000,
        bountyBoostUpgrade: 0.14,
      },
      {
        level: 14,
        cost: 47100000,
        bountyBoostUpgrade: 0.16,
      },
      {
        level: 15,
        cost: 71200000,
        bountyBoostUpgrade: 0.19,
      },
      {
        level: 16,
        cost: 118000000,
        bountyBoostUpgrade: 0.22,
      },
      {
        level: 17,
        cost: 207000000,
        bountyBoostUpgrade: 0.22,
      },
      {
        level: 18,
        cost: 319000000,
        bountyBoostUpgrade: 0.22,
      },
      {
        level: 19,
        cost: 557000000,
        bountyBoostUpgrade: 0.21,
      },
      {
        level: 20,
        cost: 946000000,
        bountyBoostUpgrade: 0.21,
      },
    ],
  },
  {
    name: "Sharpened Femur",
    itemId: "sharpened_femur",
    category: "Gear",
    subcategory: "Basic",
    description:
      "A human thighbone sharpened to a point. Crude but effective weapon.",
    dependencies: [],
    upgrades: [
      {
        level: 1,
        cost: 36000,
        bountyBoostUpgrade: 0.02,
      },
      {
        level: 2,
        cost: 53000,
        bountyBoostUpgrade: 0.02,
      },
      {
        level: 3,
        cost: 82000,
        bountyBoostUpgrade: 0.03,
      },
      {
        level: 4,
        cost: 122000,
        bountyBoostUpgrade: 0.03,
      },
      {
        level: 5,
        cost: 177000,
        bountyBoostUpgrade: 0.04,
      },
      {
        level: 6,
        cost: 311000,
        bountyBoostUpgrade: 0.04,
      },
      {
        level: 7,
        cost: 457000,
        bountyBoostUpgrade: 0.05,
      },
      {
        level: 8,
        cost: 724000,
        bountyBoostUpgrade: 0.06,
      },
      {
        level: 9,
        cost: 1220000,
        bountyBoostUpgrade: 0.07,
      },
      {
        level: 10,
        cost: 1990000,
        bountyBoostUpgrade: 0.09,
      },
      {
        level: 11,
        cost: 3320000,
        bountyBoostUpgrade: 0.1,
      },
      {
        level: 12,
        cost: 4980000,
        bountyBoostUpgrade: 0.13,
      },
      {
        level: 13,
        cost: 8130000,
        bountyBoostUpgrade: 0.15,
      },
      {
        level: 14,
        cost: 11900000,
        bountyBoostUpgrade: 0.18,
      },
      {
        level: 15,
        cost: 19200000,
        bountyBoostUpgrade: 0.21,
      },
      {
        level: 16,
        cost: 32500000,
        bountyBoostUpgrade: 0.25,
      },
      {
        level: 17,
        cost: 50100000,
        bountyBoostUpgrade: 0.3,
      },
      {
        level: 18,
        cost: 80200000,
        bountyBoostUpgrade: 0.3,
      },
      {
        level: 19,
        cost: 126000000,
        bountyBoostUpgrade: 0.31,
      },
      {
        level: 20,
        cost: 187000000,
        bountyBoostUpgrade: 0.3,
      },
    ],
  },
  {
    name: "Carrion Crow Cloak",
    itemId: "carrion_crow_cloak",
    category: "Gear",
    subcategory: "Basic",
    description: "A cloak made of black feathers. Attracts scavenging birds.",
    dependencies: [],
    upgrades: [
      {
        level: 1,
        cost: 53000,
        bountyBoostUpgrade: 0.01,
      },
      {
        level: 2,
        cost: 85000,
        bountyBoostUpgrade: 0.02,
      },
      {
        level: 3,
        cost: 122000,
        bountyBoostUpgrade: 0.02,
      },
      {
        level: 4,
        cost: 196000,
        bountyBoostUpgrade: 0.02,
      },
      {
        level: 5,
        cost: 317000,
        bountyBoostUpgrade: 0.03,
      },
      {
        level: 6,
        cost: 429000,
        bountyBoostUpgrade: 0.03,
      },
      {
        level: 7,
        cost: 632000,
        bountyBoostUpgrade: 0.04,
      },
      {
        level: 8,
        cost: 1010000,
        bountyBoostUpgrade: 0.04,
      },
      {
        level: 9,
        cost: 1550000,
        bountyBoostUpgrade: 0.05,
      },
      {
        level: 10,
        cost: 2130000,
        bountyBoostUpgrade: 0.06,
      },
      {
        level: 11,
        cost: 3260000,
        bountyBoostUpgrade: 0.07,
      },
      {
        level: 12,
        cost: 4860000,
        bountyBoostUpgrade: 0.08,
      },
      {
        level: 13,
        cost: 7050000,
        bountyBoostUpgrade: 0.1,
      },
      {
        level: 14,
        cost: 11100000,
        bountyBoostUpgrade: 0.11,
      },
      {
        level: 15,
        cost: 18100000,
        bountyBoostUpgrade: 0.13,
      },
      {
        level: 16,
        cost: 28300000,
        bountyBoostUpgrade: 0.16,
      },
      {
        level: 17,
        cost: 48100000,
        bountyBoostUpgrade: 0.19,
      },
      {
        level: 18,
        cost: 73100000,
        bountyBoostUpgrade: 0.19,
      },
      {
        level: 19,
        cost: 120000000,
        bountyBoostUpgrade: 0.18,
      },
      {
        level: 20,
        cost: 182000000,
        bountyBoostUpgrade: 0.18,
      },
    ],
  },
  {
    name: "Knuckle Dusters",
    itemId: "knuckle_dusters",
    category: "Gear",
    subcategory: "Basic",
    description:
      "Metal fist weapons adorned with sharp spikes. Amplifies unarmed damage.",
    dependencies: [],
    upgrades: [
      {
        level: 1,
        cost: 67000,
        bountyBoostUpgrade: 0.01,
      },
      {
        level: 2,
        cost: 96000,
        bountyBoostUpgrade: 0.01,
      },
      {
        level: 3,
        cost: 140000,
        bountyBoostUpgrade: 0.02,
      },
      {
        level: 4,
        cost: 227000,
        bountyBoostUpgrade: 0.02,
      },
      {
        level: 5,
        cost: 327000,
        bountyBoostUpgrade: 0.03,
      },
      {
        level: 6,
        cost: 495000,
        bountyBoostUpgrade: 0.03,
      },
      {
        level: 7,
        cost: 788000,
        bountyBoostUpgrade: 0.04,
      },
      {
        level: 8,
        cost: 1090000,
        bountyBoostUpgrade: 0.04,
      },
      {
        level: 9,
        cost: 1660000,
        bountyBoostUpgrade: 0.05,
      },
      {
        level: 10,
        cost: 2520000,
        bountyBoostUpgrade: 0.06,
      },
      {
        level: 11,
        cost: 3830000,
        bountyBoostUpgrade: 0.08,
      },
      {
        level: 12,
        cost: 5270000,
        bountyBoostUpgrade: 0.09,
      },
      {
        level: 13,
        cost: 7740000,
        bountyBoostUpgrade: 0.11,
      },
      {
        level: 14,
        cost: 11600000,
        bountyBoostUpgrade: 0.13,
      },
      {
        level: 15,
        cost: 16700000,
        bountyBoostUpgrade: 0.15,
      },
      {
        level: 16,
        cost: 26500000,
        bountyBoostUpgrade: 0.15,
      },
      {
        level: 17,
        cost: 38500000,
        bountyBoostUpgrade: 0.15,
      },
      {
        level: 18,
        cost: 57200000,
        bountyBoostUpgrade: 0.14,
      },
      {
        level: 19,
        cost: 82900000,
        bountyBoostUpgrade: 0.14,
      },
      {
        level: 20,
        cost: 125000000,
        bountyBoostUpgrade: 0.14,
      },
    ],
  },
  {
    name: "Ribcage Cuirass",
    itemId: "ribcage_cuirass",
    category: "Gear",
    subcategory: "Basic",
    description:
      "Chest armor fashioned from a giant's ribcage. Macabre but protective.",
    dependencies: [],
    upgrades: [
      {
        level: 1,
        cost: 44000,
        bountyBoostUpgrade: 0.05,
      },
      {
        level: 2,
        cost: 71000,
        bountyBoostUpgrade: 0.06,
      },
      {
        level: 3,
        cost: 107000,
        bountyBoostUpgrade: 0.07,
      },
      {
        level: 4,
        cost: 184000,
        bountyBoostUpgrade: 0.08,
      },
      {
        level: 5,
        cost: 321000,
        bountyBoostUpgrade: 0.09,
      },
      {
        level: 6,
        cost: 505000,
        bountyBoostUpgrade: 0.11,
      },
      {
        level: 7,
        cost: 855000,
        bountyBoostUpgrade: 0.13,
      },
      {
        level: 8,
        cost: 1280000,
        bountyBoostUpgrade: 0.15,
      },
      {
        level: 9,
        cost: 2000000,
        bountyBoostUpgrade: 0.18,
      },
      {
        level: 10,
        cost: 3150000,
        bountyBoostUpgrade: 0.21,
      },
      {
        level: 11,
        cost: 4620000,
        bountyBoostUpgrade: 0.25,
      },
      {
        level: 12,
        cost: 8010000,
        bountyBoostUpgrade: 0.29,
      },
      {
        level: 13,
        cost: 12300000,
        bountyBoostUpgrade: 0.34,
      },
      {
        level: 14,
        cost: 19600000,
        bountyBoostUpgrade: 0.41,
      },
      {
        level: 15,
        cost: 29700000,
        bountyBoostUpgrade: 0.48,
      },
      {
        level: 16,
        cost: 46500000,
        bountyBoostUpgrade: 0.57,
      },
      {
        level: 17,
        cost: 82500000,
        bountyBoostUpgrade: 0.66,
      },
      {
        level: 18,
        cost: 136000000,
        bountyBoostUpgrade: 0.76,
      },
      {
        level: 19,
        cost: 223000000,
        bountyBoostUpgrade: 0.76,
      },
      {
        level: 20,
        cost: 381000000,
        bountyBoostUpgrade: 0.74,
      },
    ],
  },
  {
    name: "Sinew Bow",
    itemId: "sinew_bow",
    category: "Gear",
    subcategory: "Basic",
    description:
      "A bow strung with human tendons. Powerful draw, unsettling to use.",
    dependencies: [],
    upgrades: [
      {
        level: 1,
        cost: 55000,
        bountyBoostUpgrade: 0.03,
      },
      {
        level: 2,
        cost: 85000,
        bountyBoostUpgrade: 0.03,
      },
      {
        level: 3,
        cost: 132000,
        bountyBoostUpgrade: 0.04,
      },
      {
        level: 4,
        cost: 222000,
        bountyBoostUpgrade: 0.04,
      },
      {
        level: 5,
        cost: 351000,
        bountyBoostUpgrade: 0.05,
      },
      {
        level: 6,
        cost: 510000,
        bountyBoostUpgrade: 0.06,
      },
      {
        level: 7,
        cost: 875000,
        bountyBoostUpgrade: 0.08,
      },
      {
        level: 8,
        cost: 1460000,
        bountyBoostUpgrade: 0.09,
      },
      {
        level: 9,
        cost: 2350000,
        bountyBoostUpgrade: 0.11,
      },
      {
        level: 10,
        cost: 3710000,
        bountyBoostUpgrade: 0.13,
      },
      {
        level: 11,
        cost: 6210000,
        bountyBoostUpgrade: 0.15,
      },
      {
        level: 12,
        cost: 9200000,
        bountyBoostUpgrade: 0.19,
      },
      {
        level: 13,
        cost: 15500000,
        bountyBoostUpgrade: 0.22,
      },
      {
        level: 14,
        cost: 24400000,
        bountyBoostUpgrade: 0.27,
      },
      {
        level: 15,
        cost: 38300000,
        bountyBoostUpgrade: 0.32,
      },
      {
        level: 16,
        cost: 60100000,
        bountyBoostUpgrade: 0.39,
      },
      {
        level: 17,
        cost: 85600000,
        bountyBoostUpgrade: 0.47,
      },
      {
        level: 18,
        cost: 126000000,
        bountyBoostUpgrade: 0.57,
      },
      {
        level: 19,
        cost: 184000000,
        bountyBoostUpgrade: 0.58,
      },
      {
        level: 20,
        cost: 287000000,
        bountyBoostUpgrade: 0.57,
      },
    ],
  },
  {
    name: "Widow Maker Crossbow",
    itemId: "widow_maker_crossbow",
    category: "Gear",
    subcategory: "Intermediate",
    description:
      "Heavy crossbow that fires barbed bolts. Causes grievous wounds.",
    dependencies: [
      {
        itemId: "sinew_bow",
        level: 7,
      },
    ],
    upgrades: [
      {
        level: 1,
        cost: 72000,
        bountyBoostUpgrade: 0.04,
      },
      {
        level: 2,
        cost: 115000,
        bountyBoostUpgrade: 0.05,
      },
      {
        level: 3,
        cost: 190000,
        bountyBoostUpgrade: 0.07,
      },
      {
        level: 4,
        cost: 305000,
        bountyBoostUpgrade: 0.08,
      },
      {
        level: 5,
        cost: 484000,
        bountyBoostUpgrade: 0.1,
      },
      {
        level: 6,
        cost: 697000,
        bountyBoostUpgrade: 0.12,
      },
      {
        level: 7,
        cost: 1000000,
        bountyBoostUpgrade: 0.14,
      },
      {
        level: 8,
        cost: 1660000,
        bountyBoostUpgrade: 0.17,
      },
      {
        level: 9,
        cost: 2630000,
        bountyBoostUpgrade: 0.21,
      },
      {
        level: 10,
        cost: 4540000,
        bountyBoostUpgrade: 0.26,
      },
      {
        level: 11,
        cost: 7090000,
        bountyBoostUpgrade: 0.32,
      },
      {
        level: 12,
        cost: 10800000,
        bountyBoostUpgrade: 0.39,
      },
      {
        level: 13,
        cost: 16500000,
        bountyBoostUpgrade: 0.47,
      },
      {
        level: 14,
        cost: 26400000,
        bountyBoostUpgrade: 0.57,
      },
      {
        level: 15,
        cost: 41100000,
        bountyBoostUpgrade: 0.71,
      },
      {
        level: 16,
        cost: 62500000,
        bountyBoostUpgrade: 0.7,
      },
      {
        level: 17,
        cost: 91100000,
        bountyBoostUpgrade: 0.7,
      },
      {
        level: 18,
        cost: 136000000,
        bountyBoostUpgrade: 0.67,
      },
      {
        level: 19,
        cost: 219000000,
        bountyBoostUpgrade: 0.67,
      },
      {
        level: 20,
        cost: 320000000,
        bountyBoostUpgrade: 0.64,
      },
    ],
  },
  {
    name: "Fleshweave Armor",
    itemId: "fleshweave_armor",
    category: "Gear",
    subcategory: "Intermediate",
    description:
      "Living armor that grows into the wearer's skin. Increases pain tolerance.",
    dependencies: [
      {
        itemId: "flayed_skin_vest",
        level: 8,
      },
    ],
    upgrades: [
      {
        level: 1,
        cost: 50000,
        bountyBoostUpgrade: 0.08,
      },
      {
        level: 2,
        cost: 86000,
        bountyBoostUpgrade: 0.1,
      },
      {
        level: 3,
        cost: 139000,
        bountyBoostUpgrade: 0.12,
      },
      {
        level: 4,
        cost: 193000,
        bountyBoostUpgrade: 0.16,
      },
      {
        level: 5,
        cost: 296000,
        bountyBoostUpgrade: 0.2,
      },
      {
        level: 6,
        cost: 460000,
        bountyBoostUpgrade: 0.25,
      },
      {
        level: 7,
        cost: 708000,
        bountyBoostUpgrade: 0.32,
      },
      {
        level: 8,
        cost: 1130000,
        bountyBoostUpgrade: 0.4,
      },
      {
        level: 9,
        cost: 1690000,
        bountyBoostUpgrade: 0.5,
      },
      {
        level: 10,
        cost: 2830000,
        bountyBoostUpgrade: 0.65,
      },
      {
        level: 11,
        cost: 4570000,
        bountyBoostUpgrade: 0.83,
      },
      {
        level: 12,
        cost: 7200000,
        bountyBoostUpgrade: 1.05,
      },
      {
        level: 13,
        cost: 10400000,
        bountyBoostUpgrade: 1.36,
      },
      {
        level: 14,
        cost: 16400000,
        bountyBoostUpgrade: 1.73,
      },
      {
        level: 15,
        cost: 23500000,
        bountyBoostUpgrade: 2.17,
      },
      {
        level: 16,
        cost: 37200000,
        bountyBoostUpgrade: 2.74,
      },
      {
        level: 17,
        cost: 59300000,
        bountyBoostUpgrade: 3.46,
      },
      {
        level: 18,
        cost: 91800000,
        bountyBoostUpgrade: 4.4,
      },
      {
        level: 19,
        cost: 143000000,
        bountyBoostUpgrade: 5,
      },
      {
        level: 20,
        cost: 206000000,
        bountyBoostUpgrade: 5,
      },
    ],
  },
  {
    name: "Morningstar of Agony",
    itemId: "morningstar_of_agony",
    category: "Gear",
    subcategory: "Intermediate",
    description: "A spiked mace that inflicts lasting, painful injuries.",
    dependencies: [
      {
        itemId: "bloodied_cleaver",
        level: 10,
      },
    ],
    upgrades: [
      {
        level: 1,
        cost: 88000,
        bountyBoostUpgrade: 0.05,
      },
      {
        level: 2,
        cost: 149000,
        bountyBoostUpgrade: 0.06,
      },
      {
        level: 3,
        cost: 258000,
        bountyBoostUpgrade: 0.07,
      },
      {
        level: 4,
        cost: 406000,
        bountyBoostUpgrade: 0.08,
      },
      {
        level: 5,
        cost: 630000,
        bountyBoostUpgrade: 0.1,
      },
      {
        level: 6,
        cost: 1010000,
        bountyBoostUpgrade: 0.12,
      },
      {
        level: 7,
        cost: 1650000,
        bountyBoostUpgrade: 0.14,
      },
      {
        level: 8,
        cost: 2850000,
        bountyBoostUpgrade: 0.17,
      },
      {
        level: 9,
        cost: 4190000,
        bountyBoostUpgrade: 0.2,
      },
      {
        level: 10,
        cost: 6870000,
        bountyBoostUpgrade: 0.25,
      },
      {
        level: 11,
        cost: 10400000,
        bountyBoostUpgrade: 0.3,
      },
      {
        level: 12,
        cost: 15700000,
        bountyBoostUpgrade: 0.36,
      },
      {
        level: 13,
        cost: 24500000,
        bountyBoostUpgrade: 0.44,
      },
      {
        level: 14,
        cost: 40800000,
        bountyBoostUpgrade: 0.53,
      },
      {
        level: 15,
        cost: 68800000,
        bountyBoostUpgrade: 0.63,
      },
      {
        level: 16,
        cost: 100000000,
        bountyBoostUpgrade: 0.63,
      },
      {
        level: 17,
        cost: 150000000,
        bountyBoostUpgrade: 0.62,
      },
      {
        level: 18,
        cost: 233000000,
        bountyBoostUpgrade: 0.61,
      },
      {
        level: 19,
        cost: 358000000,
        bountyBoostUpgrade: 0.59,
      },
      {
        level: 20,
        cost: 570000000,
        bountyBoostUpgrade: 0.57,
      },
    ],
  },
  {
    name: "Hellforged Axe",
    itemId: "hellforged_axe",
    category: "Gear",
    subcategory: "Intermediate",
    description:
      "An axe that burns flesh on contact. Leaves cauterized wounds.",
    dependencies: [
      {
        itemId: "sharpened_femur",
        level: 9,
      },
    ],
    upgrades: [
      {
        level: 1,
        cost: 62000,
        bountyBoostUpgrade: 0.06,
      },
      {
        level: 2,
        cost: 86000,
        bountyBoostUpgrade: 0.07,
      },
      {
        level: 3,
        cost: 127000,
        bountyBoostUpgrade: 0.08,
      },
      {
        level: 4,
        cost: 176000,
        bountyBoostUpgrade: 0.1,
      },
      {
        level: 5,
        cost: 244000,
        bountyBoostUpgrade: 0.11,
      },
      {
        level: 6,
        cost: 366000,
        bountyBoostUpgrade: 0.14,
      },
      {
        level: 7,
        cost: 588000,
        bountyBoostUpgrade: 0.17,
      },
      {
        level: 8,
        cost: 937000,
        bountyBoostUpgrade: 0.2,
      },
      {
        level: 9,
        cost: 1330000,
        bountyBoostUpgrade: 0.25,
      },
      {
        level: 10,
        cost: 2110000,
        bountyBoostUpgrade: 0.3,
      },
      {
        level: 11,
        cost: 2930000,
        bountyBoostUpgrade: 0.36,
      },
      {
        level: 12,
        cost: 4090000,
        bountyBoostUpgrade: 0.43,
      },
      {
        level: 13,
        cost: 5560000,
        bountyBoostUpgrade: 0.51,
      },
      {
        level: 14,
        cost: 8140000,
        bountyBoostUpgrade: 0.6,
      },
      {
        level: 15,
        cost: 11700000,
        bountyBoostUpgrade: 0.72,
      },
      {
        level: 16,
        cost: 16800000,
        bountyBoostUpgrade: 0.85,
      },
      {
        level: 17,
        cost: 24700000,
        bountyBoostUpgrade: 1.03,
      },
      {
        level: 18,
        cost: 39100000,
        bountyBoostUpgrade: 1.22,
      },
      {
        level: 19,
        cost: 52400000,
        bountyBoostUpgrade: 1.21,
      },
      {
        level: 20,
        cost: 77300000,
        bountyBoostUpgrade: 1.19,
      },
    ],
  },
  {
    name: "Pendant of Suffering",
    itemId: "pendant_of_suffering",
    category: "Gear",
    subcategory: "Intermediate",
    description: "Amplifies the wearer's pain tolerance and bloodlust.",
    dependencies: [
      {
        itemId: "executioners_hood",
        level: 6,
      },
      {
        itemId: "carrion_crow_cloak",
        level: 6,
      },
    ],
    upgrades: [
      {
        level: 1,
        cost: 86000,
        bountyBoostUpgrade: 0.06,
      },
      {
        level: 2,
        cost: 124000,
        bountyBoostUpgrade: 0.07,
      },
      {
        level: 3,
        cost: 193000,
        bountyBoostUpgrade: 0.09,
      },
      {
        level: 4,
        cost: 290000,
        bountyBoostUpgrade: 0.11,
      },
      {
        level: 5,
        cost: 451000,
        bountyBoostUpgrade: 0.13,
      },
      {
        level: 6,
        cost: 660000,
        bountyBoostUpgrade: 0.16,
      },
      {
        level: 7,
        cost: 1050000,
        bountyBoostUpgrade: 0.2,
      },
      {
        level: 8,
        cost: 1680000,
        bountyBoostUpgrade: 0.24,
      },
      {
        level: 9,
        cost: 2540000,
        bountyBoostUpgrade: 0.3,
      },
      {
        level: 10,
        cost: 3860000,
        bountyBoostUpgrade: 0.37,
      },
      {
        level: 11,
        cost: 5790000,
        bountyBoostUpgrade: 0.46,
      },
      {
        level: 12,
        cost: 7930000,
        bountyBoostUpgrade: 0.57,
      },
      {
        level: 13,
        cost: 12500000,
        bountyBoostUpgrade: 0.71,
      },
      {
        level: 14,
        cost: 17600000,
        bountyBoostUpgrade: 0.87,
      },
      {
        level: 15,
        cost: 23900000,
        bountyBoostUpgrade: 1.06,
      },
      {
        level: 16,
        cost: 39300000,
        bountyBoostUpgrade: 1.27,
      },
      {
        level: 17,
        cost: 54700000,
        bountyBoostUpgrade: 1.59,
      },
      {
        level: 18,
        cost: 78000000,
        bountyBoostUpgrade: 1.58,
      },
      {
        level: 19,
        cost: 122000000,
        bountyBoostUpgrade: 1.6,
      },
      {
        level: 20,
        cost: 181000000,
        bountyBoostUpgrade: 1.55,
      },
    ],
  },
  {
    name: "Heartpiercer Longbow",
    itemId: "heartpiercer_longbow",
    category: "Gear",
    subcategory: "Intermediate",
    description:
      "Arrows fired seek out vital organs. Causes internal bleeding.",
    dependencies: [
      {
        itemId: "sinew_bow",
        level: 12,
      },
    ],
    upgrades: [
      {
        level: 1,
        cost: 63000,
        bountyBoostUpgrade: 0.04,
      },
      {
        level: 2,
        cost: 94000,
        bountyBoostUpgrade: 0.05,
      },
      {
        level: 3,
        cost: 145000,
        bountyBoostUpgrade: 0.06,
      },
      {
        level: 4,
        cost: 225000,
        bountyBoostUpgrade: 0.07,
      },
      {
        level: 5,
        cost: 319000,
        bountyBoostUpgrade: 0.09,
      },
      {
        level: 6,
        cost: 505000,
        bountyBoostUpgrade: 0.11,
      },
      {
        level: 7,
        cost: 728000,
        bountyBoostUpgrade: 0.14,
      },
      {
        level: 8,
        cost: 1130000,
        bountyBoostUpgrade: 0.18,
      },
      {
        level: 9,
        cost: 1810000,
        bountyBoostUpgrade: 0.23,
      },
      {
        level: 10,
        cost: 2660000,
        bountyBoostUpgrade: 0.29,
      },
      {
        level: 11,
        cost: 3890000,
        bountyBoostUpgrade: 0.36,
      },
      {
        level: 12,
        cost: 5670000,
        bountyBoostUpgrade: 0.46,
      },
      {
        level: 13,
        cost: 9630000,
        bountyBoostUpgrade: 0.59,
      },
      {
        level: 14,
        cost: 14800000,
        bountyBoostUpgrade: 0.74,
      },
      {
        level: 15,
        cost: 22000000,
        bountyBoostUpgrade: 0.92,
      },
      {
        level: 16,
        cost: 34600000,
        bountyBoostUpgrade: 0.93,
      },
      {
        level: 17,
        cost: 57300000,
        bountyBoostUpgrade: 0.91,
      },
      {
        level: 18,
        cost: 86700000,
        bountyBoostUpgrade: 0.88,
      },
      {
        level: 19,
        cost: 122000000,
        bountyBoostUpgrade: 0.86,
      },
      {
        level: 20,
        cost: 180000000,
        bountyBoostUpgrade: 0.84,
      },
    ],
  },
  {
    name: "Berserker's Twin Axes",
    itemId: "berserkers_twin_axes",
    category: "Gear",
    subcategory: "Intermediate",
    description: "Dual-wielded axes that feed on the wielder's rage.",
    dependencies: [
      {
        itemId: "bloodied_cleaver",
        level: 8,
      },
      {
        itemId: "sharpened_femur",
        level: 8,
      },
    ],
    upgrades: [
      {
        level: 1,
        cost: 63000,
        bountyBoostUpgrade: 0.08,
      },
      {
        level: 2,
        cost: 99000,
        bountyBoostUpgrade: 0.11,
      },
      {
        level: 3,
        cost: 160000,
        bountyBoostUpgrade: 0.14,
      },
      {
        level: 4,
        cost: 226000,
        bountyBoostUpgrade: 0.17,
      },
      {
        level: 5,
        cost: 367000,
        bountyBoostUpgrade: 0.22,
      },
      {
        level: 6,
        cost: 584000,
        bountyBoostUpgrade: 0.28,
      },
      {
        level: 7,
        cost: 871000,
        bountyBoostUpgrade: 0.36,
      },
      {
        level: 8,
        cost: 1410000,
        bountyBoostUpgrade: 0.45,
      },
      {
        level: 9,
        cost: 2120000,
        bountyBoostUpgrade: 0.57,
      },
      {
        level: 10,
        cost: 3190000,
        bountyBoostUpgrade: 0.72,
      },
      {
        level: 11,
        cost: 5400000,
        bountyBoostUpgrade: 0.94,
      },
      {
        level: 12,
        cost: 8160000,
        bountyBoostUpgrade: 1.2,
      },
      {
        level: 13,
        cost: 13000000,
        bountyBoostUpgrade: 1.54,
      },
      {
        level: 14,
        cost: 20500000,
        bountyBoostUpgrade: 1.93,
      },
      {
        level: 15,
        cost: 30200000,
        bountyBoostUpgrade: 2.51,
      },
      {
        level: 16,
        cost: 48600000,
        bountyBoostUpgrade: 3.25,
      },
      {
        level: 17,
        cost: 81500000,
        bountyBoostUpgrade: 4.18,
      },
      {
        level: 18,
        cost: 122000000,
        bountyBoostUpgrade: 5,
      },
      {
        level: 19,
        cost: 184000000,
        bountyBoostUpgrade: 5,
      },
      {
        level: 20,
        cost: 288000000,
        bountyBoostUpgrade: 5,
      },
    ],
  },
  {
    name: "Gauntlets of Flesh Rending",
    itemId: "gauntlets_of_flesh_rending",
    category: "Gear",
    subcategory: "Intermediate",
    description: "Clawed gauntlets that tear through armor and flesh alike.",
    dependencies: [
      {
        itemId: "knuckle_dusters",
        level: 11,
      },
    ],
    upgrades: [
      {
        level: 1,
        cost: 90000,
        bountyBoostUpgrade: 0.09,
      },
      {
        level: 2,
        cost: 147000,
        bountyBoostUpgrade: 0.1,
      },
      {
        level: 3,
        cost: 242000,
        bountyBoostUpgrade: 0.13,
      },
      {
        level: 4,
        cost: 376000,
        bountyBoostUpgrade: 0.16,
      },
      {
        level: 5,
        cost: 572000,
        bountyBoostUpgrade: 0.2,
      },
      {
        level: 6,
        cost: 822000,
        bountyBoostUpgrade: 0.25,
      },
      {
        level: 7,
        cost: 1370000,
        bountyBoostUpgrade: 0.31,
      },
      {
        level: 8,
        cost: 2050000,
        bountyBoostUpgrade: 0.39,
      },
      {
        level: 9,
        cost: 3020000,
        bountyBoostUpgrade: 0.49,
      },
      {
        level: 10,
        cost: 4740000,
        bountyBoostUpgrade: 0.62,
      },
      {
        level: 11,
        cost: 7780000,
        bountyBoostUpgrade: 0.77,
      },
      {
        level: 12,
        cost: 12600000,
        bountyBoostUpgrade: 0.95,
      },
      {
        level: 13,
        cost: 19200000,
        bountyBoostUpgrade: 1.21,
      },
      {
        level: 14,
        cost: 31200000,
        bountyBoostUpgrade: 1.49,
      },
      {
        level: 15,
        cost: 45200000,
        bountyBoostUpgrade: 1.87,
      },
      {
        level: 16,
        cost: 68100000,
        bountyBoostUpgrade: 2.29,
      },
      {
        level: 17,
        cost: 101000000,
        bountyBoostUpgrade: 2.82,
      },
      {
        level: 18,
        cost: 160000000,
        bountyBoostUpgrade: 3.53,
      },
      {
        level: 19,
        cost: 265000000,
        bountyBoostUpgrade: 3.54,
      },
      {
        level: 20,
        cost: 394000000,
        bountyBoostUpgrade: 3.52,
      },
    ],
  },
  {
    name: "Aegis of the Tyrant",
    itemId: "aegis_of_the_tyrant",
    category: "Gear",
    subcategory: "Intermediate",
    description: "A shield that grows stronger with each life it takes.",
    dependencies: [
      {
        itemId: "boneforged_shield",
        level: 10,
      },
    ],
    upgrades: [
      {
        level: 1,
        cost: 82000,
        bountyBoostUpgrade: 0.03,
      },
      {
        level: 2,
        cost: 123000,
        bountyBoostUpgrade: 0.04,
      },
      {
        level: 3,
        cost: 193000,
        bountyBoostUpgrade: 0.05,
      },
      {
        level: 4,
        cost: 321000,
        bountyBoostUpgrade: 0.06,
      },
      {
        level: 5,
        cost: 491000,
        bountyBoostUpgrade: 0.07,
      },
      {
        level: 6,
        cost: 699000,
        bountyBoostUpgrade: 0.09,
      },
      {
        level: 7,
        cost: 1030000,
        bountyBoostUpgrade: 0.11,
      },
      {
        level: 8,
        cost: 1680000,
        bountyBoostUpgrade: 0.14,
      },
      {
        level: 9,
        cost: 2600000,
        bountyBoostUpgrade: 0.17,
      },
      {
        level: 10,
        cost: 3930000,
        bountyBoostUpgrade: 0.2,
      },
      {
        level: 11,
        cost: 5710000,
        bountyBoostUpgrade: 0.25,
      },
      {
        level: 12,
        cost: 9030000,
        bountyBoostUpgrade: 0.3,
      },
      {
        level: 13,
        cost: 14700000,
        bountyBoostUpgrade: 0.38,
      },
      {
        level: 14,
        cost: 21700000,
        bountyBoostUpgrade: 0.47,
      },
      {
        level: 15,
        cost: 31200000,
        bountyBoostUpgrade: 0.58,
      },
      {
        level: 16,
        cost: 48800000,
        bountyBoostUpgrade: 0.71,
      },
      {
        level: 17,
        cost: 73100000,
        bountyBoostUpgrade: 0.86,
      },
      {
        level: 18,
        cost: 117000000,
        bountyBoostUpgrade: 0.86,
      },
      {
        level: 19,
        cost: 161000000,
        bountyBoostUpgrade: 0.86,
      },
      {
        level: 20,
        cost: 247000000,
        bountyBoostUpgrade: 0.85,
      },
    ],
  },
  {
    name: "Shroud of the Damned",
    itemId: "shroud_of_the_damned",
    category: "Gear",
    subcategory: "Intermediate",
    description:
      "A cloak woven from the souls of the defeated. Drains life essence.",
    dependencies: [
      {
        itemId: "carrion_crow_cloak",
        level: 9,
      },
    ],
    upgrades: [
      {
        level: 1,
        cost: 90000,
        bountyBoostUpgrade: 0.07,
      },
      {
        level: 2,
        cost: 137000,
        bountyBoostUpgrade: 0.09,
      },
      {
        level: 3,
        cost: 192000,
        bountyBoostUpgrade: 0.11,
      },
      {
        level: 4,
        cost: 288000,
        bountyBoostUpgrade: 0.15,
      },
      {
        level: 5,
        cost: 397000,
        bountyBoostUpgrade: 0.19,
      },
      {
        level: 6,
        cost: 602000,
        bountyBoostUpgrade: 0.24,
      },
      {
        level: 7,
        cost: 857000,
        bountyBoostUpgrade: 0.29,
      },
      {
        level: 8,
        cost: 1160000,
        bountyBoostUpgrade: 0.38,
      },
      {
        level: 9,
        cost: 1630000,
        bountyBoostUpgrade: 0.48,
      },
      {
        level: 10,
        cost: 2450000,
        bountyBoostUpgrade: 0.61,
      },
      {
        level: 11,
        cost: 3650000,
        bountyBoostUpgrade: 0.77,
      },
      {
        level: 12,
        cost: 5110000,
        bountyBoostUpgrade: 1,
      },
      {
        level: 13,
        cost: 7010000,
        bountyBoostUpgrade: 1.28,
      },
      {
        level: 14,
        cost: 10900000,
        bountyBoostUpgrade: 1.65,
      },
      {
        level: 15,
        cost: 17300000,
        bountyBoostUpgrade: 2.08,
      },
      {
        level: 16,
        cost: 25200000,
        bountyBoostUpgrade: 2.12,
      },
      {
        level: 17,
        cost: 40900000,
        bountyBoostUpgrade: 2.1,
      },
      {
        level: 18,
        cost: 66000000,
        bountyBoostUpgrade: 2.03,
      },
      {
        level: 19,
        cost: 98600000,
        bountyBoostUpgrade: 2.02,
      },
      {
        level: 20,
        cost: 156000000,
        bountyBoostUpgrade: 1.98,
      },
    ],
  },
  {
    name: "Armor of the Undying",
    itemId: "armor_of_the_undying",
    category: "Gear",
    subcategory: "Advanced",
    description:
      "Plating forged from immortal flesh. Regenerates damage over time.",
    dependencies: [
      {
        itemId: "fleshweave_armor",
        level: 15,
      },
    ],
    upgrades: [
      {
        level: 1,
        cost: 99000,
        bountyBoostUpgrade: 0.15,
      },
      {
        level: 2,
        cost: 129000,
        bountyBoostUpgrade: 0.18,
      },
      {
        level: 3,
        cost: 182000,
        bountyBoostUpgrade: 0.23,
      },
      {
        level: 4,
        cost: 246000,
        bountyBoostUpgrade: 0.29,
      },
      {
        level: 5,
        cost: 333000,
        bountyBoostUpgrade: 0.35,
      },
      {
        level: 6,
        cost: 441000,
        bountyBoostUpgrade: 0.44,
      },
      {
        level: 7,
        cost: 596000,
        bountyBoostUpgrade: 0.55,
      },
      {
        level: 8,
        cost: 792000,
        bountyBoostUpgrade: 0.67,
      },
      {
        level: 9,
        cost: 1140000,
        bountyBoostUpgrade: 0.83,
      },
      {
        level: 10,
        cost: 1570000,
        bountyBoostUpgrade: 1.04,
      },
      {
        level: 11,
        cost: 2170000,
        bountyBoostUpgrade: 1.27,
      },
      {
        level: 12,
        cost: 3020000,
        bountyBoostUpgrade: 1.6,
      },
      {
        level: 13,
        cost: 4170000,
        bountyBoostUpgrade: 2.02,
      },
      {
        level: 14,
        cost: 5940000,
        bountyBoostUpgrade: 2.54,
      },
      {
        level: 15,
        cost: 7580000,
        bountyBoostUpgrade: 3.15,
      },
      {
        level: 16,
        cost: 9390000,
        bountyBoostUpgrade: 3.85,
      },
      {
        level: 17,
        cost: 12400000,
        bountyBoostUpgrade: 4.76,
      },
      {
        level: 18,
        cost: 16700000,
        bountyBoostUpgrade: 5,
      },
      {
        level: 19,
        cost: 23500000,
        bountyBoostUpgrade: 5,
      },
      {
        level: 20,
        cost: 30200000,
        bountyBoostUpgrade: 5,
      },
    ],
  },
  {
    name: "Soulreaver",
    itemId: "soulreaver",
    category: "Gear",
    subcategory: "Advanced",
    description:
      "A sword that traps the souls of those it kills, growing in power.",
    dependencies: [
      {
        itemId: "morningstar_of_agony",
        level: 12,
      },
      {
        itemId: "hellforged_axe",
        level: 12,
      },
    ],
    upgrades: [
      {
        level: 1,
        cost: 144000,
        bountyBoostUpgrade: 0.07,
      },
      {
        level: 2,
        cost: 190000,
        bountyBoostUpgrade: 0.09,
      },
      {
        level: 3,
        cost: 258000,
        bountyBoostUpgrade: 0.11,
      },
      {
        level: 4,
        cost: 362000,
        bountyBoostUpgrade: 0.15,
      },
      {
        level: 5,
        cost: 489000,
        bountyBoostUpgrade: 0.18,
      },
      {
        level: 6,
        cost: 668000,
        bountyBoostUpgrade: 0.24,
      },
      {
        level: 7,
        cost: 904000,
        bountyBoostUpgrade: 0.3,
      },
      {
        level: 8,
        cost: 1340000,
        bountyBoostUpgrade: 0.39,
      },
      {
        level: 9,
        cost: 1730000,
        bountyBoostUpgrade: 0.51,
      },
      {
        level: 10,
        cost: 2150000,
        bountyBoostUpgrade: 0.65,
      },
      {
        level: 11,
        cost: 2720000,
        bountyBoostUpgrade: 0.84,
      },
      {
        level: 12,
        cost: 4030000,
        bountyBoostUpgrade: 1.06,
      },
      {
        level: 13,
        cost: 5530000,
        bountyBoostUpgrade: 1.35,
      },
      {
        level: 14,
        cost: 7380000,
        bountyBoostUpgrade: 1.73,
      },
      {
        level: 15,
        cost: 10400000,
        bountyBoostUpgrade: 2.17,
      },
      {
        level: 16,
        cost: 13400000,
        bountyBoostUpgrade: 2.84,
      },
      {
        level: 17,
        cost: 17300000,
        bountyBoostUpgrade: 3.61,
      },
      {
        level: 18,
        cost: 21700000,
        bountyBoostUpgrade: 4.57,
      },
      {
        level: 19,
        cost: 29800000,
        bountyBoostUpgrade: 5,
      },
      {
        level: 20,
        cost: 44000000,
        bountyBoostUpgrade: 5,
      },
    ],
  },
  {
    name: "Boots of the Conqueror",
    itemId: "boots_of_the_conqueror",
    category: "Gear",
    subcategory: "Advanced",
    description: "Footwear that leaves scorched earth in its wake.",
    dependencies: [
      {
        itemId: "spiked_boots",
        level: 14,
      },
    ],
    upgrades: [
      {
        level: 1,
        cost: 126000,
        bountyBoostUpgrade: 0.19,
      },
      {
        level: 2,
        cost: 165000,
        bountyBoostUpgrade: 0.25,
      },
      {
        level: 3,
        cost: 223000,
        bountyBoostUpgrade: 0.32,
      },
      {
        level: 4,
        cost: 309000,
        bountyBoostUpgrade: 0.42,
      },
      {
        level: 5,
        cost: 388000,
        bountyBoostUpgrade: 0.55,
      },
      {
        level: 6,
        cost: 549000,
        bountyBoostUpgrade: 0.7,
      },
      {
        level: 7,
        cost: 688000,
        bountyBoostUpgrade: 0.89,
      },
      {
        level: 8,
        cost: 927000,
        bountyBoostUpgrade: 1.13,
      },
      {
        level: 9,
        cost: 1300000,
        bountyBoostUpgrade: 1.45,
      },
      {
        level: 10,
        cost: 1620000,
        bountyBoostUpgrade: 1.89,
      },
      {
        level: 11,
        cost: 2060000,
        bountyBoostUpgrade: 2.41,
      },
      {
        level: 12,
        cost: 2570000,
        bountyBoostUpgrade: 3.17,
      },
      {
        level: 13,
        cost: 3230000,
        bountyBoostUpgrade: 4.04,
      },
      {
        level: 14,
        cost: 3990000,
        bountyBoostUpgrade: 5,
      },
      {
        level: 15,
        cost: 5460000,
        bountyBoostUpgrade: 5,
      },
      {
        level: 16,
        cost: 6860000,
        bountyBoostUpgrade: 5,
      },
      {
        level: 17,
        cost: 9040000,
        bountyBoostUpgrade: 5,
      },
      {
        level: 18,
        cost: 10600000,
        bountyBoostUpgrade: 5,
      },
      {
        level: 19,
        cost: 14000000,
        bountyBoostUpgrade: 5,
      },
      {
        level: 20,
        cost: 18000000,
        bountyBoostUpgrade: 5,
      },
    ],
  },
  {
    name: "Crown of Subjugation",
    itemId: "crown_of_subjugation",
    category: "Gear",
    subcategory: "Advanced",
    description: "Helmet that breaks the will of weaker minds nearby.",
    dependencies: [
      {
        itemId: "executioners_hood",
        level: 13,
      },
    ],
    upgrades: [
      {
        level: 1,
        cost: 102000,
        bountyBoostUpgrade: 0.2,
      },
      {
        level: 2,
        cost: 138000,
        bountyBoostUpgrade: 0.26,
      },
      {
        level: 3,
        cost: 193000,
        bountyBoostUpgrade: 0.34,
      },
      {
        level: 4,
        cost: 265000,
        bountyBoostUpgrade: 0.45,
      },
      {
        level: 5,
        cost: 353000,
        bountyBoostUpgrade: 0.59,
      },
      {
        level: 6,
        cost: 444000,
        bountyBoostUpgrade: 0.77,
      },
      {
        level: 7,
        cost: 651000,
        bountyBoostUpgrade: 1,
      },
      {
        level: 8,
        cost: 871000,
        bountyBoostUpgrade: 1.3,
      },
      {
        level: 9,
        cost: 1150000,
        bountyBoostUpgrade: 1.68,
      },
      {
        level: 10,
        cost: 1590000,
        bountyBoostUpgrade: 2.21,
      },
      {
        level: 11,
        cost: 2290000,
        bountyBoostUpgrade: 2.98,
      },
      {
        level: 12,
        cost: 2960000,
        bountyBoostUpgrade: 4,
      },
      {
        level: 13,
        cost: 4410000,
        bountyBoostUpgrade: 5,
      },
      {
        level: 14,
        cost: 6250000,
        bountyBoostUpgrade: 5,
      },
      {
        level: 15,
        cost: 8430000,
        bountyBoostUpgrade: 5,
      },
      {
        level: 16,
        cost: 11200000,
        bountyBoostUpgrade: 5,
      },
      {
        level: 17,
        cost: 16400000,
        bountyBoostUpgrade: 5,
      },
      {
        level: 18,
        cost: 23400000,
        bountyBoostUpgrade: 5,
      },
      {
        level: 19,
        cost: 30500000,
        bountyBoostUpgrade: 5,
      },
      {
        level: 20,
        cost: 37700000,
        bountyBoostUpgrade: 5,
      },
    ],
  },
  {
    name: "Fists of the Colossus",
    itemId: "fists_of_the_colossus",
    category: "Gear",
    subcategory: "Advanced",
    description: "Massive gauntlets that can pulverize stone and bone alike.",
    dependencies: [
      {
        itemId: "gauntlets_of_flesh_rending",
        level: 14,
      },
    ],
    upgrades: [
      {
        level: 1,
        cost: 138000,
        bountyBoostUpgrade: 0.16,
      },
      {
        level: 2,
        cost: 190000,
        bountyBoostUpgrade: 0.21,
      },
      {
        level: 3,
        cost: 241000,
        bountyBoostUpgrade: 0.26,
      },
      {
        level: 4,
        cost: 302000,
        bountyBoostUpgrade: 0.34,
      },
      {
        level: 5,
        cost: 399000,
        bountyBoostUpgrade: 0.45,
      },
      {
        level: 6,
        cost: 531000,
        bountyBoostUpgrade: 0.59,
      },
      {
        level: 7,
        cost: 638000,
        bountyBoostUpgrade: 0.75,
      },
      {
        level: 8,
        cost: 874000,
        bountyBoostUpgrade: 0.97,
      },
      {
        level: 9,
        cost: 1050000,
        bountyBoostUpgrade: 1.25,
      },
      {
        level: 10,
        cost: 1320000,
        bountyBoostUpgrade: 1.62,
      },
      {
        level: 11,
        cost: 1700000,
        bountyBoostUpgrade: 2.06,
      },
      {
        level: 12,
        cost: 2340000,
        bountyBoostUpgrade: 2.68,
      },
      {
        level: 13,
        cost: 3120000,
        bountyBoostUpgrade: 3.46,
      },
      {
        level: 14,
        cost: 4160000,
        bountyBoostUpgrade: 4.48,
      },
      {
        level: 15,
        cost: 5440000,
        bountyBoostUpgrade: 5,
      },
      {
        level: 16,
        cost: 6380000,
        bountyBoostUpgrade: 5,
      },
      {
        level: 17,
        cost: 8970000,
        bountyBoostUpgrade: 5,
      },
      {
        level: 18,
        cost: 12100000,
        bountyBoostUpgrade: 5,
      },
      {
        level: 19,
        cost: 17200000,
        bountyBoostUpgrade: 5,
      },
      {
        level: 20,
        cost: 23200000,
        bountyBoostUpgrade: 5,
      },
    ],
  },
  {
    name: "Cloak of Flayed Shadows",
    itemId: "cloak_of_flayed_shadows",
    category: "Gear",
    subcategory: "Advanced",
    description:
      "A garment made from shadow itself. Induces terror in onlookers.",
    dependencies: [
      {
        itemId: "shroud_of_the_damned",
        level: 13,
      },
    ],
    upgrades: [
      {
        level: 1,
        cost: 137000,
        bountyBoostUpgrade: 0.06,
      },
      {
        level: 2,
        cost: 183000,
        bountyBoostUpgrade: 0.07,
      },
      {
        level: 3,
        cost: 267000,
        bountyBoostUpgrade: 0.09,
      },
      {
        level: 4,
        cost: 362000,
        bountyBoostUpgrade: 0.11,
      },
      {
        level: 5,
        cost: 522000,
        bountyBoostUpgrade: 0.14,
      },
      {
        level: 6,
        cost: 726000,
        bountyBoostUpgrade: 0.17,
      },
      {
        level: 7,
        cost: 1050000,
        bountyBoostUpgrade: 0.21,
      },
      {
        level: 8,
        cost: 1600000,
        bountyBoostUpgrade: 0.25,
      },
      {
        level: 9,
        cost: 2360000,
        bountyBoostUpgrade: 0.31,
      },
      {
        level: 10,
        cost: 3160000,
        bountyBoostUpgrade: 0.4,
      },
      {
        level: 11,
        cost: 4420000,
        bountyBoostUpgrade: 0.48,
      },
      {
        level: 12,
        cost: 5880000,
        bountyBoostUpgrade: 0.59,
      },
      {
        level: 13,
        cost: 8220000,
        bountyBoostUpgrade: 0.74,
      },
      {
        level: 14,
        cost: 10600000,
        bountyBoostUpgrade: 0.92,
      },
      {
        level: 15,
        cost: 15000000,
        bountyBoostUpgrade: 1.12,
      },
      {
        level: 16,
        cost: 19500000,
        bountyBoostUpgrade: 1.38,
      },
      {
        level: 17,
        cost: 25300000,
        bountyBoostUpgrade: 1.73,
      },
      {
        level: 18,
        cost: 32300000,
        bountyBoostUpgrade: 1.76,
      },
      {
        level: 19,
        cost: 40900000,
        bountyBoostUpgrade: 1.74,
      },
      {
        level: 20,
        cost: 60500000,
        bountyBoostUpgrade: 1.73,
      },
    ],
  },
  {
    name: "Bow of the Apocalypse",
    itemId: "bow_of_the_apocalypse",
    category: "Gear",
    subcategory: "Advanced",
    description: "Fires arrows that explode into swarms of locusts on impact.",
    dependencies: [
      {
        itemId: "heartpiercer_longbow",
        level: 15,
      },
    ],
    upgrades: [
      {
        level: 1,
        cost: 114000,
        bountyBoostUpgrade: 0.15,
      },
      {
        level: 2,
        cost: 167000,
        bountyBoostUpgrade: 0.2,
      },
      {
        level: 3,
        cost: 207000,
        bountyBoostUpgrade: 0.25,
      },
      {
        level: 4,
        cost: 306000,
        bountyBoostUpgrade: 0.32,
      },
      {
        level: 5,
        cost: 429000,
        bountyBoostUpgrade: 0.4,
      },
      {
        level: 6,
        cost: 634000,
        bountyBoostUpgrade: 0.51,
      },
      {
        level: 7,
        cost: 928000,
        bountyBoostUpgrade: 0.64,
      },
      {
        level: 8,
        cost: 1270000,
        bountyBoostUpgrade: 0.82,
      },
      {
        level: 9,
        cost: 1750000,
        bountyBoostUpgrade: 1.04,
      },
      {
        level: 10,
        cost: 2260000,
        bountyBoostUpgrade: 1.34,
      },
      {
        level: 11,
        cost: 2930000,
        bountyBoostUpgrade: 1.72,
      },
      {
        level: 12,
        cost: 4090000,
        bountyBoostUpgrade: 2.17,
      },
      {
        level: 13,
        cost: 5720000,
        bountyBoostUpgrade: 2.8,
      },
      {
        level: 14,
        cost: 8110000,
        bountyBoostUpgrade: 3.5,
      },
      {
        level: 15,
        cost: 11400000,
        bountyBoostUpgrade: 4.4,
      },
      {
        level: 16,
        cost: 15600000,
        bountyBoostUpgrade: 5,
      },
      {
        level: 17,
        cost: 20900000,
        bountyBoostUpgrade: 5,
      },
      {
        level: 18,
        cost: 29500000,
        bountyBoostUpgrade: 5,
      },
      {
        level: 19,
        cost: 36100000,
        bountyBoostUpgrade: 5,
      },
      {
        level: 20,
        cost: 45100000,
        bountyBoostUpgrade: 5,
      },
    ],
  },
  {
    name: "Juggernaut's Bulwark",
    itemId: "juggernauts_bulwark",
    category: "Gear",
    subcategory: "Advanced",
    description:
      "Nearly impenetrable armor that feeds on the user's life force.",
    dependencies: [
      {
        itemId: "aegis_of_the_tyrant",
        level: 15,
      },
    ],
    upgrades: [
      {
        level: 1,
        cost: 135000,
        bountyBoostUpgrade: 0.11,
      },
      {
        level: 2,
        cost: 212000,
        bountyBoostUpgrade: 0.15,
      },
      {
        level: 3,
        cost: 318000,
        bountyBoostUpgrade: 0.2,
      },
      {
        level: 4,
        cost: 433000,
        bountyBoostUpgrade: 0.26,
      },
      {
        level: 5,
        cost: 619000,
        bountyBoostUpgrade: 0.33,
      },
      {
        level: 6,
        cost: 832000,
        bountyBoostUpgrade: 0.44,
      },
      {
        level: 7,
        cost: 1150000,
        bountyBoostUpgrade: 0.58,
      },
      {
        level: 8,
        cost: 1790000,
        bountyBoostUpgrade: 0.74,
      },
      {
        level: 9,
        cost: 2340000,
        bountyBoostUpgrade: 0.98,
      },
      {
        level: 10,
        cost: 3300000,
        bountyBoostUpgrade: 1.28,
      },
      {
        level: 11,
        cost: 4510000,
        bountyBoostUpgrade: 1.64,
      },
      {
        level: 12,
        cost: 6230000,
        bountyBoostUpgrade: 2.12,
      },
      {
        level: 13,
        cost: 9040000,
        bountyBoostUpgrade: 2.8,
      },
      {
        level: 14,
        cost: 11500000,
        bountyBoostUpgrade: 3.56,
      },
      {
        level: 15,
        cost: 15600000,
        bountyBoostUpgrade: 4.71,
      },
      {
        level: 16,
        cost: 24200000,
        bountyBoostUpgrade: 5,
      },
      {
        level: 17,
        cost: 35800000,
        bountyBoostUpgrade: 5,
      },
      {
        level: 18,
        cost: 49600000,
        bountyBoostUpgrade: 5,
      },
      {
        level: 19,
        cost: 70600000,
        bountyBoostUpgrade: 5,
      },
      {
        level: 20,
        cost: 95900000,
        bountyBoostUpgrade: 5,
      },
    ],
  },
  {
    name: "Worldbreaker Maul",
    itemId: "worldbreaker_maul",
    category: "Gear",
    subcategory: "Advanced",
    description:
      "A hammer said to have forged the world. Can shatter reality itself.",
    dependencies: [
      {
        itemId: "berserkers_twin_axes",
        level: 16,
      },
    ],
    upgrades: [
      {
        level: 1,
        cost: 100000,
        bountyBoostUpgrade: 0.13,
      },
      {
        level: 2,
        cost: 139000,
        bountyBoostUpgrade: 0.16,
      },
      {
        level: 3,
        cost: 194000,
        bountyBoostUpgrade: 0.2,
      },
      {
        level: 4,
        cost: 275000,
        bountyBoostUpgrade: 0.25,
      },
      {
        level: 5,
        cost: 377000,
        bountyBoostUpgrade: 0.31,
      },
      {
        level: 6,
        cost: 494000,
        bountyBoostUpgrade: 0.39,
      },
      {
        level: 7,
        cost: 672000,
        bountyBoostUpgrade: 0.48,
      },
      {
        level: 8,
        cost: 950000,
        bountyBoostUpgrade: 0.58,
      },
      {
        level: 9,
        cost: 1240000,
        bountyBoostUpgrade: 0.72,
      },
      {
        level: 10,
        cost: 1550000,
        bountyBoostUpgrade: 0.91,
      },
      {
        level: 11,
        cost: 2170000,
        bountyBoostUpgrade: 1.15,
      },
      {
        level: 12,
        cost: 3000000,
        bountyBoostUpgrade: 1.43,
      },
      {
        level: 13,
        cost: 4190000,
        bountyBoostUpgrade: 1.81,
      },
      {
        level: 14,
        cost: 5660000,
        bountyBoostUpgrade: 2.3,
      },
      {
        level: 15,
        cost: 8530000,
        bountyBoostUpgrade: 2.87,
      },
      {
        level: 16,
        cost: 12000000,
        bountyBoostUpgrade: 2.88,
      },
      {
        level: 17,
        cost: 16400000,
        bountyBoostUpgrade: 2.85,
      },
      {
        level: 18,
        cost: 21400000,
        bountyBoostUpgrade: 2.8,
      },
      {
        level: 19,
        cost: 29800000,
        bountyBoostUpgrade: 2.77,
      },
      {
        level: 20,
        cost: 43800000,
        bountyBoostUpgrade: 2.62,
      },
    ],
  },
  {
    name: "Helm of Maddening Visions",
    itemId: "helm_of_maddening_visions",
    category: "Gear",
    subcategory: "Advanced",
    description: "Grants foresight at the cost of sanity.",
    dependencies: [
      {
        itemId: "crown_of_subjugation",
        level: 14,
      },
    ],
    upgrades: [
      {
        level: 1,
        cost: 58000,
        bountyBoostUpgrade: 0.12,
      },
      {
        level: 2,
        cost: 81000,
        bountyBoostUpgrade: 0.15,
      },
      {
        level: 3,
        cost: 108000,
        bountyBoostUpgrade: 0.18,
      },
      {
        level: 4,
        cost: 139000,
        bountyBoostUpgrade: 0.23,
      },
      {
        level: 5,
        cost: 190000,
        bountyBoostUpgrade: 0.29,
      },
      {
        level: 6,
        cost: 267000,
        bountyBoostUpgrade: 0.36,
      },
      {
        level: 7,
        cost: 323000,
        bountyBoostUpgrade: 0.44,
      },
      {
        level: 8,
        cost: 398000,
        bountyBoostUpgrade: 0.54,
      },
      {
        level: 9,
        cost: 538000,
        bountyBoostUpgrade: 0.69,
      },
      {
        level: 10,
        cost: 687000,
        bountyBoostUpgrade: 0.85,
      },
      {
        level: 11,
        cost: 905000,
        bountyBoostUpgrade: 1.09,
      },
      {
        level: 12,
        cost: 1260000,
        bountyBoostUpgrade: 1.36,
      },
      {
        level: 13,
        cost: 1790000,
        bountyBoostUpgrade: 1.7,
      },
      {
        level: 14,
        cost: 2200000,
        bountyBoostUpgrade: 2.14,
      },
      {
        level: 15,
        cost: 2730000,
        bountyBoostUpgrade: 2.72,
      },
      {
        level: 16,
        cost: 4040000,
        bountyBoostUpgrade: 3.34,
      },
      {
        level: 17,
        cost: 5340000,
        bountyBoostUpgrade: 4.17,
      },
      {
        level: 18,
        cost: 7250000,
        bountyBoostUpgrade: 4.2,
      },
      {
        level: 19,
        cost: 9880000,
        bountyBoostUpgrade: 4.16,
      },
      {
        level: 20,
        cost: 12700000,
        bountyBoostUpgrade: 4.17,
      },
    ],
  },
  {
    name: "Brutalize",
    itemId: "brutalize",
    category: "Skills",
    subcategory: "Basic",
    description:
      "A vicious attack that maims the target, reducing their combat effectiveness.",
    dependencies: [],
    upgrades: [
      {
        level: 1,
        cost: 78000,
        bountyBoostUpgrade: 0.02,
      },
      {
        level: 2,
        cost: 130000,
        bountyBoostUpgrade: 0.02,
      },
      {
        level: 3,
        cost: 218000,
        bountyBoostUpgrade: 0.02,
      },
      {
        level: 4,
        cost: 336000,
        bountyBoostUpgrade: 0.03,
      },
      {
        level: 5,
        cost: 539000,
        bountyBoostUpgrade: 0.03,
      },
      {
        level: 6,
        cost: 846000,
        bountyBoostUpgrade: 0.04,
      },
      {
        level: 7,
        cost: 1330000,
        bountyBoostUpgrade: 0.04,
      },
      {
        level: 8,
        cost: 2260000,
        bountyBoostUpgrade: 0.05,
      },
      {
        level: 9,
        cost: 3710000,
        bountyBoostUpgrade: 0.06,
      },
      {
        level: 10,
        cost: 6610000,
        bountyBoostUpgrade: 0.07,
      },
      {
        level: 11,
        cost: 11100000,
        bountyBoostUpgrade: 0.09,
      },
      {
        level: 12,
        cost: 17400000,
        bountyBoostUpgrade: 0.1,
      },
      {
        level: 13,
        cost: 30900000,
        bountyBoostUpgrade: 0.12,
      },
      {
        level: 14,
        cost: 50000000,
        bountyBoostUpgrade: 0.15,
      },
      {
        level: 15,
        cost: 86400000,
        bountyBoostUpgrade: 0.17,
      },
      {
        level: 16,
        cost: 139000000,
        bountyBoostUpgrade: 0.2,
      },
      {
        level: 17,
        cost: 229000000,
        bountyBoostUpgrade: 0.24,
      },
      {
        level: 18,
        cost: 368000000,
        bountyBoostUpgrade: 0.29,
      },
      {
        level: 19,
        cost: 642000000,
        bountyBoostUpgrade: 0.34,
      },
      {
        level: 20,
        cost: 1140000000,
        bountyBoostUpgrade: 0.34,
      },
    ],
  },
  {
    name: "Blood Shield",
    itemId: "blood_shield",
    category: "Skills",
    subcategory: "Basic",
    description: "Create a barrier using the blood of fallen enemies.",
    dependencies: [],
    upgrades: [
      {
        level: 1,
        cost: 45000,
        bountyBoostUpgrade: 0.02,
      },
      {
        level: 2,
        cost: 68000,
        bountyBoostUpgrade: 0.03,
      },
      {
        level: 3,
        cost: 97000,
        bountyBoostUpgrade: 0.03,
      },
      {
        level: 4,
        cost: 152000,
        bountyBoostUpgrade: 0.04,
      },
      {
        level: 5,
        cost: 215000,
        bountyBoostUpgrade: 0.04,
      },
      {
        level: 6,
        cost: 302000,
        bountyBoostUpgrade: 0.05,
      },
      {
        level: 7,
        cost: 472000,
        bountyBoostUpgrade: 0.06,
      },
      {
        level: 8,
        cost: 707000,
        bountyBoostUpgrade: 0.07,
      },
      {
        level: 9,
        cost: 1050000,
        bountyBoostUpgrade: 0.09,
      },
      {
        level: 10,
        cost: 1520000,
        bountyBoostUpgrade: 0.11,
      },
      {
        level: 11,
        cost: 2200000,
        bountyBoostUpgrade: 0.13,
      },
      {
        level: 12,
        cost: 3190000,
        bountyBoostUpgrade: 0.15,
      },
      {
        level: 13,
        cost: 4540000,
        bountyBoostUpgrade: 0.18,
      },
      {
        level: 14,
        cost: 6150000,
        bountyBoostUpgrade: 0.21,
      },
      {
        level: 15,
        cost: 9180000,
        bountyBoostUpgrade: 0.25,
      },
      {
        level: 16,
        cost: 13200000,
        bountyBoostUpgrade: 0.29,
      },
      {
        level: 17,
        cost: 19800000,
        bountyBoostUpgrade: 0.35,
      },
      {
        level: 18,
        cost: 27300000,
        bountyBoostUpgrade: 0.35,
      },
      {
        level: 19,
        cost: 41200000,
        bountyBoostUpgrade: 0.34,
      },
      {
        level: 20,
        cost: 55500000,
        bountyBoostUpgrade: 0.34,
      },
    ],
  },
  {
    name: "Bonecrusher",
    itemId: "bonecrusher",
    category: "Skills",
    subcategory: "Basic",
    description:
      "A powerful blow that can shatter bones, crippling the target.",
    dependencies: [],
    upgrades: [
      {
        level: 1,
        cost: 40000,
        bountyBoostUpgrade: 0.03,
      },
      {
        level: 2,
        cost: 62000,
        bountyBoostUpgrade: 0.03,
      },
      {
        level: 3,
        cost: 106000,
        bountyBoostUpgrade: 0.04,
      },
      {
        level: 4,
        cost: 151000,
        bountyBoostUpgrade: 0.04,
      },
      {
        level: 5,
        cost: 231000,
        bountyBoostUpgrade: 0.05,
      },
      {
        level: 6,
        cost: 378000,
        bountyBoostUpgrade: 0.06,
      },
      {
        level: 7,
        cost: 607000,
        bountyBoostUpgrade: 0.07,
      },
      {
        level: 8,
        cost: 980000,
        bountyBoostUpgrade: 0.08,
      },
      {
        level: 9,
        cost: 1580000,
        bountyBoostUpgrade: 0.1,
      },
      {
        level: 10,
        cost: 2510000,
        bountyBoostUpgrade: 0.11,
      },
      {
        level: 11,
        cost: 4080000,
        bountyBoostUpgrade: 0.14,
      },
      {
        level: 12,
        cost: 6660000,
        bountyBoostUpgrade: 0.16,
      },
      {
        level: 13,
        cost: 9970000,
        bountyBoostUpgrade: 0.18,
      },
      {
        level: 14,
        cost: 15600000,
        bountyBoostUpgrade: 0.22,
      },
      {
        level: 15,
        cost: 26200000,
        bountyBoostUpgrade: 0.26,
      },
      {
        level: 16,
        cost: 37100000,
        bountyBoostUpgrade: 0.3,
      },
      {
        level: 17,
        cost: 58700000,
        bountyBoostUpgrade: 0.3,
      },
      {
        level: 18,
        cost: 93000000,
        bountyBoostUpgrade: 0.3,
      },
      {
        level: 19,
        cost: 137000000,
        bountyBoostUpgrade: 0.3,
      },
      {
        level: 20,
        cost: 204000000,
        bountyBoostUpgrade: 0.29,
      },
    ],
  },
  {
    name: "Deadeye",
    itemId: "deadeye",
    category: "Skills",
    subcategory: "Basic",
    description: "Aim for vital points, increasing critical hit chance.",
    dependencies: [],
    upgrades: [
      {
        level: 1,
        cost: 61000,
        bountyBoostUpgrade: 0.03,
      },
      {
        level: 2,
        cost: 91000,
        bountyBoostUpgrade: 0.03,
      },
      {
        level: 3,
        cost: 141000,
        bountyBoostUpgrade: 0.04,
      },
      {
        level: 4,
        cost: 191000,
        bountyBoostUpgrade: 0.04,
      },
      {
        level: 5,
        cost: 287000,
        bountyBoostUpgrade: 0.05,
      },
      {
        level: 6,
        cost: 468000,
        bountyBoostUpgrade: 0.06,
      },
      {
        level: 7,
        cost: 722000,
        bountyBoostUpgrade: 0.06,
      },
      {
        level: 8,
        cost: 1030000,
        bountyBoostUpgrade: 0.08,
      },
      {
        level: 9,
        cost: 1660000,
        bountyBoostUpgrade: 0.09,
      },
      {
        level: 10,
        cost: 2320000,
        bountyBoostUpgrade: 0.1,
      },
      {
        level: 11,
        cost: 3220000,
        bountyBoostUpgrade: 0.12,
      },
      {
        level: 12,
        cost: 5080000,
        bountyBoostUpgrade: 0.13,
      },
      {
        level: 13,
        cost: 7780000,
        bountyBoostUpgrade: 0.15,
      },
      {
        level: 14,
        cost: 11800000,
        bountyBoostUpgrade: 0.18,
      },
      {
        level: 15,
        cost: 17100000,
        bountyBoostUpgrade: 0.2,
      },
      {
        level: 16,
        cost: 24600000,
        bountyBoostUpgrade: 0.23,
      },
      {
        level: 17,
        cost: 36100000,
        bountyBoostUpgrade: 0.23,
      },
      {
        level: 18,
        cost: 53500000,
        bountyBoostUpgrade: 0.24,
      },
      {
        level: 19,
        cost: 74500000,
        bountyBoostUpgrade: 0.24,
      },
      {
        level: 20,
        cost: 116000000,
        bountyBoostUpgrade: 0.23,
      },
    ],
  },
  {
    name: "Bloodrush",
    itemId: "bloodrush",
    category: "Skills",
    subcategory: "Basic",
    description: "A frenzied charge that leaves a trail of carnage.",
    dependencies: [],
    upgrades: [
      {
        level: 1,
        cost: 36000,
        bountyBoostUpgrade: 0.01,
      },
      {
        level: 2,
        cost: 53000,
        bountyBoostUpgrade: 0.02,
      },
      {
        level: 3,
        cost: 82000,
        bountyBoostUpgrade: 0.02,
      },
      {
        level: 4,
        cost: 121000,
        bountyBoostUpgrade: 0.03,
      },
      {
        level: 5,
        cost: 181000,
        bountyBoostUpgrade: 0.03,
      },
      {
        level: 6,
        cost: 284000,
        bountyBoostUpgrade: 0.04,
      },
      {
        level: 7,
        cost: 435000,
        bountyBoostUpgrade: 0.05,
      },
      {
        level: 8,
        cost: 679000,
        bountyBoostUpgrade: 0.06,
      },
      {
        level: 9,
        cost: 1020000,
        bountyBoostUpgrade: 0.07,
      },
      {
        level: 10,
        cost: 1410000,
        bountyBoostUpgrade: 0.09,
      },
      {
        level: 11,
        cost: 2170000,
        bountyBoostUpgrade: 0.11,
      },
      {
        level: 12,
        cost: 3230000,
        bountyBoostUpgrade: 0.13,
      },
      {
        level: 13,
        cost: 4740000,
        bountyBoostUpgrade: 0.16,
      },
      {
        level: 14,
        cost: 7430000,
        bountyBoostUpgrade: 0.2,
      },
      {
        level: 15,
        cost: 11500000,
        bountyBoostUpgrade: 0.25,
      },
      {
        level: 16,
        cost: 18300000,
        bountyBoostUpgrade: 0.3,
      },
      {
        level: 17,
        cost: 26700000,
        bountyBoostUpgrade: 0.38,
      },
      {
        level: 18,
        cost: 37300000,
        bountyBoostUpgrade: 0.38,
      },
      {
        level: 19,
        cost: 57500000,
        bountyBoostUpgrade: 0.38,
      },
      {
        level: 20,
        cost: 88600000,
        bountyBoostUpgrade: 0.37,
      },
    ],
  },
  {
    name: "Skullsplitter",
    itemId: "skullsplitter",
    category: "Skills",
    subcategory: "Basic",
    description: "A devastating kick aimed at the head.",
    dependencies: [],
    upgrades: [
      {
        level: 1,
        cost: 59000,
        bountyBoostUpgrade: 0.03,
      },
      {
        level: 2,
        cost: 97000,
        bountyBoostUpgrade: 0.04,
      },
      {
        level: 3,
        cost: 168000,
        bountyBoostUpgrade: 0.05,
      },
      {
        level: 4,
        cost: 256000,
        bountyBoostUpgrade: 0.06,
      },
      {
        level: 5,
        cost: 442000,
        bountyBoostUpgrade: 0.07,
      },
      {
        level: 6,
        cost: 763000,
        bountyBoostUpgrade: 0.09,
      },
      {
        level: 7,
        cost: 1290000,
        bountyBoostUpgrade: 0.11,
      },
      {
        level: 8,
        cost: 2280000,
        bountyBoostUpgrade: 0.13,
      },
      {
        level: 9,
        cost: 3900000,
        bountyBoostUpgrade: 0.16,
      },
      {
        level: 10,
        cost: 6400000,
        bountyBoostUpgrade: 0.19,
      },
      {
        level: 11,
        cost: 10400000,
        bountyBoostUpgrade: 0.23,
      },
      {
        level: 12,
        cost: 17000000,
        bountyBoostUpgrade: 0.28,
      },
      {
        level: 13,
        cost: 27800000,
        bountyBoostUpgrade: 0.34,
      },
      {
        level: 14,
        cost: 49600000,
        bountyBoostUpgrade: 0.41,
      },
      {
        level: 15,
        cost: 86400000,
        bountyBoostUpgrade: 0.49,
      },
      {
        level: 16,
        cost: 138000000,
        bountyBoostUpgrade: 0.5,
      },
      {
        level: 17,
        cost: 238000000,
        bountyBoostUpgrade: 0.49,
      },
      {
        level: 18,
        cost: 394000000,
        bountyBoostUpgrade: 0.48,
      },
      {
        level: 19,
        cost: 691000000,
        bountyBoostUpgrade: 0.48,
      },
      {
        level: 20,
        cost: 1120000000,
        bountyBoostUpgrade: 0.47,
      },
    ],
  },
  {
    name: "Riposte of Agony",
    itemId: "riposte_of_agony",
    category: "Skills",
    subcategory: "Basic",
    description: "Counter an enemy attack with a pain-inflicting strike.",
    dependencies: [],
    upgrades: [
      {
        level: 1,
        cost: 59000,
        bountyBoostUpgrade: 0.04,
      },
      {
        level: 2,
        cost: 97000,
        bountyBoostUpgrade: 0.04,
      },
      {
        level: 3,
        cost: 154000,
        bountyBoostUpgrade: 0.05,
      },
      {
        level: 4,
        cost: 252000,
        bountyBoostUpgrade: 0.06,
      },
      {
        level: 5,
        cost: 433000,
        bountyBoostUpgrade: 0.07,
      },
      {
        level: 6,
        cost: 633000,
        bountyBoostUpgrade: 0.09,
      },
      {
        level: 7,
        cost: 1080000,
        bountyBoostUpgrade: 0.11,
      },
      {
        level: 8,
        cost: 1870000,
        bountyBoostUpgrade: 0.13,
      },
      {
        level: 9,
        cost: 3070000,
        bountyBoostUpgrade: 0.15,
      },
      {
        level: 10,
        cost: 4860000,
        bountyBoostUpgrade: 0.18,
      },
      {
        level: 11,
        cost: 7030000,
        bountyBoostUpgrade: 0.21,
      },
      {
        level: 12,
        cost: 10500000,
        bountyBoostUpgrade: 0.26,
      },
      {
        level: 13,
        cost: 15500000,
        bountyBoostUpgrade: 0.31,
      },
      {
        level: 14,
        cost: 22400000,
        bountyBoostUpgrade: 0.37,
      },
      {
        level: 15,
        cost: 35700000,
        bountyBoostUpgrade: 0.45,
      },
      {
        level: 16,
        cost: 53400000,
        bountyBoostUpgrade: 0.53,
      },
      {
        level: 17,
        cost: 88900000,
        bountyBoostUpgrade: 0.64,
      },
      {
        level: 18,
        cost: 129000000,
        bountyBoostUpgrade: 0.63,
      },
      {
        level: 19,
        cost: 198000000,
        bountyBoostUpgrade: 0.62,
      },
      {
        level: 20,
        cost: 348000000,
        bountyBoostUpgrade: 0.6,
      },
    ],
  },
  {
    name: "Fleshrender",
    itemId: "fleshrender",
    category: "Skills",
    subcategory: "Basic",
    description: "Throw a weapon with enough force to pierce multiple enemies.",
    dependencies: [],
    upgrades: [
      {
        level: 1,
        cost: 80000,
        bountyBoostUpgrade: 0.03,
      },
      {
        level: 2,
        cost: 135000,
        bountyBoostUpgrade: 0.03,
      },
      {
        level: 3,
        cost: 200000,
        bountyBoostUpgrade: 0.04,
      },
      {
        level: 4,
        cost: 320000,
        bountyBoostUpgrade: 0.05,
      },
      {
        level: 5,
        cost: 507000,
        bountyBoostUpgrade: 0.06,
      },
      {
        level: 6,
        cost: 777000,
        bountyBoostUpgrade: 0.07,
      },
      {
        level: 7,
        cost: 1210000,
        bountyBoostUpgrade: 0.08,
      },
      {
        level: 8,
        cost: 2040000,
        bountyBoostUpgrade: 0.1,
      },
      {
        level: 9,
        cost: 3440000,
        bountyBoostUpgrade: 0.12,
      },
      {
        level: 10,
        cost: 5370000,
        bountyBoostUpgrade: 0.14,
      },
      {
        level: 11,
        cost: 7800000,
        bountyBoostUpgrade: 0.17,
      },
      {
        level: 12,
        cost: 11800000,
        bountyBoostUpgrade: 0.21,
      },
      {
        level: 13,
        cost: 19800000,
        bountyBoostUpgrade: 0.25,
      },
      {
        level: 14,
        cost: 31000000,
        bountyBoostUpgrade: 0.3,
      },
      {
        level: 15,
        cost: 48400000,
        bountyBoostUpgrade: 0.35,
      },
      {
        level: 16,
        cost: 78500000,
        bountyBoostUpgrade: 0.36,
      },
      {
        level: 17,
        cost: 119000000,
        bountyBoostUpgrade: 0.35,
      },
      {
        level: 18,
        cost: 182000000,
        bountyBoostUpgrade: 0.34,
      },
      {
        level: 19,
        cost: 275000000,
        bountyBoostUpgrade: 0.34,
      },
      {
        level: 20,
        cost: 417000000,
        bountyBoostUpgrade: 0.33,
      },
    ],
  },
  {
    name: "Shadowstalk",
    itemId: "shadowstalk",
    category: "Skills",
    subcategory: "Basic",
    description: "Move unseen, leaving a trail of whispered terrors.",
    dependencies: [],
    upgrades: [
      {
        level: 1,
        cost: 50000,
        bountyBoostUpgrade: 0.03,
      },
      {
        level: 2,
        cost: 75000,
        bountyBoostUpgrade: 0.03,
      },
      {
        level: 3,
        cost: 122000,
        bountyBoostUpgrade: 0.04,
      },
      {
        level: 4,
        cost: 200000,
        bountyBoostUpgrade: 0.05,
      },
      {
        level: 5,
        cost: 350000,
        bountyBoostUpgrade: 0.05,
      },
      {
        level: 6,
        cost: 611000,
        bountyBoostUpgrade: 0.06,
      },
      {
        level: 7,
        cost: 996000,
        bountyBoostUpgrade: 0.07,
      },
      {
        level: 8,
        cost: 1730000,
        bountyBoostUpgrade: 0.08,
      },
      {
        level: 9,
        cost: 3080000,
        bountyBoostUpgrade: 0.1,
      },
      {
        level: 10,
        cost: 5240000,
        bountyBoostUpgrade: 0.11,
      },
      {
        level: 11,
        cost: 9000000,
        bountyBoostUpgrade: 0.13,
      },
      {
        level: 12,
        cost: 14000000,
        bountyBoostUpgrade: 0.16,
      },
      {
        level: 13,
        cost: 23400000,
        bountyBoostUpgrade: 0.18,
      },
      {
        level: 14,
        cost: 36000000,
        bountyBoostUpgrade: 0.22,
      },
      {
        level: 15,
        cost: 57100000,
        bountyBoostUpgrade: 0.26,
      },
      {
        level: 16,
        cost: 96400000,
        bountyBoostUpgrade: 0.3,
      },
      {
        level: 17,
        cost: 153000000,
        bountyBoostUpgrade: 0.35,
      },
      {
        level: 18,
        cost: 248000000,
        bountyBoostUpgrade: 0.41,
      },
      {
        level: 19,
        cost: 443000000,
        bountyBoostUpgrade: 0.48,
      },
      {
        level: 20,
        cost: 762000000,
        bountyBoostUpgrade: 0.49,
      },
    ],
  },
  {
    name: "Wallcrawler",
    itemId: "wallcrawler",
    category: "Skills",
    subcategory: "Basic",
    description:
      "Scale surfaces using blade weapons, leaving a trail of destruction.",
    dependencies: [],
    upgrades: [
      {
        level: 1,
        cost: 53000,
        bountyBoostUpgrade: 0.05,
      },
      {
        level: 2,
        cost: 78000,
        bountyBoostUpgrade: 0.05,
      },
      {
        level: 3,
        cost: 116000,
        bountyBoostUpgrade: 0.07,
      },
      {
        level: 4,
        cost: 170000,
        bountyBoostUpgrade: 0.08,
      },
      {
        level: 5,
        cost: 239000,
        bountyBoostUpgrade: 0.1,
      },
      {
        level: 6,
        cost: 351000,
        bountyBoostUpgrade: 0.11,
      },
      {
        level: 7,
        cost: 498000,
        bountyBoostUpgrade: 0.14,
      },
      {
        level: 8,
        cost: 698000,
        bountyBoostUpgrade: 0.16,
      },
      {
        level: 9,
        cost: 1030000,
        bountyBoostUpgrade: 0.2,
      },
      {
        level: 10,
        cost: 1630000,
        bountyBoostUpgrade: 0.24,
      },
      {
        level: 11,
        cost: 2680000,
        bountyBoostUpgrade: 0.29,
      },
      {
        level: 12,
        cost: 4020000,
        bountyBoostUpgrade: 0.34,
      },
      {
        level: 13,
        cost: 6430000,
        bountyBoostUpgrade: 0.42,
      },
      {
        level: 14,
        cost: 9250000,
        bountyBoostUpgrade: 0.51,
      },
      {
        level: 15,
        cost: 14000000,
        bountyBoostUpgrade: 0.61,
      },
      {
        level: 16,
        cost: 20800000,
        bountyBoostUpgrade: 0.73,
      },
      {
        level: 17,
        cost: 32000000,
        bountyBoostUpgrade: 0.9,
      },
      {
        level: 18,
        cost: 53900000,
        bountyBoostUpgrade: 1.08,
      },
      {
        level: 19,
        cost: 75100000,
        bountyBoostUpgrade: 1.28,
      },
      {
        level: 20,
        cost: 109000000,
        bountyBoostUpgrade: 1.3,
      },
    ],
  },
  {
    name: "Twinblade Tornado",
    itemId: "twinblade_tornado",
    category: "Skills",
    subcategory: "Intermediate",
    description: "Dual-wield spin attack that shreds nearby enemies.",
    dependencies: [
      {
        itemId: "brutalize",
        level: 8,
      },
      {
        itemId: "bloodrush",
        level: 8,
      },
    ],
    upgrades: [
      {
        level: 1,
        cost: 66000,
        bountyBoostUpgrade: 0.06,
      },
      {
        level: 2,
        cost: 96000,
        bountyBoostUpgrade: 0.07,
      },
      {
        level: 3,
        cost: 141000,
        bountyBoostUpgrade: 0.09,
      },
      {
        level: 4,
        cost: 223000,
        bountyBoostUpgrade: 0.11,
      },
      {
        level: 5,
        cost: 360000,
        bountyBoostUpgrade: 0.14,
      },
      {
        level: 6,
        cost: 572000,
        bountyBoostUpgrade: 0.17,
      },
      {
        level: 7,
        cost: 898000,
        bountyBoostUpgrade: 0.21,
      },
      {
        level: 8,
        cost: 1450000,
        bountyBoostUpgrade: 0.27,
      },
      {
        level: 9,
        cost: 2270000,
        bountyBoostUpgrade: 0.33,
      },
      {
        level: 10,
        cost: 3670000,
        bountyBoostUpgrade: 0.42,
      },
      {
        level: 11,
        cost: 5890000,
        bountyBoostUpgrade: 0.52,
      },
      {
        level: 12,
        cost: 8410000,
        bountyBoostUpgrade: 0.65,
      },
      {
        level: 13,
        cost: 12200000,
        bountyBoostUpgrade: 0.82,
      },
      {
        level: 14,
        cost: 18400000,
        bountyBoostUpgrade: 1.04,
      },
      {
        level: 15,
        cost: 29400000,
        bountyBoostUpgrade: 1.3,
      },
      {
        level: 16,
        cost: 45200000,
        bountyBoostUpgrade: 1.28,
      },
      {
        level: 17,
        cost: 69000000,
        bountyBoostUpgrade: 1.26,
      },
      {
        level: 18,
        cost: 106000000,
        bountyBoostUpgrade: 1.22,
      },
      {
        level: 19,
        cost: 167000000,
        bountyBoostUpgrade: 1.21,
      },
      {
        level: 20,
        cost: 277000000,
        bountyBoostUpgrade: 1.17,
      },
    ],
  },
  {
    name: "Crushing Blow",
    itemId: "crushing_blow",
    category: "Skills",
    subcategory: "Intermediate",
    description: "A single, powerful strike that ignores armor.",
    dependencies: [
      {
        itemId: "bonecrusher",
        level: 10,
      },
    ],
    upgrades: [
      {
        level: 1,
        cost: 117000,
        bountyBoostUpgrade: 0.09,
      },
      {
        level: 2,
        cost: 189000,
        bountyBoostUpgrade: 0.11,
      },
      {
        level: 3,
        cost: 259000,
        bountyBoostUpgrade: 0.13,
      },
      {
        level: 4,
        cost: 391000,
        bountyBoostUpgrade: 0.15,
      },
      {
        level: 5,
        cost: 638000,
        bountyBoostUpgrade: 0.18,
      },
      {
        level: 6,
        cost: 941000,
        bountyBoostUpgrade: 0.21,
      },
      {
        level: 7,
        cost: 1480000,
        bountyBoostUpgrade: 0.25,
      },
      {
        level: 8,
        cost: 2200000,
        bountyBoostUpgrade: 0.3,
      },
      {
        level: 9,
        cost: 3470000,
        bountyBoostUpgrade: 0.36,
      },
      {
        level: 10,
        cost: 5050000,
        bountyBoostUpgrade: 0.43,
      },
      {
        level: 11,
        cost: 8040000,
        bountyBoostUpgrade: 0.51,
      },
      {
        level: 12,
        cost: 12300000,
        bountyBoostUpgrade: 0.59,
      },
      {
        level: 13,
        cost: 18300000,
        bountyBoostUpgrade: 0.69,
      },
      {
        level: 14,
        cost: 25200000,
        bountyBoostUpgrade: 0.8,
      },
      {
        level: 15,
        cost: 36500000,
        bountyBoostUpgrade: 0.97,
      },
      {
        level: 16,
        cost: 53800000,
        bountyBoostUpgrade: 1.16,
      },
      {
        level: 17,
        cost: 74100000,
        bountyBoostUpgrade: 1.16,
      },
      {
        level: 18,
        cost: 115000000,
        bountyBoostUpgrade: 1.16,
      },
      {
        level: 19,
        cost: 170000000,
        bountyBoostUpgrade: 1.12,
      },
      {
        level: 20,
        cost: 251000000,
        bountyBoostUpgrade: 1.1,
      },
    ],
  },
  {
    name: "Tower of Pain",
    itemId: "tower_of_pain",
    category: "Skills",
    subcategory: "Intermediate",
    description: "Use a shield offensively to create a column of spikes.",
    dependencies: [
      {
        itemId: "blood_shield",
        level: 9,
      },
    ],
    upgrades: [
      {
        level: 1,
        cost: 93000,
        bountyBoostUpgrade: 0.05,
      },
      {
        level: 2,
        cost: 129000,
        bountyBoostUpgrade: 0.06,
      },
      {
        level: 3,
        cost: 188000,
        bountyBoostUpgrade: 0.07,
      },
      {
        level: 4,
        cost: 272000,
        bountyBoostUpgrade: 0.09,
      },
      {
        level: 5,
        cost: 393000,
        bountyBoostUpgrade: 0.11,
      },
      {
        level: 6,
        cost: 565000,
        bountyBoostUpgrade: 0.14,
      },
      {
        level: 7,
        cost: 824000,
        bountyBoostUpgrade: 0.17,
      },
      {
        level: 8,
        cost: 1330000,
        bountyBoostUpgrade: 0.21,
      },
      {
        level: 9,
        cost: 2090000,
        bountyBoostUpgrade: 0.27,
      },
      {
        level: 10,
        cost: 3140000,
        bountyBoostUpgrade: 0.33,
      },
      {
        level: 11,
        cost: 4380000,
        bountyBoostUpgrade: 0.41,
      },
      {
        level: 12,
        cost: 7130000,
        bountyBoostUpgrade: 0.5,
      },
      {
        level: 13,
        cost: 11100000,
        bountyBoostUpgrade: 0.63,
      },
      {
        level: 14,
        cost: 17200000,
        bountyBoostUpgrade: 0.8,
      },
      {
        level: 15,
        cost: 26200000,
        bountyBoostUpgrade: 1,
      },
      {
        level: 16,
        cost: 38400000,
        bountyBoostUpgrade: 1.26,
      },
      {
        level: 17,
        cost: 58000000,
        bountyBoostUpgrade: 1.6,
      },
      {
        level: 18,
        cost: 93200000,
        bountyBoostUpgrade: 2.03,
      },
      {
        level: 19,
        cost: 134000000,
        bountyBoostUpgrade: 2.04,
      },
      {
        level: 20,
        cost: 206000000,
        bountyBoostUpgrade: 2.04,
      },
    ],
  },
  {
    name: "Arrowstorm",
    itemId: "arrowstorm",
    category: "Skills",
    subcategory: "Intermediate",
    description:
      "Unleash a barrage of arrows, sacrificing accuracy for volume.",
    dependencies: [
      {
        itemId: "deadeye",
        level: 11,
      },
    ],
    upgrades: [
      {
        level: 1,
        cost: 115000,
        bountyBoostUpgrade: 0.07,
      },
      {
        level: 2,
        cost: 158000,
        bountyBoostUpgrade: 0.09,
      },
      {
        level: 3,
        cost: 235000,
        bountyBoostUpgrade: 0.12,
      },
      {
        level: 4,
        cost: 375000,
        bountyBoostUpgrade: 0.15,
      },
      {
        level: 5,
        cost: 580000,
        bountyBoostUpgrade: 0.19,
      },
      {
        level: 6,
        cost: 893000,
        bountyBoostUpgrade: 0.24,
      },
      {
        level: 7,
        cost: 1350000,
        bountyBoostUpgrade: 0.31,
      },
      {
        level: 8,
        cost: 1940000,
        bountyBoostUpgrade: 0.4,
      },
      {
        level: 9,
        cost: 3110000,
        bountyBoostUpgrade: 0.51,
      },
      {
        level: 10,
        cost: 4600000,
        bountyBoostUpgrade: 0.65,
      },
      {
        level: 11,
        cost: 7590000,
        bountyBoostUpgrade: 0.84,
      },
      {
        level: 12,
        cost: 11200000,
        bountyBoostUpgrade: 1.08,
      },
      {
        level: 13,
        cost: 16400000,
        bountyBoostUpgrade: 1.38,
      },
      {
        level: 14,
        cost: 26800000,
        bountyBoostUpgrade: 1.73,
      },
      {
        level: 15,
        cost: 39100000,
        bountyBoostUpgrade: 2.24,
      },
      {
        level: 16,
        cost: 62700000,
        bountyBoostUpgrade: 2.82,
      },
      {
        level: 17,
        cost: 91500000,
        bountyBoostUpgrade: 3.53,
      },
      {
        level: 18,
        cost: 135000000,
        bountyBoostUpgrade: 4.43,
      },
      {
        level: 19,
        cost: 206000000,
        bountyBoostUpgrade: 4.43,
      },
      {
        level: 20,
        cost: 297000000,
        bountyBoostUpgrade: 4.37,
      },
    ],
  },
  {
    name: "Eviscerate",
    itemId: "eviscerate",
    category: "Skills",
    subcategory: "Intermediate",
    description: "A precise strike that spills the target's innards.",
    dependencies: [
      {
        itemId: "fleshrender",
        level: 9,
      },
    ],
    upgrades: [
      {
        level: 1,
        cost: 53000,
        bountyBoostUpgrade: 0.08,
      },
      {
        level: 2,
        cost: 78000,
        bountyBoostUpgrade: 0.1,
      },
      {
        level: 3,
        cost: 127000,
        bountyBoostUpgrade: 0.12,
      },
      {
        level: 4,
        cost: 196000,
        bountyBoostUpgrade: 0.15,
      },
      {
        level: 5,
        cost: 296000,
        bountyBoostUpgrade: 0.19,
      },
      {
        level: 6,
        cost: 474000,
        bountyBoostUpgrade: 0.23,
      },
      {
        level: 7,
        cost: 808000,
        bountyBoostUpgrade: 0.29,
      },
      {
        level: 8,
        cost: 1140000,
        bountyBoostUpgrade: 0.37,
      },
      {
        level: 9,
        cost: 1720000,
        bountyBoostUpgrade: 0.46,
      },
      {
        level: 10,
        cost: 2840000,
        bountyBoostUpgrade: 0.57,
      },
      {
        level: 11,
        cost: 4090000,
        bountyBoostUpgrade: 0.7,
      },
      {
        level: 12,
        cost: 5920000,
        bountyBoostUpgrade: 0.86,
      },
      {
        level: 13,
        cost: 8940000,
        bountyBoostUpgrade: 1.06,
      },
      {
        level: 14,
        cost: 14000000,
        bountyBoostUpgrade: 1.3,
      },
      {
        level: 15,
        cost: 21300000,
        bountyBoostUpgrade: 1.59,
      },
      {
        level: 16,
        cost: 32000000,
        bountyBoostUpgrade: 1.99,
      },
      {
        level: 17,
        cost: 54600000,
        bountyBoostUpgrade: 1.96,
      },
      {
        level: 18,
        cost: 88000000,
        bountyBoostUpgrade: 1.97,
      },
      {
        level: 19,
        cost: 147000000,
        bountyBoostUpgrade: 1.97,
      },
      {
        level: 20,
        cost: 237000000,
        bountyBoostUpgrade: 1.88,
      },
    ],
  },
  {
    name: "Warcry of the Damned",
    itemId: "warcry_of_the_damned",
    category: "Skills",
    subcategory: "Intermediate",
    description: "A terrifying shout that can stop hearts with fear.",
    dependencies: [
      {
        itemId: "skullsplitter",
        level: 8,
      },
    ],
    upgrades: [
      {
        level: 1,
        cost: 91000,
        bountyBoostUpgrade: 0.05,
      },
      {
        level: 2,
        cost: 120000,
        bountyBoostUpgrade: 0.06,
      },
      {
        level: 3,
        cost: 177000,
        bountyBoostUpgrade: 0.07,
      },
      {
        level: 4,
        cost: 259000,
        bountyBoostUpgrade: 0.1,
      },
      {
        level: 5,
        cost: 364000,
        bountyBoostUpgrade: 0.13,
      },
      {
        level: 6,
        cost: 543000,
        bountyBoostUpgrade: 0.16,
      },
      {
        level: 7,
        cost: 735000,
        bountyBoostUpgrade: 0.21,
      },
      {
        level: 8,
        cost: 1100000,
        bountyBoostUpgrade: 0.27,
      },
      {
        level: 9,
        cost: 1580000,
        bountyBoostUpgrade: 0.34,
      },
      {
        level: 10,
        cost: 2370000,
        bountyBoostUpgrade: 0.44,
      },
      {
        level: 11,
        cost: 3210000,
        bountyBoostUpgrade: 0.56,
      },
      {
        level: 12,
        cost: 4810000,
        bountyBoostUpgrade: 0.72,
      },
      {
        level: 13,
        cost: 7120000,
        bountyBoostUpgrade: 0.93,
      },
      {
        level: 14,
        cost: 9750000,
        bountyBoostUpgrade: 1.2,
      },
      {
        level: 15,
        cost: 14300000,
        bountyBoostUpgrade: 1.52,
      },
      {
        level: 16,
        cost: 23000000,
        bountyBoostUpgrade: 1.54,
      },
      {
        level: 17,
        cost: 35600000,
        bountyBoostUpgrade: 1.55,
      },
      {
        level: 18,
        cost: 51300000,
        bountyBoostUpgrade: 1.51,
      },
      {
        level: 19,
        cost: 74200000,
        bountyBoostUpgrade: 1.47,
      },
      {
        level: 20,
        cost: 100000000,
        bountyBoostUpgrade: 1.41,
      },
    ],
  },
  {
    name: "Bloodmist Dodge",
    itemId: "bloodmist_dodge",
    category: "Skills",
    subcategory: "Intermediate",
    description: "Dissolve into a mist of blood, avoiding damage.",
    dependencies: [
      {
        itemId: "shadowstalk",
        level: 10,
      },
    ],
    upgrades: [
      {
        level: 1,
        cost: 68000,
        bountyBoostUpgrade: 0.05,
      },
      {
        level: 2,
        cost: 105000,
        bountyBoostUpgrade: 0.06,
      },
      {
        level: 3,
        cost: 165000,
        bountyBoostUpgrade: 0.07,
      },
      {
        level: 4,
        cost: 262000,
        bountyBoostUpgrade: 0.09,
      },
      {
        level: 5,
        cost: 400000,
        bountyBoostUpgrade: 0.12,
      },
      {
        level: 6,
        cost: 619000,
        bountyBoostUpgrade: 0.15,
      },
      {
        level: 7,
        cost: 1020000,
        bountyBoostUpgrade: 0.19,
      },
      {
        level: 8,
        cost: 1610000,
        bountyBoostUpgrade: 0.25,
      },
      {
        level: 9,
        cost: 2520000,
        bountyBoostUpgrade: 0.31,
      },
      {
        level: 10,
        cost: 3870000,
        bountyBoostUpgrade: 0.38,
      },
      {
        level: 11,
        cost: 5480000,
        bountyBoostUpgrade: 0.49,
      },
      {
        level: 12,
        cost: 8450000,
        bountyBoostUpgrade: 0.61,
      },
      {
        level: 13,
        cost: 13700000,
        bountyBoostUpgrade: 0.77,
      },
      {
        level: 14,
        cost: 22200000,
        bountyBoostUpgrade: 0.99,
      },
      {
        level: 15,
        cost: 35700000,
        bountyBoostUpgrade: 1.24,
      },
      {
        level: 16,
        cost: 55000000,
        bountyBoostUpgrade: 1.26,
      },
      {
        level: 17,
        cost: 84700000,
        bountyBoostUpgrade: 1.26,
      },
      {
        level: 18,
        cost: 127000000,
        bountyBoostUpgrade: 1.25,
      },
      {
        level: 19,
        cost: 206000000,
        bountyBoostUpgrade: 1.23,
      },
      {
        level: 20,
        cost: 299000000,
        bountyBoostUpgrade: 1.21,
      },
    ],
  },
  {
    name: "Vengeance Strike",
    itemId: "vengeance_strike",
    category: "Skills",
    subcategory: "Intermediate",
    description: "Deal massive damage when near death.",
    dependencies: [
      {
        itemId: "riposte_of_agony",
        level: 11,
      },
    ],
    upgrades: [
      {
        level: 1,
        cost: 93000,
        bountyBoostUpgrade: 0.09,
      },
      {
        level: 2,
        cost: 131000,
        bountyBoostUpgrade: 0.11,
      },
      {
        level: 3,
        cost: 191000,
        bountyBoostUpgrade: 0.14,
      },
      {
        level: 4,
        cost: 280000,
        bountyBoostUpgrade: 0.17,
      },
      {
        level: 5,
        cost: 389000,
        bountyBoostUpgrade: 0.22,
      },
      {
        level: 6,
        cost: 531000,
        bountyBoostUpgrade: 0.27,
      },
      {
        level: 7,
        cost: 747000,
        bountyBoostUpgrade: 0.34,
      },
      {
        level: 8,
        cost: 995000,
        bountyBoostUpgrade: 0.43,
      },
      {
        level: 9,
        cost: 1390000,
        bountyBoostUpgrade: 0.55,
      },
      {
        level: 10,
        cost: 2020000,
        bountyBoostUpgrade: 0.7,
      },
      {
        level: 11,
        cost: 2960000,
        bountyBoostUpgrade: 0.88,
      },
      {
        level: 12,
        cost: 4180000,
        bountyBoostUpgrade: 1.1,
      },
      {
        level: 13,
        cost: 6150000,
        bountyBoostUpgrade: 1.41,
      },
      {
        level: 14,
        cost: 9040000,
        bountyBoostUpgrade: 1.8,
      },
      {
        level: 15,
        cost: 13600000,
        bountyBoostUpgrade: 2.3,
      },
      {
        level: 16,
        cost: 19400000,
        bountyBoostUpgrade: 2.84,
      },
      {
        level: 17,
        cost: 28500000,
        bountyBoostUpgrade: 3.6,
      },
      {
        level: 18,
        cost: 43900000,
        bountyBoostUpgrade: 4.57,
      },
      {
        level: 19,
        cost: 69100000,
        bountyBoostUpgrade: 4.52,
      },
      {
        level: 20,
        cost: 93400000,
        bountyBoostUpgrade: 4.47,
      },
    ],
  },
  {
    name: "Disembowel",
    itemId: "disembowel",
    category: "Skills",
    subcategory: "Intermediate",
    description: "Violently disarm an opponent, possibly removing limbs.",
    dependencies: [
      {
        itemId: "brutalize",
        level: 12,
      },
    ],
    upgrades: [
      {
        level: 1,
        cost: 55000,
        bountyBoostUpgrade: 0.04,
      },
      {
        level: 2,
        cost: 75000,
        bountyBoostUpgrade: 0.05,
      },
      {
        level: 3,
        cost: 109000,
        bountyBoostUpgrade: 0.06,
      },
      {
        level: 4,
        cost: 161000,
        bountyBoostUpgrade: 0.08,
      },
      {
        level: 5,
        cost: 215000,
        bountyBoostUpgrade: 0.1,
      },
      {
        level: 6,
        cost: 331000,
        bountyBoostUpgrade: 0.13,
      },
      {
        level: 7,
        cost: 460000,
        bountyBoostUpgrade: 0.16,
      },
      {
        level: 8,
        cost: 651000,
        bountyBoostUpgrade: 0.2,
      },
      {
        level: 9,
        cost: 952000,
        bountyBoostUpgrade: 0.26,
      },
      {
        level: 10,
        cost: 1300000,
        bountyBoostUpgrade: 0.33,
      },
      {
        level: 11,
        cost: 1680000,
        bountyBoostUpgrade: 0.41,
      },
      {
        level: 12,
        cost: 2650000,
        bountyBoostUpgrade: 0.53,
      },
      {
        level: 13,
        cost: 3780000,
        bountyBoostUpgrade: 0.66,
      },
      {
        level: 14,
        cost: 5040000,
        bountyBoostUpgrade: 0.85,
      },
      {
        level: 15,
        cost: 6990000,
        bountyBoostUpgrade: 1.07,
      },
      {
        level: 16,
        cost: 9840000,
        bountyBoostUpgrade: 1.35,
      },
      {
        level: 17,
        cost: 13500000,
        bountyBoostUpgrade: 1.73,
      },
      {
        level: 18,
        cost: 18500000,
        bountyBoostUpgrade: 2.24,
      },
      {
        level: 19,
        cost: 26600000,
        bountyBoostUpgrade: 2.22,
      },
      {
        level: 20,
        cost: 36400000,
        bountyBoostUpgrade: 2.22,
      },
    ],
  },
  {
    name: "Bloodhound",
    itemId: "bloodhound",
    category: "Skills",
    subcategory: "Intermediate",
    description: "Track enemies by the scent of their fear and blood.",
    dependencies: [
      {
        itemId: "shadowstalk",
        level: 9,
      },
    ],
    upgrades: [
      {
        level: 1,
        cost: 54000,
        bountyBoostUpgrade: 0.07,
      },
      {
        level: 2,
        cost: 73000,
        bountyBoostUpgrade: 0.09,
      },
      {
        level: 3,
        cost: 95000,
        bountyBoostUpgrade: 0.11,
      },
      {
        level: 4,
        cost: 138000,
        bountyBoostUpgrade: 0.13,
      },
      {
        level: 5,
        cost: 202000,
        bountyBoostUpgrade: 0.16,
      },
      {
        level: 6,
        cost: 310000,
        bountyBoostUpgrade: 0.19,
      },
      {
        level: 7,
        cost: 449000,
        bountyBoostUpgrade: 0.23,
      },
      {
        level: 8,
        cost: 634000,
        bountyBoostUpgrade: 0.29,
      },
      {
        level: 9,
        cost: 934000,
        bountyBoostUpgrade: 0.35,
      },
      {
        level: 10,
        cost: 1360000,
        bountyBoostUpgrade: 0.44,
      },
      {
        level: 11,
        cost: 2000000,
        bountyBoostUpgrade: 0.54,
      },
      {
        level: 12,
        cost: 2950000,
        bountyBoostUpgrade: 0.65,
      },
      {
        level: 13,
        cost: 4030000,
        bountyBoostUpgrade: 0.81,
      },
      {
        level: 14,
        cost: 6130000,
        bountyBoostUpgrade: 1,
      },
      {
        level: 15,
        cost: 9150000,
        bountyBoostUpgrade: 1.23,
      },
      {
        level: 16,
        cost: 12300000,
        bountyBoostUpgrade: 1.49,
      },
      {
        level: 17,
        cost: 17400000,
        bountyBoostUpgrade: 1.85,
      },
      {
        level: 18,
        cost: 22400000,
        bountyBoostUpgrade: 2.23,
      },
      {
        level: 19,
        cost: 31900000,
        bountyBoostUpgrade: 2.77,
      },
      {
        level: 20,
        cost: 45000000,
        bountyBoostUpgrade: 2.81,
      },
    ],
  },
  {
    name: "Meatstorm",
    itemId: "meatstorm",
    category: "Skills",
    subcategory: "Advanced",
    description: "Create a whirlwind of flesh and bone, damaging all nearby.",
    dependencies: [
      {
        itemId: "twinblade_tornado",
        level: 14,
      },
    ],
    upgrades: [
      {
        level: 1,
        cost: 146000,
        bountyBoostUpgrade: 0.17,
      },
      {
        level: 2,
        cost: 199000,
        bountyBoostUpgrade: 0.22,
      },
      {
        level: 3,
        cost: 289000,
        bountyBoostUpgrade: 0.28,
      },
      {
        level: 4,
        cost: 373000,
        bountyBoostUpgrade: 0.36,
      },
      {
        level: 5,
        cost: 574000,
        bountyBoostUpgrade: 0.46,
      },
      {
        level: 6,
        cost: 817000,
        bountyBoostUpgrade: 0.59,
      },
      {
        level: 7,
        cost: 1160000,
        bountyBoostUpgrade: 0.75,
      },
      {
        level: 8,
        cost: 1580000,
        bountyBoostUpgrade: 0.94,
      },
      {
        level: 9,
        cost: 2130000,
        bountyBoostUpgrade: 1.21,
      },
      {
        level: 10,
        cost: 3130000,
        bountyBoostUpgrade: 1.55,
      },
      {
        level: 11,
        cost: 4480000,
        bountyBoostUpgrade: 1.96,
      },
      {
        level: 12,
        cost: 6540000,
        bountyBoostUpgrade: 2.49,
      },
      {
        level: 13,
        cost: 9040000,
        bountyBoostUpgrade: 3.16,
      },
      {
        level: 14,
        cost: 13900000,
        bountyBoostUpgrade: 3.93,
      },
      {
        level: 15,
        cost: 18600000,
        bountyBoostUpgrade: 5,
      },
      {
        level: 16,
        cost: 24700000,
        bountyBoostUpgrade: 5,
      },
      {
        level: 17,
        cost: 36600000,
        bountyBoostUpgrade: 5,
      },
      {
        level: 18,
        cost: 48200000,
        bountyBoostUpgrade: 5,
      },
      {
        level: 19,
        cost: 68600000,
        bountyBoostUpgrade: 5,
      },
      {
        level: 20,
        cost: 93500000,
        bountyBoostUpgrade: 5,
      },
    ],
  },
  {
    name: "Hellgate",
    itemId: "hellgate",
    category: "Skills",
    subcategory: "Advanced",
    description: "Open a portal to pull lesser demons into battle.",
    dependencies: [
      {
        itemId: "warcry_of_the_damned",
        level: 15,
      },
    ],
    upgrades: [
      {
        level: 1,
        cost: 89000,
        bountyBoostUpgrade: 0.19,
      },
      {
        level: 2,
        cost: 127000,
        bountyBoostUpgrade: 0.25,
      },
      {
        level: 3,
        cost: 189000,
        bountyBoostUpgrade: 0.33,
      },
      {
        level: 4,
        cost: 275000,
        bountyBoostUpgrade: 0.43,
      },
      {
        level: 5,
        cost: 352000,
        bountyBoostUpgrade: 0.57,
      },
      {
        level: 6,
        cost: 470000,
        bountyBoostUpgrade: 0.74,
      },
      {
        level: 7,
        cost: 612000,
        bountyBoostUpgrade: 0.97,
      },
      {
        level: 8,
        cost: 874000,
        bountyBoostUpgrade: 1.3,
      },
      {
        level: 9,
        cost: 1220000,
        bountyBoostUpgrade: 1.71,
      },
      {
        level: 10,
        cost: 1610000,
        bountyBoostUpgrade: 2.26,
      },
      {
        level: 11,
        cost: 2110000,
        bountyBoostUpgrade: 2.99,
      },
      {
        level: 12,
        cost: 3070000,
        bountyBoostUpgrade: 3.94,
      },
      {
        level: 13,
        cost: 4220000,
        bountyBoostUpgrade: 5,
      },
      {
        level: 14,
        cost: 5740000,
        bountyBoostUpgrade: 5,
      },
      {
        level: 15,
        cost: 8370000,
        bountyBoostUpgrade: 5,
      },
      {
        level: 16,
        cost: 10700000,
        bountyBoostUpgrade: 5,
      },
      {
        level: 17,
        cost: 16200000,
        bountyBoostUpgrade: 5,
      },
      {
        level: 18,
        cost: 22500000,
        bountyBoostUpgrade: 5,
      },
      {
        level: 19,
        cost: 30900000,
        bountyBoostUpgrade: 5,
      },
      {
        level: 20,
        cost: 47100000,
        bountyBoostUpgrade: 5,
      },
    ],
  },
  {
    name: "Shadowmeld",
    itemId: "shadowmeld",
    category: "Skills",
    subcategory: "Advanced",
    description:
      "Become one with the darkness, emerging to strike from any shadow.",
    dependencies: [
      {
        itemId: "bloodmist_dodge",
        level: 13,
      },
      {
        itemId: "bloodhound",
        level: 13,
      },
    ],
    upgrades: [
      {
        level: 1,
        cost: 128000,
        bountyBoostUpgrade: 0.06,
      },
      {
        level: 2,
        cost: 185000,
        bountyBoostUpgrade: 0.08,
      },
      {
        level: 3,
        cost: 262000,
        bountyBoostUpgrade: 0.1,
      },
      {
        level: 4,
        cost: 347000,
        bountyBoostUpgrade: 0.13,
      },
      {
        level: 5,
        cost: 439000,
        bountyBoostUpgrade: 0.16,
      },
      {
        level: 6,
        cost: 574000,
        bountyBoostUpgrade: 0.21,
      },
      {
        level: 7,
        cost: 807000,
        bountyBoostUpgrade: 0.26,
      },
      {
        level: 8,
        cost: 1090000,
        bountyBoostUpgrade: 0.34,
      },
      {
        level: 9,
        cost: 1480000,
        bountyBoostUpgrade: 0.44,
      },
      {
        level: 10,
        cost: 1930000,
        bountyBoostUpgrade: 0.56,
      },
      {
        level: 11,
        cost: 2480000,
        bountyBoostUpgrade: 0.71,
      },
      {
        level: 12,
        cost: 3430000,
        bountyBoostUpgrade: 0.92,
      },
      {
        level: 13,
        cost: 4940000,
        bountyBoostUpgrade: 1.16,
      },
      {
        level: 14,
        cost: 6950000,
        bountyBoostUpgrade: 1.49,
      },
      {
        level: 15,
        cost: 9080000,
        bountyBoostUpgrade: 1.92,
      },
      {
        level: 16,
        cost: 13500000,
        bountyBoostUpgrade: 2.46,
      },
      {
        level: 17,
        cost: 17400000,
        bountyBoostUpgrade: 2.47,
      },
      {
        level: 18,
        cost: 24000000,
        bountyBoostUpgrade: 2.44,
      },
      {
        level: 19,
        cost: 35400000,
        bountyBoostUpgrade: 2.37,
      },
      {
        level: 20,
        cost: 51600000,
        bountyBoostUpgrade: 2.33,
      },
    ],
  },
  {
    name: "Death From Above",
    itemId: "death_from_above",
    category: "Skills",
    subcategory: "Advanced",
    description: "Launch into the air and rain down arrows of pure malice.",
    dependencies: [
      {
        itemId: "arrowstorm",
        level: 16,
      },
    ],
    upgrades: [
      {
        level: 1,
        cost: 126000,
        bountyBoostUpgrade: 0.07,
      },
      {
        level: 2,
        cost: 165000,
        bountyBoostUpgrade: 0.09,
      },
      {
        level: 3,
        cost: 219000,
        bountyBoostUpgrade: 0.1,
      },
      {
        level: 4,
        cost: 307000,
        bountyBoostUpgrade: 0.13,
      },
      {
        level: 5,
        cost: 429000,
        bountyBoostUpgrade: 0.16,
      },
      {
        level: 6,
        cost: 598000,
        bountyBoostUpgrade: 0.2,
      },
      {
        level: 7,
        cost: 770000,
        bountyBoostUpgrade: 0.25,
      },
      {
        level: 8,
        cost: 1060000,
        bountyBoostUpgrade: 0.3,
      },
      {
        level: 9,
        cost: 1430000,
        bountyBoostUpgrade: 0.37,
      },
      {
        level: 10,
        cost: 2020000,
        bountyBoostUpgrade: 0.45,
      },
      {
        level: 11,
        cost: 2760000,
        bountyBoostUpgrade: 0.55,
      },
      {
        level: 12,
        cost: 3760000,
        bountyBoostUpgrade: 0.68,
      },
      {
        level: 13,
        cost: 5340000,
        bountyBoostUpgrade: 0.82,
      },
      {
        level: 14,
        cost: 8040000,
        bountyBoostUpgrade: 0.99,
      },
      {
        level: 15,
        cost: 11000000,
        bountyBoostUpgrade: 1.22,
      },
      {
        level: 16,
        cost: 15500000,
        bountyBoostUpgrade: 1.2,
      },
      {
        level: 17,
        cost: 22100000,
        bountyBoostUpgrade: 1.22,
      },
      {
        level: 18,
        cost: 32000000,
        bountyBoostUpgrade: 1.18,
      },
      {
        level: 19,
        cost: 41600000,
        bountyBoostUpgrade: 1.13,
      },
      {
        level: 20,
        cost: 56000000,
        bountyBoostUpgrade: 1.11,
      },
    ],
  },
  {
    name: "Bloodfrenzy",
    itemId: "bloodfrenzy",
    category: "Skills",
    subcategory: "Advanced",
    description: "Enter a state of unstoppable rage, ignoring fatal wounds.",
    dependencies: [
      {
        itemId: "vengeance_strike",
        level: 15,
      },
    ],
    upgrades: [
      {
        level: 1,
        cost: 135000,
        bountyBoostUpgrade: 0.06,
      },
      {
        level: 2,
        cost: 181000,
        bountyBoostUpgrade: 0.07,
      },
      {
        level: 3,
        cost: 221000,
        bountyBoostUpgrade: 0.09,
      },
      {
        level: 4,
        cost: 288000,
        bountyBoostUpgrade: 0.12,
      },
      {
        level: 5,
        cost: 391000,
        bountyBoostUpgrade: 0.15,
      },
      {
        level: 6,
        cost: 531000,
        bountyBoostUpgrade: 0.19,
      },
      {
        level: 7,
        cost: 679000,
        bountyBoostUpgrade: 0.23,
      },
      {
        level: 8,
        cost: 957000,
        bountyBoostUpgrade: 0.3,
      },
      {
        level: 9,
        cost: 1210000,
        bountyBoostUpgrade: 0.38,
      },
      {
        level: 10,
        cost: 1610000,
        bountyBoostUpgrade: 0.48,
      },
      {
        level: 11,
        cost: 2130000,
        bountyBoostUpgrade: 0.6,
      },
      {
        level: 12,
        cost: 2840000,
        bountyBoostUpgrade: 0.76,
      },
      {
        level: 13,
        cost: 3840000,
        bountyBoostUpgrade: 0.96,
      },
      {
        level: 14,
        cost: 5390000,
        bountyBoostUpgrade: 1.22,
      },
      {
        level: 15,
        cost: 7110000,
        bountyBoostUpgrade: 1.57,
      },
      {
        level: 16,
        cost: 9990000,
        bountyBoostUpgrade: 1.97,
      },
      {
        level: 17,
        cost: 13300000,
        bountyBoostUpgrade: 2.51,
      },
      {
        level: 18,
        cost: 18000000,
        bountyBoostUpgrade: 3.16,
      },
      {
        level: 19,
        cost: 23400000,
        bountyBoostUpgrade: 4.05,
      },
      {
        level: 20,
        cost: 31500000,
        bountyBoostUpgrade: 4.11,
      },
    ],
  },
  {
    name: "Chain of Torment",
    itemId: "chain_of_torment",
    category: "Skills",
    subcategory: "Advanced",
    description: "Bind enemies together, sharing the pain of your attacks.",
    dependencies: [
      {
        itemId: "disembowel",
        level: 14,
      },
    ],
    upgrades: [
      {
        level: 1,
        cost: 116000,
        bountyBoostUpgrade: 0.06,
      },
      {
        level: 2,
        cost: 156000,
        bountyBoostUpgrade: 0.08,
      },
      {
        level: 3,
        cost: 201000,
        bountyBoostUpgrade: 0.1,
      },
      {
        level: 4,
        cost: 278000,
        bountyBoostUpgrade: 0.13,
      },
      {
        level: 5,
        cost: 389000,
        bountyBoostUpgrade: 0.17,
      },
      {
        level: 6,
        cost: 489000,
        bountyBoostUpgrade: 0.22,
      },
      {
        level: 7,
        cost: 689000,
        bountyBoostUpgrade: 0.28,
      },
      {
        level: 8,
        cost: 922000,
        bountyBoostUpgrade: 0.36,
      },
      {
        level: 9,
        cost: 1230000,
        bountyBoostUpgrade: 0.47,
      },
      {
        level: 10,
        cost: 1590000,
        bountyBoostUpgrade: 0.6,
      },
      {
        level: 11,
        cost: 2080000,
        bountyBoostUpgrade: 0.77,
      },
      {
        level: 12,
        cost: 2620000,
        bountyBoostUpgrade: 1.01,
      },
      {
        level: 13,
        cost: 3640000,
        bountyBoostUpgrade: 1.29,
      },
      {
        level: 14,
        cost: 4880000,
        bountyBoostUpgrade: 1.69,
      },
      {
        level: 15,
        cost: 6960000,
        bountyBoostUpgrade: 2.2,
      },
      {
        level: 16,
        cost: 9460000,
        bountyBoostUpgrade: 2.22,
      },
      {
        level: 17,
        cost: 13100000,
        bountyBoostUpgrade: 2.21,
      },
      {
        level: 18,
        cost: 18700000,
        bountyBoostUpgrade: 2.19,
      },
      {
        level: 19,
        cost: 26100000,
        bountyBoostUpgrade: 2.18,
      },
      {
        level: 20,
        cost: 33700000,
        bountyBoostUpgrade: 2.13,
      },
    ],
  },
  {
    name: "Temporal Slaughter",
    itemId: "temporal_slaughter",
    category: "Skills",
    subcategory: "Advanced",
    description: "Slow time to unleash a flurry of fatal blows.",
    dependencies: [
      {
        itemId: "crushing_blow",
        level: 15,
      },
      {
        itemId: "eviscerate",
        level: 15,
      },
    ],
    upgrades: [
      {
        level: 1,
        cost: 54000,
        bountyBoostUpgrade: 0.06,
      },
      {
        level: 2,
        cost: 77000,
        bountyBoostUpgrade: 0.07,
      },
      {
        level: 3,
        cost: 106000,
        bountyBoostUpgrade: 0.1,
      },
      {
        level: 4,
        cost: 134000,
        bountyBoostUpgrade: 0.13,
      },
      {
        level: 5,
        cost: 188000,
        bountyBoostUpgrade: 0.17,
      },
      {
        level: 6,
        cost: 247000,
        bountyBoostUpgrade: 0.22,
      },
      {
        level: 7,
        cost: 343000,
        bountyBoostUpgrade: 0.29,
      },
      {
        level: 8,
        cost: 449000,
        bountyBoostUpgrade: 0.39,
      },
      {
        level: 9,
        cost: 590000,
        bountyBoostUpgrade: 0.52,
      },
      {
        level: 10,
        cost: 792000,
        bountyBoostUpgrade: 0.69,
      },
      {
        level: 11,
        cost: 1070000,
        bountyBoostUpgrade: 0.92,
      },
      {
        level: 12,
        cost: 1550000,
        bountyBoostUpgrade: 1.22,
      },
      {
        level: 13,
        cost: 2150000,
        bountyBoostUpgrade: 1.57,
      },
      {
        level: 14,
        cost: 2960000,
        bountyBoostUpgrade: 2.03,
      },
      {
        level: 15,
        cost: 3910000,
        bountyBoostUpgrade: 2.65,
      },
      {
        level: 16,
        cost: 5440000,
        bountyBoostUpgrade: 3.41,
      },
      {
        level: 17,
        cost: 6490000,
        bountyBoostUpgrade: 4.56,
      },
      {
        level: 18,
        cost: 8370000,
        bountyBoostUpgrade: 5,
      },
      {
        level: 19,
        cost: 10700000,
        bountyBoostUpgrade: 5,
      },
      {
        level: 20,
        cost: 13600000,
        bountyBoostUpgrade: 5,
      },
    ],
  },
  {
    name: "Breath of Plague",
    itemId: "breath_of_plague",
    category: "Skills",
    subcategory: "Advanced",
    description: "Exhale a miasma that inflicts horrific diseases.",
    dependencies: [
      {
        itemId: "tower_of_pain",
        level: 14,
      },
    ],
    upgrades: [
      {
        level: 1,
        cost: 108000,
        bountyBoostUpgrade: 0.2,
      },
      {
        level: 2,
        cost: 132000,
        bountyBoostUpgrade: 0.26,
      },
      {
        level: 3,
        cost: 166000,
        bountyBoostUpgrade: 0.34,
      },
      {
        level: 4,
        cost: 215000,
        bountyBoostUpgrade: 0.45,
      },
      {
        level: 5,
        cost: 278000,
        bountyBoostUpgrade: 0.58,
      },
      {
        level: 6,
        cost: 357000,
        bountyBoostUpgrade: 0.77,
      },
      {
        level: 7,
        cost: 504000,
        bountyBoostUpgrade: 0.99,
      },
      {
        level: 8,
        cost: 628000,
        bountyBoostUpgrade: 1.3,
      },
      {
        level: 9,
        cost: 812000,
        bountyBoostUpgrade: 1.68,
      },
      {
        level: 10,
        cost: 1100000,
        bountyBoostUpgrade: 2.19,
      },
      {
        level: 11,
        cost: 1410000,
        bountyBoostUpgrade: 2.9,
      },
      {
        level: 12,
        cost: 2050000,
        bountyBoostUpgrade: 3.76,
      },
      {
        level: 13,
        cost: 2500000,
        bountyBoostUpgrade: 4.99,
      },
      {
        level: 14,
        cost: 3140000,
        bountyBoostUpgrade: 5,
      },
      {
        level: 15,
        cost: 4340000,
        bountyBoostUpgrade: 5,
      },
      {
        level: 16,
        cost: 5870000,
        bountyBoostUpgrade: 5,
      },
      {
        level: 17,
        cost: 8000000,
        bountyBoostUpgrade: 5,
      },
      {
        level: 18,
        cost: 10400000,
        bountyBoostUpgrade: 5,
      },
      {
        level: 19,
        cost: 13300000,
        bountyBoostUpgrade: 5,
      },
      {
        level: 20,
        cost: 15600000,
        bountyBoostUpgrade: 5,
      },
    ],
  },
  {
    name: "Mindshatter",
    itemId: "mindshatter",
    category: "Skills",
    subcategory: "Advanced",
    description: "Psychically dominate a foe, turning them into a puppet.",
    dependencies: [
      {
        itemId: "warcry_of_the_damned",
        level: 16,
      },
    ],
    upgrades: [
      {
        level: 1,
        cost: 136000,
        bountyBoostUpgrade: 0.05,
      },
      {
        level: 2,
        cost: 164000,
        bountyBoostUpgrade: 0.07,
      },
      {
        level: 3,
        cost: 222000,
        bountyBoostUpgrade: 0.09,
      },
      {
        level: 4,
        cost: 296000,
        bountyBoostUpgrade: 0.12,
      },
      {
        level: 5,
        cost: 408000,
        bountyBoostUpgrade: 0.15,
      },
      {
        level: 6,
        cost: 529000,
        bountyBoostUpgrade: 0.19,
      },
      {
        level: 7,
        cost: 718000,
        bountyBoostUpgrade: 0.25,
      },
      {
        level: 8,
        cost: 884000,
        bountyBoostUpgrade: 0.33,
      },
      {
        level: 9,
        cost: 1230000,
        bountyBoostUpgrade: 0.43,
      },
      {
        level: 10,
        cost: 1610000,
        bountyBoostUpgrade: 0.55,
      },
      {
        level: 11,
        cost: 2360000,
        bountyBoostUpgrade: 0.71,
      },
      {
        level: 12,
        cost: 3080000,
        bountyBoostUpgrade: 0.93,
      },
      {
        level: 13,
        cost: 4110000,
        bountyBoostUpgrade: 1.19,
      },
      {
        level: 14,
        cost: 5900000,
        bountyBoostUpgrade: 1.53,
      },
      {
        level: 15,
        cost: 7570000,
        bountyBoostUpgrade: 1.98,
      },
      {
        level: 16,
        cost: 10600000,
        bountyBoostUpgrade: 2.59,
      },
      {
        level: 17,
        cost: 14400000,
        bountyBoostUpgrade: 3.35,
      },
      {
        level: 18,
        cost: 19400000,
        bountyBoostUpgrade: 3.39,
      },
      {
        level: 19,
        cost: 25600000,
        bountyBoostUpgrade: 3.43,
      },
      {
        level: 20,
        cost: 32800000,
        bountyBoostUpgrade: 3.37,
      },
    ],
  },
  {
    name: "Harbinger of Doom",
    itemId: "harbinger_of_doom",
    category: "Skills",
    subcategory: "Advanced",
    description:
      "Transform into an avatar of death, draining life and spreading terror.",
    dependencies: [
      {
        itemId: "bloodfrenzy",
        level: 17,
      },
    ],
    upgrades: [
      {
        level: 1,
        cost: 62000,
        bountyBoostUpgrade: 0.11,
      },
      {
        level: 2,
        cost: 83000,
        bountyBoostUpgrade: 0.14,
      },
      {
        level: 3,
        cost: 116000,
        bountyBoostUpgrade: 0.18,
      },
      {
        level: 4,
        cost: 149000,
        bountyBoostUpgrade: 0.24,
      },
      {
        level: 5,
        cost: 201000,
        bountyBoostUpgrade: 0.32,
      },
      {
        level: 6,
        cost: 283000,
        bountyBoostUpgrade: 0.41,
      },
      {
        level: 7,
        cost: 373000,
        bountyBoostUpgrade: 0.53,
      },
      {
        level: 8,
        cost: 460000,
        bountyBoostUpgrade: 0.69,
      },
      {
        level: 9,
        cost: 661000,
        bountyBoostUpgrade: 0.9,
      },
      {
        level: 10,
        cost: 936000,
        bountyBoostUpgrade: 1.17,
      },
      {
        level: 11,
        cost: 1240000,
        bountyBoostUpgrade: 1.54,
      },
      {
        level: 12,
        cost: 1610000,
        bountyBoostUpgrade: 2.02,
      },
      {
        level: 13,
        cost: 2000000,
        bountyBoostUpgrade: 2.66,
      },
      {
        level: 14,
        cost: 2680000,
        bountyBoostUpgrade: 3.43,
      },
      {
        level: 15,
        cost: 3270000,
        bountyBoostUpgrade: 4.39,
      },
      {
        level: 16,
        cost: 4480000,
        bountyBoostUpgrade: 5,
      },
      {
        level: 17,
        cost: 5420000,
        bountyBoostUpgrade: 5,
      },
      {
        level: 18,
        cost: 6780000,
        bountyBoostUpgrade: 5,
      },
      {
        level: 19,
        cost: 9130000,
        bountyBoostUpgrade: 5,
      },
      {
        level: 20,
        cost: 12800000,
        bountyBoostUpgrade: 5,
      },
    ],
  },
  {
    name: "Blood Vial",
    itemId: "blood_vial",
    category: "Consumables",
    subcategory: "Basic",
    description: "Restores health but increases bloodlust temporarily.",
    dependencies: [],
    upgrades: [
      {
        level: 1,
        cost: 51000,
        bountyBoostUpgrade: 0.02,
      },
      {
        level: 2,
        cost: 85000,
        bountyBoostUpgrade: 0.03,
      },
      {
        level: 3,
        cost: 135000,
        bountyBoostUpgrade: 0.03,
      },
      {
        level: 4,
        cost: 222000,
        bountyBoostUpgrade: 0.04,
      },
      {
        level: 5,
        cost: 360000,
        bountyBoostUpgrade: 0.05,
      },
      {
        level: 6,
        cost: 613000,
        bountyBoostUpgrade: 0.06,
      },
      {
        level: 7,
        cost: 991000,
        bountyBoostUpgrade: 0.07,
      },
      {
        level: 8,
        cost: 1600000,
        bountyBoostUpgrade: 0.09,
      },
      {
        level: 9,
        cost: 2480000,
        bountyBoostUpgrade: 0.11,
      },
      {
        level: 10,
        cost: 4020000,
        bountyBoostUpgrade: 0.13,
      },
      {
        level: 11,
        cost: 6180000,
        bountyBoostUpgrade: 0.16,
      },
      {
        level: 12,
        cost: 10200000,
        bountyBoostUpgrade: 0.19,
      },
      {
        level: 13,
        cost: 17500000,
        bountyBoostUpgrade: 0.23,
      },
      {
        level: 14,
        cost: 29300000,
        bountyBoostUpgrade: 0.28,
      },
      {
        level: 15,
        cost: 43900000,
        bountyBoostUpgrade: 0.34,
      },
      {
        level: 16,
        cost: 70700000,
        bountyBoostUpgrade: 0.41,
      },
      {
        level: 17,
        cost: 111000000,
        bountyBoostUpgrade: 0.49,
      },
      {
        level: 18,
        cost: 175000000,
        bountyBoostUpgrade: 0.49,
      },
      {
        level: 19,
        cost: 277000000,
        bountyBoostUpgrade: 0.5,
      },
      {
        level: 20,
        cost: 411000000,
        bountyBoostUpgrade: 0.5,
      },
    ],
  },
  {
    name: "Cannibal's Jerky",
    itemId: "cannibals_jerky",
    category: "Consumables",
    subcategory: "Basic",
    description:
      "Dried human flesh that provides sustenance and minor healing.",
    dependencies: [],
    upgrades: [
      {
        level: 1,
        cost: 44000,
        bountyBoostUpgrade: 0.03,
      },
      {
        level: 2,
        cost: 71000,
        bountyBoostUpgrade: 0.04,
      },
      {
        level: 3,
        cost: 114000,
        bountyBoostUpgrade: 0.04,
      },
      {
        level: 4,
        cost: 186000,
        bountyBoostUpgrade: 0.05,
      },
      {
        level: 5,
        cost: 282000,
        bountyBoostUpgrade: 0.06,
      },
      {
        level: 6,
        cost: 471000,
        bountyBoostUpgrade: 0.07,
      },
      {
        level: 7,
        cost: 685000,
        bountyBoostUpgrade: 0.08,
      },
      {
        level: 8,
        cost: 935000,
        bountyBoostUpgrade: 0.09,
      },
      {
        level: 9,
        cost: 1290000,
        bountyBoostUpgrade: 0.11,
      },
      {
        level: 10,
        cost: 1860000,
        bountyBoostUpgrade: 0.13,
      },
      {
        level: 11,
        cost: 2820000,
        bountyBoostUpgrade: 0.15,
      },
      {
        level: 12,
        cost: 4070000,
        bountyBoostUpgrade: 0.17,
      },
      {
        level: 13,
        cost: 6590000,
        bountyBoostUpgrade: 0.2,
      },
      {
        level: 14,
        cost: 9480000,
        bountyBoostUpgrade: 0.23,
      },
      {
        level: 15,
        cost: 14200000,
        bountyBoostUpgrade: 0.27,
      },
      {
        level: 16,
        cost: 23200000,
        bountyBoostUpgrade: 0.31,
      },
      {
        level: 17,
        cost: 34200000,
        bountyBoostUpgrade: 0.37,
      },
      {
        level: 18,
        cost: 50500000,
        bountyBoostUpgrade: 0.44,
      },
      {
        level: 19,
        cost: 78000000,
        bountyBoostUpgrade: 0.43,
      },
      {
        level: 20,
        cost: 108000000,
        bountyBoostUpgrade: 0.43,
      },
    ],
  },
  {
    name: "Painkiller Herbs",
    itemId: "painkiller_herbs",
    category: "Consumables",
    subcategory: "Basic",
    description: "Numbs pain and allows fighting beyond normal limits.",
    dependencies: [],
    upgrades: [
      {
        level: 1,
        cost: 53000,
        bountyBoostUpgrade: 0.02,
      },
      {
        level: 2,
        cost: 91000,
        bountyBoostUpgrade: 0.02,
      },
      {
        level: 3,
        cost: 163000,
        bountyBoostUpgrade: 0.03,
      },
      {
        level: 4,
        cost: 293000,
        bountyBoostUpgrade: 0.03,
      },
      {
        level: 5,
        cost: 461000,
        bountyBoostUpgrade: 0.04,
      },
      {
        level: 6,
        cost: 799000,
        bountyBoostUpgrade: 0.05,
      },
      {
        level: 7,
        cost: 1330000,
        bountyBoostUpgrade: 0.06,
      },
      {
        level: 8,
        cost: 2130000,
        bountyBoostUpgrade: 0.07,
      },
      {
        level: 9,
        cost: 3410000,
        bountyBoostUpgrade: 0.08,
      },
      {
        level: 10,
        cost: 5760000,
        bountyBoostUpgrade: 0.1,
      },
      {
        level: 11,
        cost: 10600000,
        bountyBoostUpgrade: 0.12,
      },
      {
        level: 12,
        cost: 18100000,
        bountyBoostUpgrade: 0.14,
      },
      {
        level: 13,
        cost: 31700000,
        bountyBoostUpgrade: 0.16,
      },
      {
        level: 14,
        cost: 58100000,
        bountyBoostUpgrade: 0.19,
      },
      {
        level: 15,
        cost: 88900000,
        bountyBoostUpgrade: 0.23,
      },
      {
        level: 16,
        cost: 145000000,
        bountyBoostUpgrade: 0.28,
      },
      {
        level: 17,
        cost: 254000000,
        bountyBoostUpgrade: 0.33,
      },
      {
        level: 18,
        cost: 409000000,
        bountyBoostUpgrade: 0.39,
      },
      {
        level: 19,
        cost: 688000000,
        bountyBoostUpgrade: 0.39,
      },
      {
        level: 20,
        cost: 1120000000,
        bountyBoostUpgrade: 0.38,
      },
    ],
  },
  {
    name: "Liquid Courage",
    itemId: "liquid_courage",
    category: "Consumables",
    subcategory: "Basic",
    description: "An alcoholic brew that dulls fear and pain.",
    dependencies: [],
    upgrades: [
      {
        level: 1,
        cost: 82000,
        bountyBoostUpgrade: 0.03,
      },
      {
        level: 2,
        cost: 131000,
        bountyBoostUpgrade: 0.04,
      },
      {
        level: 3,
        cost: 194000,
        bountyBoostUpgrade: 0.04,
      },
      {
        level: 4,
        cost: 281000,
        bountyBoostUpgrade: 0.05,
      },
      {
        level: 5,
        cost: 464000,
        bountyBoostUpgrade: 0.06,
      },
      {
        level: 6,
        cost: 728000,
        bountyBoostUpgrade: 0.07,
      },
      {
        level: 7,
        cost: 1090000,
        bountyBoostUpgrade: 0.08,
      },
      {
        level: 8,
        cost: 1730000,
        bountyBoostUpgrade: 0.09,
      },
      {
        level: 9,
        cost: 2700000,
        bountyBoostUpgrade: 0.1,
      },
      {
        level: 10,
        cost: 4310000,
        bountyBoostUpgrade: 0.12,
      },
      {
        level: 11,
        cost: 6730000,
        bountyBoostUpgrade: 0.14,
      },
      {
        level: 12,
        cost: 9510000,
        bountyBoostUpgrade: 0.16,
      },
      {
        level: 13,
        cost: 13700000,
        bountyBoostUpgrade: 0.2,
      },
      {
        level: 14,
        cost: 20900000,
        bountyBoostUpgrade: 0.23,
      },
      {
        level: 15,
        cost: 35900000,
        bountyBoostUpgrade: 0.26,
      },
      {
        level: 16,
        cost: 53200000,
        bountyBoostUpgrade: 0.3,
      },
      {
        level: 17,
        cost: 81400000,
        bountyBoostUpgrade: 0.31,
      },
      {
        level: 18,
        cost: 116000000,
        bountyBoostUpgrade: 0.31,
      },
      {
        level: 19,
        cost: 160000000,
        bountyBoostUpgrade: 0.3,
      },
      {
        level: 20,
        cost: 237000000,
        bountyBoostUpgrade: 0.29,
      },
    ],
  },
  {
    name: "Blazing Pitch",
    itemId: "blazing_pitch",
    category: "Consumables",
    subcategory: "Basic",
    description: "Creates patches of burning ground when thrown.",
    dependencies: [],
    upgrades: [
      {
        level: 1,
        cost: 89000,
        bountyBoostUpgrade: 0.04,
      },
      {
        level: 2,
        cost: 138000,
        bountyBoostUpgrade: 0.04,
      },
      {
        level: 3,
        cost: 211000,
        bountyBoostUpgrade: 0.05,
      },
      {
        level: 4,
        cost: 295000,
        bountyBoostUpgrade: 0.06,
      },
      {
        level: 5,
        cost: 448000,
        bountyBoostUpgrade: 0.08,
      },
      {
        level: 6,
        cost: 670000,
        bountyBoostUpgrade: 0.1,
      },
      {
        level: 7,
        cost: 998000,
        bountyBoostUpgrade: 0.12,
      },
      {
        level: 8,
        cost: 1530000,
        bountyBoostUpgrade: 0.15,
      },
      {
        level: 9,
        cost: 2260000,
        bountyBoostUpgrade: 0.19,
      },
      {
        level: 10,
        cost: 3050000,
        bountyBoostUpgrade: 0.24,
      },
      {
        level: 11,
        cost: 4620000,
        bountyBoostUpgrade: 0.29,
      },
      {
        level: 12,
        cost: 7020000,
        bountyBoostUpgrade: 0.35,
      },
      {
        level: 13,
        cost: 10700000,
        bountyBoostUpgrade: 0.42,
      },
      {
        level: 14,
        cost: 17000000,
        bountyBoostUpgrade: 0.52,
      },
      {
        level: 15,
        cost: 23000000,
        bountyBoostUpgrade: 0.64,
      },
      {
        level: 16,
        cost: 35500000,
        bountyBoostUpgrade: 0.79,
      },
      {
        level: 17,
        cost: 53000000,
        bountyBoostUpgrade: 0.99,
      },
      {
        level: 18,
        cost: 76800000,
        bountyBoostUpgrade: 0.98,
      },
      {
        level: 19,
        cost: 107000000,
        bountyBoostUpgrade: 0.96,
      },
      {
        level: 20,
        cost: 143000000,
        bountyBoostUpgrade: 0.96,
      },
    ],
  },
  {
    name: "Barbed Wire",
    itemId: "barbed_wire",
    category: "Consumables",
    subcategory: "Basic",
    description: "Sets up painful, movement-restricting barriers.",
    dependencies: [],
    upgrades: [
      {
        level: 1,
        cost: 46000,
        bountyBoostUpgrade: 0.02,
      },
      {
        level: 2,
        cost: 77000,
        bountyBoostUpgrade: 0.02,
      },
      {
        level: 3,
        cost: 124000,
        bountyBoostUpgrade: 0.03,
      },
      {
        level: 4,
        cost: 208000,
        bountyBoostUpgrade: 0.03,
      },
      {
        level: 5,
        cost: 374000,
        bountyBoostUpgrade: 0.04,
      },
      {
        level: 6,
        cost: 603000,
        bountyBoostUpgrade: 0.05,
      },
      {
        level: 7,
        cost: 1070000,
        bountyBoostUpgrade: 0.06,
      },
      {
        level: 8,
        cost: 1710000,
        bountyBoostUpgrade: 0.07,
      },
      {
        level: 9,
        cost: 2780000,
        bountyBoostUpgrade: 0.08,
      },
      {
        level: 10,
        cost: 4510000,
        bountyBoostUpgrade: 0.1,
      },
      {
        level: 11,
        cost: 7690000,
        bountyBoostUpgrade: 0.12,
      },
      {
        level: 12,
        cost: 13300000,
        bountyBoostUpgrade: 0.14,
      },
      {
        level: 13,
        cost: 23000000,
        bountyBoostUpgrade: 0.16,
      },
      {
        level: 14,
        cost: 38800000,
        bountyBoostUpgrade: 0.19,
      },
      {
        level: 15,
        cost: 60400000,
        bountyBoostUpgrade: 0.23,
      },
      {
        level: 16,
        cost: 106000000,
        bountyBoostUpgrade: 0.23,
      },
      {
        level: 17,
        cost: 179000000,
        bountyBoostUpgrade: 0.22,
      },
      {
        level: 18,
        cost: 298000000,
        bountyBoostUpgrade: 0.22,
      },
      {
        level: 19,
        cost: 500000000,
        bountyBoostUpgrade: 0.21,
      },
      {
        level: 20,
        cost: 822000000,
        bountyBoostUpgrade: 0.21,
      },
    ],
  },
  {
    name: "Venom Sac",
    itemId: "venom_sac",
    category: "Consumables",
    subcategory: "Basic",
    description: "Applies a fast-acting poison to weapons.",
    dependencies: [],
    upgrades: [
      {
        level: 1,
        cost: 46000,
        bountyBoostUpgrade: 0.03,
      },
      {
        level: 2,
        cost: 69000,
        bountyBoostUpgrade: 0.04,
      },
      {
        level: 3,
        cost: 100000,
        bountyBoostUpgrade: 0.05,
      },
      {
        level: 4,
        cost: 135000,
        bountyBoostUpgrade: 0.06,
      },
      {
        level: 5,
        cost: 200000,
        bountyBoostUpgrade: 0.07,
      },
      {
        level: 6,
        cost: 305000,
        bountyBoostUpgrade: 0.09,
      },
      {
        level: 7,
        cost: 415000,
        bountyBoostUpgrade: 0.11,
      },
      {
        level: 8,
        cost: 586000,
        bountyBoostUpgrade: 0.14,
      },
      {
        level: 9,
        cost: 840000,
        bountyBoostUpgrade: 0.17,
      },
      {
        level: 10,
        cost: 1280000,
        bountyBoostUpgrade: 0.2,
      },
      {
        level: 11,
        cost: 1960000,
        bountyBoostUpgrade: 0.25,
      },
      {
        level: 12,
        cost: 2860000,
        bountyBoostUpgrade: 0.3,
      },
      {
        level: 13,
        cost: 4140000,
        bountyBoostUpgrade: 0.37,
      },
      {
        level: 14,
        cost: 6580000,
        bountyBoostUpgrade: 0.44,
      },
      {
        level: 15,
        cost: 10300000,
        bountyBoostUpgrade: 0.54,
      },
      {
        level: 16,
        cost: 14800000,
        bountyBoostUpgrade: 0.65,
      },
      {
        level: 17,
        cost: 21400000,
        bountyBoostUpgrade: 0.79,
      },
      {
        level: 18,
        cost: 31800000,
        bountyBoostUpgrade: 0.98,
      },
      {
        level: 19,
        cost: 46000000,
        bountyBoostUpgrade: 0.97,
      },
      {
        level: 20,
        cost: 65600000,
        bountyBoostUpgrade: 0.95,
      },
    ],
  },
  {
    name: "Bone Dust",
    itemId: "bone_dust",
    category: "Consumables",
    subcategory: "Basic",
    description: "Temporarily hardens weapons, increasing damage.",
    dependencies: [],
    upgrades: [
      {
        level: 1,
        cost: 37000,
        bountyBoostUpgrade: 0.05,
      },
      {
        level: 2,
        cost: 64000,
        bountyBoostUpgrade: 0.05,
      },
      {
        level: 3,
        cost: 112000,
        bountyBoostUpgrade: 0.07,
      },
      {
        level: 4,
        cost: 175000,
        bountyBoostUpgrade: 0.08,
      },
      {
        level: 5,
        cost: 284000,
        bountyBoostUpgrade: 0.09,
      },
      {
        level: 6,
        cost: 447000,
        bountyBoostUpgrade: 0.11,
      },
      {
        level: 7,
        cost: 710000,
        bountyBoostUpgrade: 0.13,
      },
      {
        level: 8,
        cost: 1190000,
        bountyBoostUpgrade: 0.16,
      },
      {
        level: 9,
        cost: 1850000,
        bountyBoostUpgrade: 0.18,
      },
      {
        level: 10,
        cost: 2800000,
        bountyBoostUpgrade: 0.22,
      },
      {
        level: 11,
        cost: 4980000,
        bountyBoostUpgrade: 0.26,
      },
      {
        level: 12,
        cost: 9010000,
        bountyBoostUpgrade: 0.3,
      },
      {
        level: 13,
        cost: 16000000,
        bountyBoostUpgrade: 0.36,
      },
      {
        level: 14,
        cost: 25700000,
        bountyBoostUpgrade: 0.44,
      },
      {
        level: 15,
        cost: 39200000,
        bountyBoostUpgrade: 0.52,
      },
      {
        level: 16,
        cost: 70200000,
        bountyBoostUpgrade: 0.61,
      },
      {
        level: 17,
        cost: 115000000,
        bountyBoostUpgrade: 0.6,
      },
      {
        level: 18,
        cost: 175000000,
        bountyBoostUpgrade: 0.59,
      },
      {
        level: 19,
        cost: 276000000,
        bountyBoostUpgrade: 0.57,
      },
      {
        level: 20,
        cost: 506000000,
        bountyBoostUpgrade: 0.55,
      },
    ],
  },
  {
    name: "Cruel Caltrops",
    itemId: "cruel_caltrops",
    category: "Consumables",
    subcategory: "Basic",
    description: "Spreads sharp spikes that damage and slow enemies.",
    dependencies: [],
    upgrades: [
      {
        level: 1,
        cost: 42000,
        bountyBoostUpgrade: 0.04,
      },
      {
        level: 2,
        cost: 72000,
        bountyBoostUpgrade: 0.05,
      },
      {
        level: 3,
        cost: 117000,
        bountyBoostUpgrade: 0.06,
      },
      {
        level: 4,
        cost: 178000,
        bountyBoostUpgrade: 0.07,
      },
      {
        level: 5,
        cost: 294000,
        bountyBoostUpgrade: 0.09,
      },
      {
        level: 6,
        cost: 485000,
        bountyBoostUpgrade: 0.1,
      },
      {
        level: 7,
        cost: 772000,
        bountyBoostUpgrade: 0.12,
      },
      {
        level: 8,
        cost: 1300000,
        bountyBoostUpgrade: 0.15,
      },
      {
        level: 9,
        cost: 2270000,
        bountyBoostUpgrade: 0.18,
      },
      {
        level: 10,
        cost: 3800000,
        bountyBoostUpgrade: 0.22,
      },
      {
        level: 11,
        cost: 6660000,
        bountyBoostUpgrade: 0.27,
      },
      {
        level: 12,
        cost: 11000000,
        bountyBoostUpgrade: 0.33,
      },
      {
        level: 13,
        cost: 19800000,
        bountyBoostUpgrade: 0.39,
      },
      {
        level: 14,
        cost: 31600000,
        bountyBoostUpgrade: 0.48,
      },
      {
        level: 15,
        cost: 53000000,
        bountyBoostUpgrade: 0.57,
      },
      {
        level: 16,
        cost: 86200000,
        bountyBoostUpgrade: 0.69,
      },
      {
        level: 17,
        cost: 131000000,
        bountyBoostUpgrade: 0.84,
      },
      {
        level: 18,
        cost: 234000000,
        bountyBoostUpgrade: 1.01,
      },
      {
        level: 19,
        cost: 392000000,
        bountyBoostUpgrade: 1,
      },
      {
        level: 20,
        cost: 702000000,
        bountyBoostUpgrade: 0.98,
      },
    ],
  },
  {
    name: "Paranoia Powder",
    itemId: "paranoia_powder",
    category: "Consumables",
    subcategory: "Basic",
    description:
      "A substance that reveals hidden enemies but causes hallucinations.",
    dependencies: [],
    upgrades: [
      {
        level: 1,
        cost: 69000,
        bountyBoostUpgrade: 0.03,
      },
      {
        level: 2,
        cost: 115000,
        bountyBoostUpgrade: 0.04,
      },
      {
        level: 3,
        cost: 182000,
        bountyBoostUpgrade: 0.05,
      },
      {
        level: 4,
        cost: 320000,
        bountyBoostUpgrade: 0.05,
      },
      {
        level: 5,
        cost: 485000,
        bountyBoostUpgrade: 0.06,
      },
      {
        level: 6,
        cost: 804000,
        bountyBoostUpgrade: 0.07,
      },
      {
        level: 7,
        cost: 1280000,
        bountyBoostUpgrade: 0.08,
      },
      {
        level: 8,
        cost: 1940000,
        bountyBoostUpgrade: 0.1,
      },
      {
        level: 9,
        cost: 3250000,
        bountyBoostUpgrade: 0.11,
      },
      {
        level: 10,
        cost: 5000000,
        bountyBoostUpgrade: 0.13,
      },
      {
        level: 11,
        cost: 8020000,
        bountyBoostUpgrade: 0.15,
      },
      {
        level: 12,
        cost: 12800000,
        bountyBoostUpgrade: 0.17,
      },
      {
        level: 13,
        cost: 22600000,
        bountyBoostUpgrade: 0.2,
      },
      {
        level: 14,
        cost: 34400000,
        bountyBoostUpgrade: 0.23,
      },
      {
        level: 15,
        cost: 58400000,
        bountyBoostUpgrade: 0.27,
      },
      {
        level: 16,
        cost: 101000000,
        bountyBoostUpgrade: 0.31,
      },
      {
        level: 17,
        cost: 153000000,
        bountyBoostUpgrade: 0.31,
      },
      {
        level: 18,
        cost: 244000000,
        bountyBoostUpgrade: 0.3,
      },
      {
        level: 19,
        cost: 396000000,
        bountyBoostUpgrade: 0.3,
      },
      {
        level: 20,
        cost: 654000000,
        bountyBoostUpgrade: 0.3,
      },
    ],
  },
  {
    name: "Berserker Brew",
    itemId: "berserker_brew",
    category: "Consumables",
    subcategory: "Intermediate",
    description:
      "A potion that greatly increases strength but reduces control.",
    dependencies: [
      {
        itemId: "liquid_courage",
        level: 8,
      },
    ],
    upgrades: [
      {
        level: 1,
        cost: 102000,
        bountyBoostUpgrade: 0.04,
      },
      {
        level: 2,
        cost: 140000,
        bountyBoostUpgrade: 0.05,
      },
      {
        level: 3,
        cost: 202000,
        bountyBoostUpgrade: 0.06,
      },
      {
        level: 4,
        cost: 316000,
        bountyBoostUpgrade: 0.08,
      },
      {
        level: 5,
        cost: 497000,
        bountyBoostUpgrade: 0.1,
      },
      {
        level: 6,
        cost: 730000,
        bountyBoostUpgrade: 0.12,
      },
      {
        level: 7,
        cost: 1150000,
        bountyBoostUpgrade: 0.15,
      },
      {
        level: 8,
        cost: 1700000,
        bountyBoostUpgrade: 0.2,
      },
      {
        level: 9,
        cost: 2440000,
        bountyBoostUpgrade: 0.25,
      },
      {
        level: 10,
        cost: 3300000,
        bountyBoostUpgrade: 0.31,
      },
      {
        level: 11,
        cost: 4440000,
        bountyBoostUpgrade: 0.39,
      },
      {
        level: 12,
        cost: 6340000,
        bountyBoostUpgrade: 0.49,
      },
      {
        level: 13,
        cost: 10200000,
        bountyBoostUpgrade: 0.62,
      },
      {
        level: 14,
        cost: 16200000,
        bountyBoostUpgrade: 0.79,
      },
      {
        level: 15,
        cost: 21400000,
        bountyBoostUpgrade: 1.02,
      },
      {
        level: 16,
        cost: 31300000,
        bountyBoostUpgrade: 1.3,
      },
      {
        level: 17,
        cost: 42300000,
        bountyBoostUpgrade: 1.64,
      },
      {
        level: 18,
        cost: 61100000,
        bountyBoostUpgrade: 1.61,
      },
      {
        level: 19,
        cost: 91400000,
        bountyBoostUpgrade: 1.57,
      },
      {
        level: 20,
        cost: 127000000,
        bountyBoostUpgrade: 1.53,
      },
    ],
  },
  {
    name: "Miasma Bomb",
    itemId: "miasma_bomb",
    category: "Consumables",
    subcategory: "Intermediate",
    description: "Releases a cloud of choking, poisonous gas.",
    dependencies: [
      {
        itemId: "venom_sac",
        level: 9,
      },
    ],
    upgrades: [
      {
        level: 1,
        cost: 90000,
        bountyBoostUpgrade: 0.04,
      },
      {
        level: 2,
        cost: 127000,
        bountyBoostUpgrade: 0.05,
      },
      {
        level: 3,
        cost: 209000,
        bountyBoostUpgrade: 0.06,
      },
      {
        level: 4,
        cost: 306000,
        bountyBoostUpgrade: 0.07,
      },
      {
        level: 5,
        cost: 431000,
        bountyBoostUpgrade: 0.09,
      },
      {
        level: 6,
        cost: 639000,
        bountyBoostUpgrade: 0.11,
      },
      {
        level: 7,
        cost: 1000000,
        bountyBoostUpgrade: 0.13,
      },
      {
        level: 8,
        cost: 1520000,
        bountyBoostUpgrade: 0.15,
      },
      {
        level: 9,
        cost: 2460000,
        bountyBoostUpgrade: 0.19,
      },
      {
        level: 10,
        cost: 3460000,
        bountyBoostUpgrade: 0.23,
      },
      {
        level: 11,
        cost: 5060000,
        bountyBoostUpgrade: 0.27,
      },
      {
        level: 12,
        cost: 8180000,
        bountyBoostUpgrade: 0.33,
      },
      {
        level: 13,
        cost: 12000000,
        bountyBoostUpgrade: 0.39,
      },
      {
        level: 14,
        cost: 18300000,
        bountyBoostUpgrade: 0.47,
      },
      {
        level: 15,
        cost: 27100000,
        bountyBoostUpgrade: 0.58,
      },
      {
        level: 16,
        cost: 42300000,
        bountyBoostUpgrade: 0.71,
      },
      {
        level: 17,
        cost: 66100000,
        bountyBoostUpgrade: 0.85,
      },
      {
        level: 18,
        cost: 99500000,
        bountyBoostUpgrade: 1.01,
      },
      {
        level: 19,
        cost: 144000000,
        bountyBoostUpgrade: 1.01,
      },
      {
        level: 20,
        cost: 225000000,
        bountyBoostUpgrade: 0.98,
      },
    ],
  },
  {
    name: "Rage Essence",
    itemId: "rage_essence",
    category: "Consumables",
    subcategory: "Intermediate",
    description:
      "Temporarily removes pain limitations, allowing for feats of incredible strength.",
    dependencies: [
      {
        itemId: "painkiller_herbs",
        level: 10,
      },
    ],
    upgrades: [
      {
        level: 1,
        cost: 87000,
        bountyBoostUpgrade: 0.05,
      },
      {
        level: 2,
        cost: 120000,
        bountyBoostUpgrade: 0.06,
      },
      {
        level: 3,
        cost: 174000,
        bountyBoostUpgrade: 0.07,
      },
      {
        level: 4,
        cost: 240000,
        bountyBoostUpgrade: 0.09,
      },
      {
        level: 5,
        cost: 332000,
        bountyBoostUpgrade: 0.11,
      },
      {
        level: 6,
        cost: 481000,
        bountyBoostUpgrade: 0.14,
      },
      {
        level: 7,
        cost: 684000,
        bountyBoostUpgrade: 0.17,
      },
      {
        level: 8,
        cost: 961000,
        bountyBoostUpgrade: 0.21,
      },
      {
        level: 9,
        cost: 1390000,
        bountyBoostUpgrade: 0.26,
      },
      {
        level: 10,
        cost: 2130000,
        bountyBoostUpgrade: 0.31,
      },
      {
        level: 11,
        cost: 2830000,
        bountyBoostUpgrade: 0.38,
      },
      {
        level: 12,
        cost: 3570000,
        bountyBoostUpgrade: 0.46,
      },
      {
        level: 13,
        cost: 5280000,
        bountyBoostUpgrade: 0.56,
      },
      {
        level: 14,
        cost: 7200000,
        bountyBoostUpgrade: 0.69,
      },
      {
        level: 15,
        cost: 9760000,
        bountyBoostUpgrade: 0.86,
      },
      {
        level: 16,
        cost: 14600000,
        bountyBoostUpgrade: 1.06,
      },
      {
        level: 17,
        cost: 20800000,
        bountyBoostUpgrade: 1.31,
      },
      {
        level: 18,
        cost: 29600000,
        bountyBoostUpgrade: 1.61,
      },
      {
        level: 19,
        cost: 41500000,
        bountyBoostUpgrade: 1.62,
      },
      {
        level: 20,
        cost: 63800000,
        bountyBoostUpgrade: 1.62,
      },
    ],
  },
  {
    name: "Greek Fire Flask",
    itemId: "greek_fire_flask",
    category: "Consumables",
    subcategory: "Intermediate",
    description: "Unleashes unquenchable flames that stick to targets.",
    dependencies: [
      {
        itemId: "blazing_pitch",
        level: 11,
      },
    ],
    upgrades: [
      {
        level: 1,
        cost: 42000,
        bountyBoostUpgrade: 0.03,
      },
      {
        level: 2,
        cost: 63000,
        bountyBoostUpgrade: 0.04,
      },
      {
        level: 3,
        cost: 94000,
        bountyBoostUpgrade: 0.05,
      },
      {
        level: 4,
        cost: 141000,
        bountyBoostUpgrade: 0.06,
      },
      {
        level: 5,
        cost: 228000,
        bountyBoostUpgrade: 0.07,
      },
      {
        level: 6,
        cost: 324000,
        bountyBoostUpgrade: 0.09,
      },
      {
        level: 7,
        cost: 435000,
        bountyBoostUpgrade: 0.1,
      },
      {
        level: 8,
        cost: 692000,
        bountyBoostUpgrade: 0.12,
      },
      {
        level: 9,
        cost: 987000,
        bountyBoostUpgrade: 0.14,
      },
      {
        level: 10,
        cost: 1430000,
        bountyBoostUpgrade: 0.17,
      },
      {
        level: 11,
        cost: 2040000,
        bountyBoostUpgrade: 0.2,
      },
      {
        level: 12,
        cost: 2950000,
        bountyBoostUpgrade: 0.23,
      },
      {
        level: 13,
        cost: 4300000,
        bountyBoostUpgrade: 0.28,
      },
      {
        level: 14,
        cost: 6630000,
        bountyBoostUpgrade: 0.32,
      },
      {
        level: 15,
        cost: 10900000,
        bountyBoostUpgrade: 0.39,
      },
      {
        level: 16,
        cost: 17700000,
        bountyBoostUpgrade: 0.46,
      },
      {
        level: 17,
        cost: 27500000,
        bountyBoostUpgrade: 0.54,
      },
      {
        level: 18,
        cost: 38400000,
        bountyBoostUpgrade: 0.64,
      },
      {
        level: 19,
        cost: 58500000,
        bountyBoostUpgrade: 0.75,
      },
      {
        level: 20,
        cost: 90700000,
        bountyBoostUpgrade: 0.77,
      },
    ],
  },
  {
    name: "Phantom Skin",
    itemId: "phantom_skin",
    category: "Consumables",
    subcategory: "Intermediate",
    description: "Grants temporary invisibility but causes intense pain.",
    dependencies: [
      {
        itemId: "paranoia_powder",
        level: 9,
      },
    ],
    upgrades: [
      {
        level: 1,
        cost: 104000,
        bountyBoostUpgrade: 0.06,
      },
      {
        level: 2,
        cost: 154000,
        bountyBoostUpgrade: 0.07,
      },
      {
        level: 3,
        cost: 227000,
        bountyBoostUpgrade: 0.08,
      },
      {
        level: 4,
        cost: 317000,
        bountyBoostUpgrade: 0.1,
      },
      {
        level: 5,
        cost: 466000,
        bountyBoostUpgrade: 0.11,
      },
      {
        level: 6,
        cost: 683000,
        bountyBoostUpgrade: 0.13,
      },
      {
        level: 7,
        cost: 1000000,
        bountyBoostUpgrade: 0.16,
      },
      {
        level: 8,
        cost: 1480000,
        bountyBoostUpgrade: 0.19,
      },
      {
        level: 9,
        cost: 2190000,
        bountyBoostUpgrade: 0.22,
      },
      {
        level: 10,
        cost: 3060000,
        bountyBoostUpgrade: 0.26,
      },
      {
        level: 11,
        cost: 4330000,
        bountyBoostUpgrade: 0.31,
      },
      {
        level: 12,
        cost: 6680000,
        bountyBoostUpgrade: 0.37,
      },
      {
        level: 13,
        cost: 10300000,
        bountyBoostUpgrade: 0.45,
      },
      {
        level: 14,
        cost: 16600000,
        bountyBoostUpgrade: 0.53,
      },
      {
        level: 15,
        cost: 24200000,
        bountyBoostUpgrade: 0.63,
      },
      {
        level: 16,
        cost: 36800000,
        bountyBoostUpgrade: 0.63,
      },
      {
        level: 17,
        cost: 50200000,
        bountyBoostUpgrade: 0.62,
      },
      {
        level: 18,
        cost: 72300000,
        bountyBoostUpgrade: 0.6,
      },
      {
        level: 19,
        cost: 109000000,
        bountyBoostUpgrade: 0.59,
      },
      {
        level: 20,
        cost: 165000000,
        bountyBoostUpgrade: 0.58,
      },
    ],
  },
  {
    name: "Butcher's Toolkit",
    itemId: "butchers_toolkit",
    category: "Consumables",
    subcategory: "Intermediate",
    description:
      'A grisly set of tools for harvesting "resources" from the fallen.',
    dependencies: [
      {
        itemId: "cannibals_jerky",
        level: 8,
      },
    ],
    upgrades: [
      {
        level: 1,
        cost: 92000,
        bountyBoostUpgrade: 0.07,
      },
      {
        level: 2,
        cost: 134000,
        bountyBoostUpgrade: 0.09,
      },
      {
        level: 3,
        cost: 190000,
        bountyBoostUpgrade: 0.1,
      },
      {
        level: 4,
        cost: 271000,
        bountyBoostUpgrade: 0.13,
      },
      {
        level: 5,
        cost: 368000,
        bountyBoostUpgrade: 0.17,
      },
      {
        level: 6,
        cost: 567000,
        bountyBoostUpgrade: 0.21,
      },
      {
        level: 7,
        cost: 782000,
        bountyBoostUpgrade: 0.26,
      },
      {
        level: 8,
        cost: 1110000,
        bountyBoostUpgrade: 0.32,
      },
      {
        level: 9,
        cost: 1570000,
        bountyBoostUpgrade: 0.4,
      },
      {
        level: 10,
        cost: 2220000,
        bountyBoostUpgrade: 0.5,
      },
      {
        level: 11,
        cost: 3640000,
        bountyBoostUpgrade: 0.63,
      },
      {
        level: 12,
        cost: 5990000,
        bountyBoostUpgrade: 0.79,
      },
      {
        level: 13,
        cost: 8860000,
        bountyBoostUpgrade: 0.99,
      },
      {
        level: 14,
        cost: 13500000,
        bountyBoostUpgrade: 1.24,
      },
      {
        level: 15,
        cost: 18800000,
        bountyBoostUpgrade: 1.53,
      },
      {
        level: 16,
        cost: 25800000,
        bountyBoostUpgrade: 1.51,
      },
      {
        level: 17,
        cost: 40000000,
        bountyBoostUpgrade: 1.51,
      },
      {
        level: 18,
        cost: 63700000,
        bountyBoostUpgrade: 1.46,
      },
      {
        level: 19,
        cost: 100000000,
        bountyBoostUpgrade: 1.45,
      },
      {
        level: 20,
        cost: 146000000,
        bountyBoostUpgrade: 1.4,
      },
    ],
  },
  {
    name: "Scroll of Blood Magic",
    itemId: "scroll_of_blood_magic",
    category: "Consumables",
    subcategory: "Intermediate",
    description:
      "Activates a random, powerful spell at the cost of the user's health.",
    dependencies: [
      {
        itemId: "blood_vial",
        level: 10,
      },
    ],
    upgrades: [
      {
        level: 1,
        cost: 66000,
        bountyBoostUpgrade: 0.07,
      },
      {
        level: 2,
        cost: 96000,
        bountyBoostUpgrade: 0.08,
      },
      {
        level: 3,
        cost: 136000,
        bountyBoostUpgrade: 0.1,
      },
      {
        level: 4,
        cost: 197000,
        bountyBoostUpgrade: 0.12,
      },
      {
        level: 5,
        cost: 305000,
        bountyBoostUpgrade: 0.14,
      },
      {
        level: 6,
        cost: 445000,
        bountyBoostUpgrade: 0.17,
      },
      {
        level: 7,
        cost: 643000,
        bountyBoostUpgrade: 0.2,
      },
      {
        level: 8,
        cost: 949000,
        bountyBoostUpgrade: 0.25,
      },
      {
        level: 9,
        cost: 1540000,
        bountyBoostUpgrade: 0.29,
      },
      {
        level: 10,
        cost: 2530000,
        bountyBoostUpgrade: 0.34,
      },
      {
        level: 11,
        cost: 4070000,
        bountyBoostUpgrade: 0.4,
      },
      {
        level: 12,
        cost: 6430000,
        bountyBoostUpgrade: 0.48,
      },
      {
        level: 13,
        cost: 9920000,
        bountyBoostUpgrade: 0.58,
      },
      {
        level: 14,
        cost: 15000000,
        bountyBoostUpgrade: 0.7,
      },
      {
        level: 15,
        cost: 23400000,
        bountyBoostUpgrade: 0.85,
      },
      {
        level: 16,
        cost: 37000000,
        bountyBoostUpgrade: 0.83,
      },
      {
        level: 17,
        cost: 52000000,
        bountyBoostUpgrade: 0.84,
      },
      {
        level: 18,
        cost: 73300000,
        bountyBoostUpgrade: 0.82,
      },
      {
        level: 19,
        cost: 110000000,
        bountyBoostUpgrade: 0.79,
      },
      {
        level: 20,
        cost: 175000000,
        bountyBoostUpgrade: 0.78,
      },
    ],
  },
  {
    name: "Essence of Agony",
    itemId: "essence_of_agony",
    category: "Consumables",
    subcategory: "Intermediate",
    description: "Coats weapons in a substance that magnifies pain.",
    dependencies: [
      {
        itemId: "bone_dust",
        level: 9,
      },
    ],
    upgrades: [
      {
        level: 1,
        cost: 95000,
        bountyBoostUpgrade: 0.06,
      },
      {
        level: 2,
        cost: 130000,
        bountyBoostUpgrade: 0.08,
      },
      {
        level: 3,
        cost: 178000,
        bountyBoostUpgrade: 0.09,
      },
      {
        level: 4,
        cost: 259000,
        bountyBoostUpgrade: 0.11,
      },
      {
        level: 5,
        cost: 396000,
        bountyBoostUpgrade: 0.13,
      },
      {
        level: 6,
        cost: 652000,
        bountyBoostUpgrade: 0.15,
      },
      {
        level: 7,
        cost: 963000,
        bountyBoostUpgrade: 0.18,
      },
      {
        level: 8,
        cost: 1400000,
        bountyBoostUpgrade: 0.22,
      },
      {
        level: 9,
        cost: 1980000,
        bountyBoostUpgrade: 0.26,
      },
      {
        level: 10,
        cost: 3150000,
        bountyBoostUpgrade: 0.31,
      },
      {
        level: 11,
        cost: 4940000,
        bountyBoostUpgrade: 0.38,
      },
      {
        level: 12,
        cost: 6610000,
        bountyBoostUpgrade: 0.44,
      },
      {
        level: 13,
        cost: 10900000,
        bountyBoostUpgrade: 0.53,
      },
      {
        level: 14,
        cost: 14900000,
        bountyBoostUpgrade: 0.63,
      },
      {
        level: 15,
        cost: 20400000,
        bountyBoostUpgrade: 0.76,
      },
      {
        level: 16,
        cost: 29100000,
        bountyBoostUpgrade: 0.91,
      },
      {
        level: 17,
        cost: 42200000,
        bountyBoostUpgrade: 1.09,
      },
      {
        level: 18,
        cost: 61100000,
        bountyBoostUpgrade: 1.31,
      },
      {
        level: 19,
        cost: 94700000,
        bountyBoostUpgrade: 1.56,
      },
      {
        level: 20,
        cost: 146000000,
        bountyBoostUpgrade: 1.6,
      },
    ],
  },
  {
    name: "Flesh Knitting Salve",
    itemId: "flesh_knitting_salve",
    category: "Consumables",
    subcategory: "Intermediate",
    description: "Rapidly closes wounds but leaves hideous scars.",
    dependencies: [
      {
        itemId: "blood_vial",
        level: 8,
      },
      {
        itemId: "painkiller_herbs",
        level: 8,
      },
    ],
    upgrades: [
      {
        level: 1,
        cost: 54000,
        bountyBoostUpgrade: 0.1,
      },
      {
        level: 2,
        cost: 74000,
        bountyBoostUpgrade: 0.13,
      },
      {
        level: 3,
        cost: 97000,
        bountyBoostUpgrade: 0.16,
      },
      {
        level: 4,
        cost: 139000,
        bountyBoostUpgrade: 0.2,
      },
      {
        level: 5,
        cost: 191000,
        bountyBoostUpgrade: 0.25,
      },
      {
        level: 6,
        cost: 272000,
        bountyBoostUpgrade: 0.31,
      },
      {
        level: 7,
        cost: 425000,
        bountyBoostUpgrade: 0.4,
      },
      {
        level: 8,
        cost: 613000,
        bountyBoostUpgrade: 0.49,
      },
      {
        level: 9,
        cost: 992000,
        bountyBoostUpgrade: 0.63,
      },
      {
        level: 10,
        cost: 1400000,
        bountyBoostUpgrade: 0.8,
      },
      {
        level: 11,
        cost: 1930000,
        bountyBoostUpgrade: 1.01,
      },
      {
        level: 12,
        cost: 2590000,
        bountyBoostUpgrade: 1.26,
      },
      {
        level: 13,
        cost: 4060000,
        bountyBoostUpgrade: 1.58,
      },
      {
        level: 14,
        cost: 5430000,
        bountyBoostUpgrade: 1.99,
      },
      {
        level: 15,
        cost: 8240000,
        bountyBoostUpgrade: 2.49,
      },
      {
        level: 16,
        cost: 11300000,
        bountyBoostUpgrade: 3.16,
      },
      {
        level: 17,
        cost: 16100000,
        bountyBoostUpgrade: 3.11,
      },
      {
        level: 18,
        cost: 23100000,
        bountyBoostUpgrade: 3.06,
      },
      {
        level: 19,
        cost: 32800000,
        bountyBoostUpgrade: 3.07,
      },
      {
        level: 20,
        cost: 46900000,
        bountyBoostUpgrade: 2.99,
      },
    ],
  },
  {
    name: "Soulstone",
    itemId: "soulstone",
    category: "Consumables",
    subcategory: "Intermediate",
    description:
      "Stores magical energy, siphoned painfully from living beings.",
    dependencies: [
      {
        itemId: "paranoia_powder",
        level: 11,
      },
    ],
    upgrades: [
      {
        level: 1,
        cost: 45000,
        bountyBoostUpgrade: 0.06,
      },
      {
        level: 2,
        cost: 72000,
        bountyBoostUpgrade: 0.07,
      },
      {
        level: 3,
        cost: 117000,
        bountyBoostUpgrade: 0.08,
      },
      {
        level: 4,
        cost: 181000,
        bountyBoostUpgrade: 0.1,
      },
      {
        level: 5,
        cost: 267000,
        bountyBoostUpgrade: 0.12,
      },
      {
        level: 6,
        cost: 398000,
        bountyBoostUpgrade: 0.14,
      },
      {
        level: 7,
        cost: 622000,
        bountyBoostUpgrade: 0.18,
      },
      {
        level: 8,
        cost: 1010000,
        bountyBoostUpgrade: 0.22,
      },
      {
        level: 9,
        cost: 1520000,
        bountyBoostUpgrade: 0.26,
      },
      {
        level: 10,
        cost: 2500000,
        bountyBoostUpgrade: 0.32,
      },
      {
        level: 11,
        cost: 3610000,
        bountyBoostUpgrade: 0.39,
      },
      {
        level: 12,
        cost: 5980000,
        bountyBoostUpgrade: 0.47,
      },
      {
        level: 13,
        cost: 8660000,
        bountyBoostUpgrade: 0.56,
      },
      {
        level: 14,
        cost: 13000000,
        bountyBoostUpgrade: 0.67,
      },
      {
        level: 15,
        cost: 19700000,
        bountyBoostUpgrade: 0.8,
      },
      {
        level: 16,
        cost: 33000000,
        bountyBoostUpgrade: 0.99,
      },
      {
        level: 17,
        cost: 50500000,
        bountyBoostUpgrade: 0.98,
      },
      {
        level: 18,
        cost: 83300000,
        bountyBoostUpgrade: 0.99,
      },
      {
        level: 19,
        cost: 138000000,
        bountyBoostUpgrade: 0.97,
      },
      {
        level: 20,
        cost: 218000000,
        bountyBoostUpgrade: 0.94,
      },
    ],
  },
  {
    name: "Draught of Living Death",
    itemId: "draught_of_living_death",
    category: "Consumables",
    subcategory: "Advanced",
    description: "Grants invulnerability but the user appears and feels dead.",
    dependencies: [
      {
        itemId: "phantom_skin",
        level: 15,
      },
    ],
    upgrades: [
      {
        level: 1,
        cost: 92000,
        bountyBoostUpgrade: 0.13,
      },
      {
        level: 2,
        cost: 117000,
        bountyBoostUpgrade: 0.16,
      },
      {
        level: 3,
        cost: 152000,
        bountyBoostUpgrade: 0.2,
      },
      {
        level: 4,
        cost: 202000,
        bountyBoostUpgrade: 0.25,
      },
      {
        level: 5,
        cost: 261000,
        bountyBoostUpgrade: 0.3,
      },
      {
        level: 6,
        cost: 325000,
        bountyBoostUpgrade: 0.37,
      },
      {
        level: 7,
        cost: 449000,
        bountyBoostUpgrade: 0.46,
      },
      {
        level: 8,
        cost: 618000,
        bountyBoostUpgrade: 0.57,
      },
      {
        level: 9,
        cost: 880000,
        bountyBoostUpgrade: 0.7,
      },
      {
        level: 10,
        cost: 1150000,
        bountyBoostUpgrade: 0.85,
      },
      {
        level: 11,
        cost: 1510000,
        bountyBoostUpgrade: 1.04,
      },
      {
        level: 12,
        cost: 1990000,
        bountyBoostUpgrade: 1.29,
      },
      {
        level: 13,
        cost: 2750000,
        bountyBoostUpgrade: 1.59,
      },
      {
        level: 14,
        cost: 3560000,
        bountyBoostUpgrade: 1.95,
      },
      {
        level: 15,
        cost: 5040000,
        bountyBoostUpgrade: 2.35,
      },
      {
        level: 16,
        cost: 6920000,
        bountyBoostUpgrade: 2.91,
      },
      {
        level: 17,
        cost: 9190000,
        bountyBoostUpgrade: 2.86,
      },
      {
        level: 18,
        cost: 11200000,
        bountyBoostUpgrade: 2.79,
      },
      {
        level: 19,
        cost: 15900000,
        bountyBoostUpgrade: 2.79,
      },
      {
        level: 20,
        cost: 21800000,
        bountyBoostUpgrade: 2.68,
      },
    ],
  },
  {
    name: "Paradox Scroll",
    itemId: "paradox_scroll",
    category: "Consumables",
    subcategory: "Advanced",
    description:
      "Reverses the last few seconds, but the user retains wounds taken.",
    dependencies: [
      {
        itemId: "scroll_of_blood_magic",
        level: 14,
      },
    ],
    upgrades: [
      {
        level: 1,
        cost: 69000,
        bountyBoostUpgrade: 0.15,
      },
      {
        level: 2,
        cost: 87000,
        bountyBoostUpgrade: 0.19,
      },
      {
        level: 3,
        cost: 124000,
        bountyBoostUpgrade: 0.24,
      },
      {
        level: 4,
        cost: 152000,
        bountyBoostUpgrade: 0.3,
      },
      {
        level: 5,
        cost: 203000,
        bountyBoostUpgrade: 0.39,
      },
      {
        level: 6,
        cost: 274000,
        bountyBoostUpgrade: 0.49,
      },
      {
        level: 7,
        cost: 329000,
        bountyBoostUpgrade: 0.62,
      },
      {
        level: 8,
        cost: 428000,
        bountyBoostUpgrade: 0.79,
      },
      {
        level: 9,
        cost: 504000,
        bountyBoostUpgrade: 1,
      },
      {
        level: 10,
        cost: 627000,
        bountyBoostUpgrade: 1.24,
      },
      {
        level: 11,
        cost: 822000,
        bountyBoostUpgrade: 1.6,
      },
      {
        level: 12,
        cost: 1080000,
        bountyBoostUpgrade: 2.06,
      },
      {
        level: 13,
        cost: 1310000,
        bountyBoostUpgrade: 2.59,
      },
      {
        level: 14,
        cost: 1830000,
        bountyBoostUpgrade: 3.26,
      },
      {
        level: 15,
        cost: 2530000,
        bountyBoostUpgrade: 4.15,
      },
      {
        level: 16,
        cost: 3690000,
        bountyBoostUpgrade: 5,
      },
      {
        level: 17,
        cost: 5070000,
        bountyBoostUpgrade: 5,
      },
      {
        level: 18,
        cost: 6860000,
        bountyBoostUpgrade: 5,
      },
      {
        level: 19,
        cost: 9390000,
        bountyBoostUpgrade: 4.99,
      },
      {
        level: 20,
        cost: 12700000,
        bountyBoostUpgrade: 4.88,
      },
    ],
  },
  {
    name: "Hellfire Grenade",
    itemId: "hellfire_grenade",
    category: "Consumables",
    subcategory: "Advanced",
    description: "Explodes into flames that burn both flesh and soul.",
    dependencies: [
      {
        itemId: "greek_fire_flask",
        level: 16,
      },
    ],
    upgrades: [
      {
        level: 1,
        cost: 123000,
        bountyBoostUpgrade: 0.13,
      },
      {
        level: 2,
        cost: 169000,
        bountyBoostUpgrade: 0.16,
      },
      {
        level: 3,
        cost: 245000,
        bountyBoostUpgrade: 0.2,
      },
      {
        level: 4,
        cost: 316000,
        bountyBoostUpgrade: 0.25,
      },
      {
        level: 5,
        cost: 430000,
        bountyBoostUpgrade: 0.31,
      },
      {
        level: 6,
        cost: 601000,
        bountyBoostUpgrade: 0.38,
      },
      {
        level: 7,
        cost: 770000,
        bountyBoostUpgrade: 0.47,
      },
      {
        level: 8,
        cost: 1170000,
        bountyBoostUpgrade: 0.58,
      },
      {
        level: 9,
        cost: 1590000,
        bountyBoostUpgrade: 0.72,
      },
      {
        level: 10,
        cost: 2310000,
        bountyBoostUpgrade: 0.89,
      },
      {
        level: 11,
        cost: 3070000,
        bountyBoostUpgrade: 1.11,
      },
      {
        level: 12,
        cost: 4390000,
        bountyBoostUpgrade: 1.36,
      },
      {
        level: 13,
        cost: 5870000,
        bountyBoostUpgrade: 1.71,
      },
      {
        level: 14,
        cost: 8400000,
        bountyBoostUpgrade: 2.13,
      },
      {
        level: 15,
        cost: 10700000,
        bountyBoostUpgrade: 2.65,
      },
      {
        level: 16,
        cost: 13800000,
        bountyBoostUpgrade: 3.23,
      },
      {
        level: 17,
        cost: 20900000,
        bountyBoostUpgrade: 3.25,
      },
      {
        level: 18,
        cost: 29100000,
        bountyBoostUpgrade: 3.19,
      },
      {
        level: 19,
        cost: 43200000,
        bountyBoostUpgrade: 3.16,
      },
      {
        level: 20,
        cost: 59600000,
        bountyBoostUpgrade: 3.05,
      },
    ],
  },
  {
    name: "Philosopher's Bane",
    itemId: "philosophers_bane",
    category: "Consumables",
    subcategory: "Advanced",
    description: "Corrupts matter into twisted, evil forms.",
    dependencies: [
      {
        itemId: "essence_of_agony",
        level: 15,
      },
    ],
    upgrades: [
      {
        level: 1,
        cost: 142000,
        bountyBoostUpgrade: 0.08,
      },
      {
        level: 2,
        cost: 199000,
        bountyBoostUpgrade: 0.1,
      },
      {
        level: 3,
        cost: 269000,
        bountyBoostUpgrade: 0.13,
      },
      {
        level: 4,
        cost: 351000,
        bountyBoostUpgrade: 0.16,
      },
      {
        level: 5,
        cost: 495000,
        bountyBoostUpgrade: 0.2,
      },
      {
        level: 6,
        cost: 686000,
        bountyBoostUpgrade: 0.25,
      },
      {
        level: 7,
        cost: 917000,
        bountyBoostUpgrade: 0.31,
      },
      {
        level: 8,
        cost: 1300000,
        bountyBoostUpgrade: 0.39,
      },
      {
        level: 9,
        cost: 1850000,
        bountyBoostUpgrade: 0.49,
      },
      {
        level: 10,
        cost: 2670000,
        bountyBoostUpgrade: 0.61,
      },
      {
        level: 11,
        cost: 3750000,
        bountyBoostUpgrade: 0.74,
      },
      {
        level: 12,
        cost: 5400000,
        bountyBoostUpgrade: 0.92,
      },
      {
        level: 13,
        cost: 7650000,
        bountyBoostUpgrade: 1.14,
      },
      {
        level: 14,
        cost: 10700000,
        bountyBoostUpgrade: 1.42,
      },
      {
        level: 15,
        cost: 15800000,
        bountyBoostUpgrade: 1.8,
      },
      {
        level: 16,
        cost: 21200000,
        bountyBoostUpgrade: 2.27,
      },
      {
        level: 17,
        cost: 28000000,
        bountyBoostUpgrade: 2.83,
      },
      {
        level: 18,
        cost: 36800000,
        bountyBoostUpgrade: 2.79,
      },
      {
        level: 19,
        cost: 49700000,
        bountyBoostUpgrade: 2.75,
      },
      {
        level: 20,
        cost: 69400000,
        bountyBoostUpgrade: 2.72,
      },
    ],
  },
  {
    name: "Ichor of the Gods",
    itemId: "ichor_of_the_gods",
    category: "Consumables",
    subcategory: "Advanced",
    description: "Temporarily ascend to demigod status, wielding divine wrath.",
    dependencies: [
      {
        itemId: "berserker_brew",
        level: 17,
      },
      {
        itemId: "soulstone",
        level: 17,
      },
    ],
    upgrades: [
      {
        level: 1,
        cost: 62000,
        bountyBoostUpgrade: 0.12,
      },
      {
        level: 2,
        cost: 88000,
        bountyBoostUpgrade: 0.16,
      },
      {
        level: 3,
        cost: 127000,
        bountyBoostUpgrade: 0.2,
      },
      {
        level: 4,
        cost: 182000,
        bountyBoostUpgrade: 0.27,
      },
      {
        level: 5,
        cost: 275000,
        bountyBoostUpgrade: 0.35,
      },
      {
        level: 6,
        cost: 373000,
        bountyBoostUpgrade: 0.47,
      },
      {
        level: 7,
        cost: 480000,
        bountyBoostUpgrade: 0.61,
      },
      {
        level: 8,
        cost: 665000,
        bountyBoostUpgrade: 0.82,
      },
      {
        level: 9,
        cost: 921000,
        bountyBoostUpgrade: 1.06,
      },
      {
        level: 10,
        cost: 1240000,
        bountyBoostUpgrade: 1.39,
      },
      {
        level: 11,
        cost: 1730000,
        bountyBoostUpgrade: 1.85,
      },
      {
        level: 12,
        cost: 2470000,
        bountyBoostUpgrade: 2.42,
      },
      {
        level: 13,
        cost: 3670000,
        bountyBoostUpgrade: 3.2,
      },
      {
        level: 14,
        cost: 5570000,
        bountyBoostUpgrade: 4.27,
      },
      {
        level: 15,
        cost: 8410000,
        bountyBoostUpgrade: 5,
      },
      {
        level: 16,
        cost: 11300000,
        bountyBoostUpgrade: 5,
      },
      {
        level: 17,
        cost: 16400000,
        bountyBoostUpgrade: 5,
      },
      {
        level: 18,
        cost: 22300000,
        bountyBoostUpgrade: 5,
      },
      {
        level: 19,
        cost: 34400000,
        bountyBoostUpgrade: 5,
      },
      {
        level: 20,
        cost: 48000000,
        bountyBoostUpgrade: 5,
      },
    ],
  },
  {
    name: "Fate-Twisting Vial",
    itemId: "fate_twisting_vial",
    category: "Consumables",
    subcategory: "Advanced",
    description:
      "Guarantees success of the next action by dooming a later one.",
    dependencies: [
      {
        itemId: "paradox_scroll",
        level: 15,
      },
    ],
    upgrades: [
      {
        level: 1,
        cost: 134000,
        bountyBoostUpgrade: 0.13,
      },
      {
        level: 2,
        cost: 189000,
        bountyBoostUpgrade: 0.17,
      },
      {
        level: 3,
        cost: 290000,
        bountyBoostUpgrade: 0.22,
      },
      {
        level: 4,
        cost: 406000,
        bountyBoostUpgrade: 0.28,
      },
      {
        level: 5,
        cost: 572000,
        bountyBoostUpgrade: 0.36,
      },
      {
        level: 6,
        cost: 802000,
        bountyBoostUpgrade: 0.45,
      },
      {
        level: 7,
        cost: 1140000,
        bountyBoostUpgrade: 0.58,
      },
      {
        level: 8,
        cost: 1530000,
        bountyBoostUpgrade: 0.75,
      },
      {
        level: 9,
        cost: 2050000,
        bountyBoostUpgrade: 0.97,
      },
      {
        level: 10,
        cost: 2850000,
        bountyBoostUpgrade: 1.21,
      },
      {
        level: 11,
        cost: 3790000,
        bountyBoostUpgrade: 1.52,
      },
      {
        level: 12,
        cost: 5360000,
        bountyBoostUpgrade: 1.93,
      },
      {
        level: 13,
        cost: 7600000,
        bountyBoostUpgrade: 2.44,
      },
      {
        level: 14,
        cost: 10600000,
        bountyBoostUpgrade: 3.15,
      },
      {
        level: 15,
        cost: 15700000,
        bountyBoostUpgrade: 4.03,
      },
      {
        level: 16,
        cost: 21600000,
        bountyBoostUpgrade: 5,
      },
      {
        level: 17,
        cost: 29500000,
        bountyBoostUpgrade: 5,
      },
      {
        level: 18,
        cost: 44300000,
        bountyBoostUpgrade: 5,
      },
      {
        level: 19,
        cost: 58900000,
        bountyBoostUpgrade: 5,
      },
      {
        level: 20,
        cost: 81300000,
        bountyBoostUpgrade: 5,
      },
    ],
  },
  {
    name: "Void Beacon",
    itemId: "void_beacon",
    category: "Consumables",
    subcategory: "Advanced",
    description:
      "Creates a localized black hole, obliterating everything nearby.",
    dependencies: [
      {
        itemId: "miasma_bomb",
        level: 16,
      },
    ],
    upgrades: [
      {
        level: 1,
        cost: 118000,
        bountyBoostUpgrade: 0.15,
      },
      {
        level: 2,
        cost: 162000,
        bountyBoostUpgrade: 0.2,
      },
      {
        level: 3,
        cost: 223000,
        bountyBoostUpgrade: 0.27,
      },
      {
        level: 4,
        cost: 304000,
        bountyBoostUpgrade: 0.34,
      },
      {
        level: 5,
        cost: 422000,
        bountyBoostUpgrade: 0.45,
      },
      {
        level: 6,
        cost: 597000,
        bountyBoostUpgrade: 0.58,
      },
      {
        level: 7,
        cost: 833000,
        bountyBoostUpgrade: 0.75,
      },
      {
        level: 8,
        cost: 1150000,
        bountyBoostUpgrade: 0.99,
      },
      {
        level: 9,
        cost: 1670000,
        bountyBoostUpgrade: 1.32,
      },
      {
        level: 10,
        cost: 2160000,
        bountyBoostUpgrade: 1.73,
      },
      {
        level: 11,
        cost: 2900000,
        bountyBoostUpgrade: 2.24,
      },
      {
        level: 12,
        cost: 4160000,
        bountyBoostUpgrade: 2.9,
      },
      {
        level: 13,
        cost: 5370000,
        bountyBoostUpgrade: 3.86,
      },
      {
        level: 14,
        cost: 7740000,
        bountyBoostUpgrade: 4.95,
      },
      {
        level: 15,
        cost: 10500000,
        bountyBoostUpgrade: 5,
      },
      {
        level: 16,
        cost: 14700000,
        bountyBoostUpgrade: 5,
      },
      {
        level: 17,
        cost: 19900000,
        bountyBoostUpgrade: 5,
      },
      {
        level: 18,
        cost: 26800000,
        bountyBoostUpgrade: 5,
      },
      {
        level: 19,
        cost: 34100000,
        bountyBoostUpgrade: 5,
      },
      {
        level: 20,
        cost: 49700000,
        bountyBoostUpgrade: 5,
      },
    ],
  },
  {
    name: "Phoenix Blood",
    itemId: "phoenix_blood",
    category: "Consumables",
    subcategory: "Advanced",
    description:
      "Allows self-resurrection, but each use shortens total lifespan.",
    dependencies: [
      {
        itemId: "flesh_knitting_salve",
        level: 15,
      },
      {
        itemId: "rage_essence",
        level: 15,
      },
    ],
    upgrades: [
      {
        level: 1,
        cost: 107000,
        bountyBoostUpgrade: 0.09,
      },
      {
        level: 2,
        cost: 145000,
        bountyBoostUpgrade: 0.12,
      },
      {
        level: 3,
        cost: 213000,
        bountyBoostUpgrade: 0.16,
      },
      {
        level: 4,
        cost: 289000,
        bountyBoostUpgrade: 0.21,
      },
      {
        level: 5,
        cost: 446000,
        bountyBoostUpgrade: 0.27,
      },
      {
        level: 6,
        cost: 605000,
        bountyBoostUpgrade: 0.35,
      },
      {
        level: 7,
        cost: 877000,
        bountyBoostUpgrade: 0.46,
      },
      {
        level: 8,
        cost: 1190000,
        bountyBoostUpgrade: 0.6,
      },
      {
        level: 9,
        cost: 1810000,
        bountyBoostUpgrade: 0.79,
      },
      {
        level: 10,
        cost: 2730000,
        bountyBoostUpgrade: 1.02,
      },
      {
        level: 11,
        cost: 3820000,
        bountyBoostUpgrade: 1.34,
      },
      {
        level: 12,
        cost: 5430000,
        bountyBoostUpgrade: 1.76,
      },
      {
        level: 13,
        cost: 7870000,
        bountyBoostUpgrade: 2.27,
      },
      {
        level: 14,
        cost: 10200000,
        bountyBoostUpgrade: 2.94,
      },
      {
        level: 15,
        cost: 14800000,
        bountyBoostUpgrade: 3.86,
      },
      {
        level: 16,
        cost: 20500000,
        bountyBoostUpgrade: 5,
      },
      {
        level: 17,
        cost: 28500000,
        bountyBoostUpgrade: 5,
      },
      {
        level: 18,
        cost: 38100000,
        bountyBoostUpgrade: 5,
      },
      {
        level: 19,
        cost: 55400000,
        bountyBoostUpgrade: 5,
      },
      {
        level: 20,
        cost: 75400000,
        bountyBoostUpgrade: 5,
      },
    ],
  },
  {
    name: "Cosmic Horror Glimpse",
    itemId: "cosmic_horror_glimpse",
    category: "Consumables",
    subcategory: "Advanced",
    description: "Reveals terrifying truths, damaging enemy sanity.",
    dependencies: [
      {
        itemId: "butchers_toolkit",
        level: 14,
      },
    ],
    upgrades: [
      {
        level: 1,
        cost: 104000,
        bountyBoostUpgrade: 0.18,
      },
      {
        level: 2,
        cost: 145000,
        bountyBoostUpgrade: 0.24,
      },
      {
        level: 3,
        cost: 207000,
        bountyBoostUpgrade: 0.31,
      },
      {
        level: 4,
        cost: 273000,
        bountyBoostUpgrade: 0.4,
      },
      {
        level: 5,
        cost: 401000,
        bountyBoostUpgrade: 0.52,
      },
      {
        level: 6,
        cost: 500000,
        bountyBoostUpgrade: 0.65,
      },
      {
        level: 7,
        cost: 662000,
        bountyBoostUpgrade: 0.82,
      },
      {
        level: 8,
        cost: 858000,
        bountyBoostUpgrade: 1.06,
      },
      {
        level: 9,
        cost: 1110000,
        bountyBoostUpgrade: 1.39,
      },
      {
        level: 10,
        cost: 1430000,
        bountyBoostUpgrade: 1.76,
      },
      {
        level: 11,
        cost: 1910000,
        bountyBoostUpgrade: 2.3,
      },
      {
        level: 12,
        cost: 2750000,
        bountyBoostUpgrade: 2.99,
      },
      {
        level: 13,
        cost: 4030000,
        bountyBoostUpgrade: 3.86,
      },
      {
        level: 14,
        cost: 5240000,
        bountyBoostUpgrade: 5,
      },
      {
        level: 15,
        cost: 6790000,
        bountyBoostUpgrade: 5,
      },
      {
        level: 16,
        cost: 8620000,
        bountyBoostUpgrade: 5,
      },
      {
        level: 17,
        cost: 12400000,
        bountyBoostUpgrade: 5,
      },
      {
        level: 18,
        cost: 16400000,
        bountyBoostUpgrade: 5,
      },
      {
        level: 19,
        cost: 21200000,
        bountyBoostUpgrade: 5,
      },
      {
        level: 20,
        cost: 30700000,
        bountyBoostUpgrade: 5,
      },
    ],
  },
  {
    name: "Oblivion Essence",
    itemId: "oblivion_essence",
    category: "Consumables",
    subcategory: "Advanced",
    description: "Temporarily erases the user from existence and memory.",
    dependencies: [
      {
        itemId: "philosophers_bane",
        level: 16,
      },
    ],
    upgrades: [
      {
        level: 1,
        cost: 128000,
        bountyBoostUpgrade: 0.07,
      },
      {
        level: 2,
        cost: 159000,
        bountyBoostUpgrade: 0.09,
      },
      {
        level: 3,
        cost: 234000,
        bountyBoostUpgrade: 0.11,
      },
      {
        level: 4,
        cost: 325000,
        bountyBoostUpgrade: 0.15,
      },
      {
        level: 5,
        cost: 433000,
        bountyBoostUpgrade: 0.19,
      },
      {
        level: 6,
        cost: 571000,
        bountyBoostUpgrade: 0.24,
      },
      {
        level: 7,
        cost: 778000,
        bountyBoostUpgrade: 0.31,
      },
      {
        level: 8,
        cost: 1080000,
        bountyBoostUpgrade: 0.39,
      },
      {
        level: 9,
        cost: 1470000,
        bountyBoostUpgrade: 0.5,
      },
      {
        level: 10,
        cost: 1930000,
        bountyBoostUpgrade: 0.64,
      },
      {
        level: 11,
        cost: 2590000,
        bountyBoostUpgrade: 0.81,
      },
      {
        level: 12,
        cost: 3770000,
        bountyBoostUpgrade: 1.01,
      },
      {
        level: 13,
        cost: 5200000,
        bountyBoostUpgrade: 1.28,
      },
      {
        level: 14,
        cost: 7010000,
        bountyBoostUpgrade: 1.64,
      },
      {
        level: 15,
        cost: 8950000,
        bountyBoostUpgrade: 2.06,
      },
      {
        level: 16,
        cost: 13600000,
        bountyBoostUpgrade: 2.66,
      },
      {
        level: 17,
        cost: 19200000,
        bountyBoostUpgrade: 3.38,
      },
      {
        level: 18,
        cost: 26200000,
        bountyBoostUpgrade: 3.32,
      },
      {
        level: 19,
        cost: 32900000,
        bountyBoostUpgrade: 3.3,
      },
      {
        level: 20,
        cost: 42800000,
        bountyBoostUpgrade: 3.28,
      },
    ],
  },
];

module.exports = ArmoryItems;
