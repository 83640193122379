import React from "react";
import battle from "../assets/images/battle.png";
import armory from "../assets/images/armory.png";
import quests from "../assets/images/quests.png";
import clan from "../assets/images/clan.png";
import airdrop from "../assets/images/airdrop.png";
import navBarBackgroundSliver from "../assets/images/navBarBackgroundSliver.png";
import navItemSeparator from "../assets/images/navItemSeparator.png";
import tabIconSelectedCloud from "../assets/images/tabIconSelectedCloud.png";
import tabIconSelectedTopGlow from "../assets/images/tabIconSelectedTopGlow.png";
import "../styles/NavigationBar.css";

const navItems = [
  { name: "Battle", icon: battle },
  { name: "Armory", icon: armory },
  { name: "Quests", icon: quests },
  { name: "Clan", icon: clan },
  { name: "Airdrop", icon: airdrop },
];

function NavigationBar({ currentScreen, onScreenChange }) {
  return (
    <nav
      className="navigation-bar"
      style={{
        backgroundImage: `url(${navBarBackgroundSliver})`,
      }}
    >
      {navItems.map((item, index) => (
        <React.Fragment key={item.name}>
          <button
            className={`nav-item ${
              currentScreen === item.name ? "active" : ""
            }`}
            onClick={() => onScreenChange(item.name)}
          >
            <div className="nav-icon-container">
              {currentScreen === item.name && (
                <>
                  <img
                    src={tabIconSelectedCloud}
                    alt=""
                    className="nav-icon-glow-cloud"
                  />
                  <img
                    src={tabIconSelectedTopGlow}
                    alt=""
                    className="nav-icon-glow-top"
                  />
                </>
              )}
              <img src={item.icon} alt={item.name} className="nav-icon" />
            </div>
            <span>{item.name}</span>
          </button>
          {index < navItems.length - 1 && (
            <img
              src={navItemSeparator}
              alt="separator"
              className="nav-separator"
            />
          )}
        </React.Fragment>
      ))}
    </nav>
  );
}

export default NavigationBar;
