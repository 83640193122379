import React, { useState } from "react";
import PropTypes from "prop-types";
import "../../../styles/EnterEthAddressBottomSheet.css";
import BottomSheet from "../../BottomSheet";
import TextInput from "../../TextInput";
import InfoCard from "../../InfoCard";
import enterEthAddressTitle from "../../../assets/images/enterEthAddressTitle.png";
import * as ethereum_address from "ethereum-address";

function EnterEthAddressBottomSheet({
  onCancel,
  onConfirm,
  ethereumAddress,
  isOpen,
}) {
  const [address, setAddress] = useState(ethereumAddress || "");
  const [isAddressValid, setIsAddressValid] = useState(false);

  const validateEthereumAddress = (address) => {
    if (
      !address ||
      typeof address !== "string" ||
      address.trim().length === 0
    ) {
      return false;
    }

    // First check if it's a valid address
    if (!ethereum_address.isAddress(address)) {
      console.log("address: ", address);
      return false;
    }

    // Then check if it's a valid checksum address
    return ethereum_address.isChecksumAddress(address);
  };

  const handleAddressChange = (e) => {
    const newAddress = e.target.value;
    setAddress(newAddress);
    const isValid = validateEthereumAddress(newAddress);
    setIsAddressValid(isValid);
    console.log("isAddressValid: ", isValid);
  };

  const handleConfirm = () => {
    if (isAddressValid) {
      onConfirm(address);
    }
  };

  return (
    <BottomSheet
      onCancel={onCancel}
      onConfirm={handleConfirm}
      canTapOutToCancel={false}
      isConfirmEnabled={isAddressValid}
      isOpen={isOpen}
    >
      <div className="enter-eth-address-content">
        <img
          src={enterEthAddressTitle}
          alt="Enter Ethereum Address"
          className="enter-eth-address-title"
        />
        <TextInput
          value={address}
          onChange={handleAddressChange}
          placeholder="0x..."
          style={{ color: isAddressValid ? "#ffffff" : "red" }}
        />
        <InfoCard
          text={
            "Ensure this is a valid Ethereum address. Non-Ethereum addresses will not receive the airdrop."
          }
          style={{ marginTop: -9 }}
        />
        <InfoCard
          text={
            "Double-check your address before submitting. Transactions on the blockchain are irreversible."
          }
          style={{ marginTop: -4 }}
        />
      </div>
    </BottomSheet>
  );
}

EnterEthAddressBottomSheet.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  ethereumAddress: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
};

export default EnterEthAddressBottomSheet;
