import React, { useState } from "react";
import { getGlobalLogs, clearGlobalLogs } from "../utils/GlobalLogger";

const DebugLog = ({ visible = true, onClose }) => {
  const [logs, setLogs] = useState(getGlobalLogs());

  const handleRefresh = () => {
    setLogs(getGlobalLogs());
  };

  const handleClear = () => {
    clearGlobalLogs();
    setLogs([]);
  };

  const handleCopyToClipboard = () => {
    const logText = logs
      .map((log) => `${log.timestamp}: ${log.message}`)
      .join("\n");
    navigator.clipboard
      .writeText(logText)
      .then(() => {
        alert("Logs copied to clipboard!");
      })
      .catch((err) => {
        console.error("Failed to copy logs: ", err);
      });
  };

  if (!visible) return null;

  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: "rgba(0, 0, 0, 0.9)",
        color: "white",
        overflowY: "auto",
        padding: "20px",
        fontFamily: "monospace",
        fontSize: "14px",
        zIndex: 9999,
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "20px",
        }}
      >
        <div>
          <button onClick={handleRefresh} style={buttonStyle}>
            Refresh Logs
          </button>
          <button onClick={handleClear} style={buttonStyle}>
            Clear Logs
          </button>
          <button onClick={handleCopyToClipboard} style={buttonStyle}>
            Copy to Clipboard
          </button>
        </div>
        <button onClick={onClose} style={buttonStyle}>
          Close
        </button>
      </div>
      <div style={{ height: "calc(100% - 60px)", overflowY: "auto" }}>
        {logs.map((log, index) => (
          <div key={index} style={{ marginBottom: "5px" }}>
            <span style={{ color: "#888" }}>{log.timestamp}</span>:{" "}
            {log.message}
          </div>
        ))}
      </div>
    </div>
  );
};

const buttonStyle = {
  backgroundColor: "#444",
  color: "white",
  border: "none",
  padding: "8px 12px",
  marginRight: "10px",
  cursor: "pointer",
};

export default DebugLog;
